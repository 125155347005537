import React from "react";
import Carousel from "react-elastic-carousel";
import { overrideStyles } from "react-elastic-carousel";
import CarouselItem from "./CarouselItem";
const breakPoints = [
  { width: 1, itemsToShow: 1, itemPadding: [0, 0] },
  { width: 1000, itemsToShow: 2, itemPadding: [0, 0] },
  { width: 1250, itemsToShow: 3, itemPadding: [0, 10] },
  { width: 1650, itemsToShow: 4, itemPadding: [0, 10] },
];

/**
 *
 * @returns A carousel for the case studies.
 */
export default function CustomCarousel() {
  return (
    <div
      className="flex-center"
      style={{ width: "95%", marginLeft: "2%", marginBottom: "5%" }}
    >
      <Carousel breakPoints={breakPoints}>
        <CarouselItem
          image="VRU.jpg"
          caption="Vapour recovery unit (VRU's)"
          navigateTo={"vru"}
        />
        <CarouselItem
          image="CombustionGen.png"
          caption="Combustion"
          navigateTo={"combustion"}
        />
        <CarouselItem
          image="chemicalPumps.jpg"
          caption="Chemical Pumps"
          navigateTo={"chemicalPumps"}
        />
        <CarouselItem
          image="ElectricPneumatics.png"
          caption="Electrical  Devices Replace Pneumatics"
          navigateTo={"electrical-devices"}
        />
        <CarouselItem
          image="InstrumentAir.png"
          caption="Instrument Air and Power on Demand"
          navigateTo={"instrument-air"}
        />
        <CarouselItem
          image="retrofit.jpg"
          caption="POWER GENERATION RETROFITS"
          navigateTo={"power-generation-retrofit"}
        />
        <CarouselItem
          image="Detection.png"
          caption="Detection, Measurement, Quantification, and Monitoring"
          navigateTo={"detection-measurement-quantification-monitoring"}
        />
        <CarouselItem
          image="ResearchTechnology.png"
          caption="Research and Testing of New Technologies"
          navigateTo={"research-new-technologies"}
        />
        <CarouselItem
          image="EmissionsReduction.png"
          caption="Managing Emissions Reduction and Limited Resources"
          navigateTo={"managing-emissions"}
        />
        <CarouselItem
          image="MethaneEmissions.png"
          caption="Initiatives to Reuce Methane Emissions"
          navigateTo={"prioritizing-iniatives"}
        />
      </Carousel>
    </div>
  );
}
