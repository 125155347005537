import React from "react";

export default function VersionHistoryCard() {
  return (
    <div className="versionCard">
      <div className="versionHistoryText">
        Version History (Ver 1.0) - Click <a href="/version-history">HERE</a>
      </div>
    </div>
  );
}
