import validator from "validator"
const nonErrorObject = {
    hasError: false,
    message: ""
}
const requiredErrorObject = {
    hasError: true,
    message: "*Required"
}

const checkName = (value, errorUpdater) => {
    let passed = false
    // Can only contain letters(a-z, A-Z), hyphens (-), and apostrophes(').
    const nameRegex = /^[a-zA-Z'-]+$/
    if (!value) {
        errorUpdater(requiredErrorObject)
    } else if (value.length > 30) {
        errorUpdater({
            hasError: true,
            message: "*Name is too long. \n Keep under 30 characters."
        })
    } else if (!nameRegex.test(value)) {
        errorUpdater({
            hasError: true,
            message: "*Can only contain letters(a-z, A-Z), hyphens (-), and apostrophes(')."
        })
    } else {
        errorUpdater(nonErrorObject)
        passed = true
    }
    return passed
}
const checkCompanyNameAndPosition = (value, updater, errorUpdater) => {
    let passed = false
    // Can only contain letters(a-z, A-Z), numbers,  hyphens (-), and apostrophes(').
    const regex = /^[a-zA-Z0-9'-\s]+$/

    if (!value) {
        errorUpdater(requiredErrorObject)
    } else if (value.length > 50) {
        errorUpdater({
            hasError: true,
            message: "*Name is too long. \n Keep under 30 characters."
        })
    } else if (!regex.test(value)) {
        errorUpdater({
            hasError: true,
            message: "*Can only contain letters(a-z, A-Z), numbers (0-9), hyphens (-), and apostrophes(')."
        })
    } else {
        const currentValue = value
        const trimmedValue = currentValue.trim()
        const singleSpacedValue = trimmedValue.replace(/\s+/g, ' ')
        updater(singleSpacedValue)
        errorUpdater(nonErrorObject)
        passed = true
    }

    return passed
}

const checkEmail = (value, errorUpdater) => {
    let passed = false

    if (!value) {
        errorUpdater(requiredErrorObject)
    } else if (!validator.isEmail(value)) {
        errorUpdater({
            hasError: true,
            message: "*Invalid email."
        })
    } else {
        errorUpdater(nonErrorObject)
        passed = true
    }

    return passed
}


const checkPassword = (password, confirmPassword, passwordErrorUpdater, confirmErrorUpdater) => {
    let passed = false

    const lowercaseRegex = /(?=.*[a-z])/;
    const uppercaseRegex = /(?=.*[A-Z])/;
    const numberRegex = /(?=.*\d)/;
    const specialCharRegex = /(?=.*[^\da-zA-Z])/;

    if (password.length < 8) {
        passwordErrorUpdater({
            hasError: true,
            message: "*Must be at least 8 characters."
        })
    } else if (!uppercaseRegex.test(password)) {
        passwordErrorUpdater({
            hasError: true,
            message: "*Must contain at least 1 uppercase letter."
        })
    } else if (!lowercaseRegex.test(password)) {
        passwordErrorUpdater({
            hasError: true,
            message: "*Must contain at least 1 lowercase letter."
        })
    } else if (!numberRegex.test(password)) {
        passwordErrorUpdater({
            hasError: true,
            message: "*Must contain at least 1 number."
        })
    } else if (!specialCharRegex.test(password)) {
        passwordErrorUpdater({
            hasError: true,
            message: "*Must contain at least 1 special character."
        })
    } else if (password !== confirmPassword) {
        confirmErrorUpdater({
            hasError: true,
            message: "*Passwords do not match"
        })
        passwordErrorUpdater({
            hasError: true,
            message: ""
        })
    } else {
        passwordErrorUpdater(nonErrorObject)
        confirmErrorUpdater(nonErrorObject)
        passed = true
    }

    return passed
}

export { checkName, checkEmail, checkCompanyNameAndPosition, checkPassword }