import { useState, useEffect } from "react";
import { forgotPassword } from "../../APIs/databaseAPI";

// Styles

import "./loginStyles.css";
import TextInput from "../../Components/TextInput";

import { Box } from "@mui/material";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");

  const onSubmit = () => {
    forgotPassword(email, function (response) {});
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);

  const [switchChecked, setSwitchChecked] = useState(false);

  const handleEmailSwitch = () => {
    setSwitchChecked(false);
  };

  return (
    <div className="loginPage">
      <div className="loginMain">
        <div className="ptacLogoContainer">
          <Box
            component="img"
            sx={{
              p: "0",
              width: 400,
              maxWidth: { xs: 250, md: 300 },
              maxHeight: { xs: 150, md: 300 },
            }}
            src="/images/carbonEmissionsLogo.png"
          />
        </div>
        <div className="loginTitleContainer">
          <p className="loginTitle bold-white-font">
            Tank Venting Eco-Efficiency and Operations Handbook
          </p>
        </div>

        <div className="loginContainer">
          <div className="loginSubTitleContainer">
            <p className="loginSubTitle bold-white-font">Forgot Password</p>
          </div>
          <div className="inputContainer">
            <div className="inputSwitchContainer">
              <div className="emailPasswordWrapper">
                <div className="loginInputWrapper">
                  <TextInput
                    label="Email"
                    value={email}
                    valueChangeHandler={setEmail}
                    type="text"
                    isRequired={true}
                    inputStyle="loginInput"
                    labelStyle="bold-400-white "
                    onFocus={handleEmailSwitch}
                  />
                </div>

                <div className="flex-center">
                  <button className="loginButton" onClick={onSubmit}>
                    Send Reset Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
