import React, { useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import CaseStudy from "../../../Components/CaseStudy";
import Footer from "../../../Components/Footer/Footer";
import "./caseStudyStyles.css";

export default function CombustionAndPower() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  const combustionParagraphs = [
    "This case study examines the effectiveness of a solution for reducing methane emissions by converting them into carbon dioxide, water vapor, and heat through a combustor. One of Questor's clients implemented this solution by installing 13 Q-Series Thermal Oxidizers to replace their previous methane emission venting practices. At three of these sites, the thermal oxidizers generate sufficient heat from combustion to power drilling operations, and Questor is now installing three 200 kW Organic Rankine Cycle power generators to transform waste heat into usable power.",
    "The adoption of this solution has not only been beneficial for reducing methane emissions but has also helped ensure necessary permitting for existing and future projects. The total gas flow measured at 3.5 mmscf/d, and the clean combustion achieved 99.99% efficiency, resulting in a greenhouse gas reduction of 1487 tonnes CO2e/d, all at a cost of less than $2.00/tonne. The total project cost amounted to $8.9 million over a 10-year period.",
    "Overall, this case study showcases the effectiveness of using a combustor to reduce methane emissions, with significant cost and environmental benefits. By implementing this solution, the client has been able to achieve a cleaner, more sustainable energy production process while ensuring compliance with regulatory requirements for greenhouse gas emissions.",
  ];

  return (
    <div className="caseStudyPage">
      <div className="caseStudyBackground">
        <CaseStudy
          title="Combustion and Power Generation"
          paragraphs={combustionParagraphs}
          hasButton={true}
          className="caseStudy"
          buttonText={"Go Back"}
          buttonNavigateTo="/resources"
          smallImage={"combustionSmall.jpg"}
        />
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
