import React from "react";
import { TextField } from "@mui/material";
import PopoverComponent from "./Popover";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
/**
 *
 * @param {String} label - Label for the input box.
 * @param {String/hook}  value - The state hook that holds the value of the input.
 * @param {Function}  valueChangeHandler - The setter for the value.
 * @param {String} type -  Type of input. Ex. "text", "number"
 * @param {Boolean}  isRequired - True/False value that indicates if the input is required or not.
 * @param {Boolean} error - True/False value indicating if validation has failed for this value. Making the border of the input go red.
 * @param {String} helperText - Text that dynamically renders depending on what made the validation fail.
 * @param {String}  popoverText - Text for the information "i" icon. (OPTIONAL)
 * @param {CSS classname / String}  inputStyle - className for the style of the input.
 * @param {CSS classname / String} labelStyle - className for the style of the label.
 *
 * @returns
 */
export default function TextInput({
  label,
  value,
  valueChangeHandler,
  type,
  isRequired,
  error,
  helperText,
  popoverText,
  inputStyle,
  labelStyle,
  onFocus,
  onBlur,
  onKeyDown,
  page,
}) {
  // Handles the value change of the input.
  const handleOnChange = (e) => {
    const newValue = e.target.value;
    valueChangeHandler(newValue);
  };
  return (
    <div className="flexWrapper">
      <div className="innerFlexWrapper flex-row-align">
        <label
          className={labelStyle ? labelStyle : null}
          htmlFor={label + "ID"}
        >
          {label}
        </label>

        {popoverText ? (
          <div>
            <PopoverComponent className="inputLabel" infoText={popoverText} />
          </div>
        ) : null}
        {page === "login" && value.length > 0 ? (
          <CheckCircleOutlineIcon
            style={{ fontSize: 20, color: "green", marginLeft: "5px" }}
          />
        ) : null}
      </div>

      <TextField
        value={value}
        onChange={(e) => handleOnChange(e)}
        type={type}
        required={isRequired}
        error={error}
        helperText={helperText}
        id={label + "ID"}
        size="small"
        InputProps={{
          style: {
            color: inputStyle === "loginInput" ? "white" : null,
          },
        }}
        className={inputStyle}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}
