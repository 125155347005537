import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Popover, Typography, styled } from "@mui/material";

//Styles
import "./componentStyles.css";

const StyledPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    // Remove the padding added by Popover
    padding: 0,
  },
  // Optional: set the z-index of the Popover to move it above other elements
  zIndex: theme.zIndex.modal + 1,
}));

/**
 *
 * @param {String} infoText - The text that should be inside the info popover.
 * @returns An info "i" icon that displays additional information onMouseEnter / onMouseLeave
 */
export default function PopoverComponent({ infoText }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div>
      <InfoOutlinedIcon
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className="infoIcon"
        fontSize="small"
      />

      <StyledPopover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
      >
        {infoText
          ? infoText.map(function (text, index) {
              return (
                <Typography className="popoverText" key={text + index}>
                  {text}
                </Typography>
              );
            })
          : null}
      </StyledPopover>
    </div>
  );
}
