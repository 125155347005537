import React, { useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
//Components
import CaseStudy from "../../../Components/CaseStudy";
import Footer from "../../../Components/Footer/Footer";

export default function Retrofit() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  const researchParagraphs = [
    'The case studies discussed earlier demonstrate that supplying power to remote sites can substantially decrease methane emissions from various sources, particularly in "greenfield" applications. PTAC\'s STV project also revealed the considerable advantages of retrofitting power into larger remote "brownfield" sites, where grid power was unavailable. Due to the high power demand at these locations, relying solely on solar energy was typically not considered a feasible option.',
    "Integrating alternative power solutions into both new and existing sites offers a promising approach for reducing methane emissions and ensuring sustainable operations in the oil and gas industry. By exploring and implementing innovative power solutions that combine solar, battery, and other technologies, companies can achieve significant environmental benefits and potentially reduce operational costs. This approach underscores the importance of continued research and development in alternative energy solutions for remote and off-grid locations in order to maximize efficiency and minimize environmental impact.",
  ];

  return (
    <div className="caseStudyPage">
      <div className="caseStudyBackground">
        <CaseStudy
          title="Power Generation Retrofits"
          paragraphs={researchParagraphs}
          hasButton={true}
          className="caseStudy"
          buttonText={"Go Back"}
          buttonNavigateTo="/resources"
          bigImage={"retrofitBig.png"}
          smallImage={"retrofitSmall.jpg"}
        />
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
