import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

export default function SuccessfulRegisterDialog({
  open,
  handleOpen,
  handleClose,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="flex-row-align">
            <div>You've successfully created your account!</div>
            <div>
              <CheckCircleOutlinedIcon
                style={{ color: "green", margin: "10px 0 0 10px" }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Login with your new account <a href="/">click here.</a>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
