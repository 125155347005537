import React from "react";

// Styles
import "./componentStyles.css";

/**
 *
 * @param {String} title - Text for title.
 * @param {Array of strings} points - Text for body.
 * @param {Boolean} indent - True/False value that indicates to indent the point.
 * @returns
 */
export default function TitleAndPoint({ title, points, indent }) {
  return (
    <div className="indent">
      <h3 className="zero-all-around-margin white-font font-weight-300 titleSummary">
        {title}
      </h3>

      {points.map(function (point, index) {
        return (
          <p
            className="pointSummary white-font font-weight-300  justifyParagraph"
            style={{ marginLeft: indent ? "3%" : null }}
          >
            {point}
          </p>
        );
      })}
    </div>
  );
}
