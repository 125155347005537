import React from "react";
import { Card, Grid, Divider, Box } from "@mui/material";

export default function CompanyCard({
  companyName,
  contactInformation,
  companyWebsite,
  companyLocation,
  companyLogo,
  secondaryCategories,
  pageNumber,
}) {
  return (
    <div className="companyCard margin-around-5-percent">
      <Card>
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <a href={"company-flip-book/" + pageNumber}>
              <h3 className="indent">{companyName}</h3>
            </a>
            <a href={"https://" + companyWebsite}>
              <p className="indent">{companyWebsite}</p>
            </a>
            <div className="indent">
              <h5>Location</h5>
              <p className="indent">{companyLocation}</p>
              <h5>Contact Information</h5>
              <div className="contactInfo ">
                {contactInformation.name}
                {contactInformation.title
                  ? ", " + contactInformation.title
                  : null}
              </div>
              <div className="contactInfo ">{contactInformation.email}</div>
              <div className="contactInfo ">{contactInformation.phone}</div>
            </div>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              component="img"
              sx={{
                p: "1em",
                maxWidth: { xs: 200, sm: 200, md: 250 },
                maxHeight: { xs: 200, sm: 200, md: 200 },
              }}
              alt="PTAC Logo."
              src={companyLogo}
            />
            <div className="margin-around-5-percent">
              <h5>Additional Services (Optional)</h5>
              <ol>
                {secondaryCategories.map(function (category, index) {
                  return <li>{category}</li>;
                })}
              </ol>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}
