import React, { useState } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Grid, IconButton, TextField, Divider } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
// Components
import CalculatorInput from "./CalculatorInput";
import AssumptionDialog from "./AssumptionDialog";
// Styles
import "./calculatorComponentStyles.css";

// API
import { getCoordinates } from "../../APIs/mapAPI";

/**
 * 
 * @param {String} type - type of input. Ex. "text".
 * @param {String} title - Title of the section.
 * @param {Array} inputs - Each item will be a useState hook that holds the value of each input.
 * @param {Array} inputValueSetters - Each item will be useState setter that changes the value of each input.
 * @param {Array} labels - Each item will be the label of each input.
 * @param {Array} infoTexts - Each item will be the string for the info icon popover.
 * @param {String} summaryTitle - title of the summary section
 * @param {Array} summaryInputs - Each item will be a useState hook that holds the value of each summary input.
 * @param {Array} summaryValueSetters - Each item will be useState setter that changes the value of each summary input.
 * @param {Array} summaryLabels - Each  item will be the label of each summary input.
 * @param {String} assumptionTitle - Main title at the top of dialog.
 * **IMPORTANT - assumptionSubTitles, assumptionInputs, assumptionLabels, and assumptionValueSetters must be in the same order. 
 * **IMPORTANT - Each item is for one section of the array. So if you only need one section (aka no subtitle/one subtitle). You're array will only hold on array item. 
 * @example assumptionInputs [[firstSectionOfInputs], [secondSectionOfInputs]]
 * @param {Nested array of strings} assumptionSubTitles - Each item will be an array that holds subtitles for sections of the assumptions.
 * @param {Nested array of strings} assumptionInputs- Each item will be an array of useState hooks that hold the value of each input.
 * @param {Nested array of strings} assumptionLabels - Each item will be an array of labels for each input. 
 * @param {Nested array of strings} assumptionValueSetters - Each item will be an array of useState setters that change the value of each input. 
 * **IMPORTANT - For having a select box beside each input. Must  also be in order as the assumptionInputs..etc.
 * @param {Array of strings} assumptionSelectValues - Array of useState hooks that hold the current value of select option. Also acts as the default value.
 * @param {Nested array of strings} assumptionSelectSetters - Each item will be an array of strings that represent the options for each select box.
 * @param {Array of strings} assumptionSelectOptions - Array of useState setters that change the value of each select option.
 * **IMPORTANT - To create a select box that doesnt need an input box beside it. An isolated select box.
 * @param {Array of strings} assumptionIsolateSelectOptions - Each item will be an array of strings that represent the options for each select box.
 * @param {Array of strings} assumptionInfoTexts - Each item will be an array of strings that represent the options for each select box in the assumptiion dialog.
 * @param {Array of strings} assumptionAdorments - Each item will be an array of strings that represent the units for each input in the assumption dialog.
 *@param {Array of strings} selectOptions - Options for the select box.
 *@param {String} selectDefaultOption - Default value for select box.
 *@param {useState hook} selectValue - value for the select box.
 *@param {Function} selectValueSetter - Setter for the value of select box.
 @param {String} commentTitle - String for the title of the comment box
 @param {Setter} commentSetter - setter for changing the text value of the comment box.
 @param {String} commentValue - the variable that holds the value of the comment
 @param {Boolean} needsMap - True/false variable determining if a google map should render in this section or not (Mostly for the site information section)
 * @returns 
 */
export default function CalculatorSection({
  type,
  title,
  inputs,
  inputMinMaxRange,
  inputValueSetters,
  labels,
  infoTexts,
  summaryTitle,
  summaryInputs,
  summaryValueSetters,
  summaryLabels,
  assumptionTitle,
  assumptionSubTitles,
  assumptionInputs,
  assumptionValueSetters,
  assumptionLabels,
  selectOptions,
  selectDefaultOption,
  selectValues,
  selectValueSetters,
  assumptionSelectValues,
  assumptionSelectSetters,
  assumptionSelectOptions,
  assumptionIsolateSelectOptions,
  assumptionInfoTexts,
  assumptionAdorments,
  commentTitle,
  commentValue,
  commentSetter,
  adornments,
  needsMap,
  currentUWI,
  locationSetter,
  assumptionDefaults,
  assumptionSelectDefaults,
}) {
  // boolean for the open state of dialog.
  const [dialogOpen, setDialogOpen] = useState(false);
  // Opens the dialog
  const handleClickOpenAssumptionDialog = () => {
    setDialogOpen(true);
  };
  // Closes the dialog
  const handleCloseAssumptionDialog = () => {
    setDialogOpen(false);
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  // Center of map
  const [center, setCenter] = useState({ lat: 44, lng: -80 });
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState();
  const handleMapLoad = (map) => {
    setMap(map);
  };

  const locateUWI = () => {
    getCoordinates(currentUWI, function (response) {
      const thisLat = response.lat;
      const thisLng = response.lng;

      const location = { lat: thisLat, lng: thisLng };
      locationSetter(location);
      setMarker(location);
      setCenter(location);
    });
  };

  return (
    <div className="sectionCard">
      <div className="sectionSubTitle">
        <label>{title}</label>
        {assumptionTitle ? (
          <IconButton onClick={handleClickOpenAssumptionDialog}>
            <AddCircleIcon />
          </IconButton>
        ) : null}
      </div>
      <div>
        <AssumptionDialog
          open={dialogOpen}
          handleClose={handleCloseAssumptionDialog}
          assumptionTitle={assumptionTitle}
          assumptionSubTitles={assumptionSubTitles}
          assumptionInputs={assumptionInputs}
          assumptionLabels={assumptionLabels}
          assumptionValueSetters={assumptionValueSetters}
          type={type}
          assumptionSelectValues={assumptionSelectValues}
          assumptionSelectSetters={assumptionSelectSetters}
          assumptionSelectOptions={assumptionSelectOptions}
          assumptionIsolateSelectOptions={assumptionIsolateSelectOptions}
          assumptionInfoTexts={assumptionInfoTexts ? assumptionInfoTexts : null}
          assumptionAdorments={assumptionAdorments ? assumptionAdorments : null}
          assumptionDefaults={assumptionDefaults ? assumptionDefaults : null}
          assumptionSelectDefaults={
            assumptionSelectDefaults ? assumptionSelectDefaults : null
          }
        />
      </div>
      <Grid container>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <div className="calculatorInputsContainer">
            {inputs.map(function (input, index) {
              return (
                <div>
                  {labels[index] === "Net Throughput" ? (
                    <Divider
                      sx={{
                        backgroundColor: "rgba(134,133,135, 0.8)",
                        width: "100%",
                      }}
                      variant="middle"
                    />
                  ) : null}
                  <CalculatorInput
                    className="calculationInputAndLabel"
                    key={labels[index] + "Input"}
                    value={input}
                    inputMinMaxRange={
                      inputMinMaxRange ? inputMinMaxRange[index] : null
                    }
                    // Normal Inputs
                    label={labels[index]}
                    popoverText={infoTexts ? [infoTexts[index]] : null}
                    adornments={adornments ? adornments[index] : null}
                    valueChangeHandler={inputValueSetters[index]}
                    type={type}
                    isRequired={false}
                    //Select Options
                    selectOptions={selectOptions ? selectOptions : null}
                    selectDefaultOption={
                      selectDefaultOption ? selectDefaultOption : null
                    }
                    selectValue={selectValues ? selectValues[index] : null}
                    selectValueSetter={
                      selectValueSetters ? selectValueSetters[index] : null
                    }
                    onBlur={locateUWI}
                  />
                </div>
              );
            })}
          </div>
        </Grid>

        {commentTitle ? (
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Grid container>
              {needsMap === true ? (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {isLoaded ? (
                    <div className="mapWrapper">
                      <div className="mapLabel">
                        <label>Map/UWI Location</label>
                      </div>

                      <div>
                        <GoogleMap
                          zoom={10}
                          center={center}
                          mapContainerClassName="mapContainer"
                          onLoad={handleMapLoad}
                        >
                          {marker && <Marker position={marker} />}{" "}
                        </GoogleMap>
                      </div>
                    </div>
                  ) : (
                    <div>Loading...</div>
                  )}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
        {commentTitle ? (
          <Grid item lg={5.7} md={5.7} sm={12} xs={12}>
            <div className="commentContainer">
              <TextField
                className="commentInput"
                label={commentTitle}
                multiline
                rows={5}
                value={commentValue}
                variant="outlined"
                onChange={(e) => commentSetter(e.target.value)}
              />
            </div>
          </Grid>
        ) : null}

        {summaryTitle ? (
          <Grid item lg={5} md={5} sm={10} xs={10}>
            <div
              className={
                summaryTitle === "Fugitive Emissions"
                  ? "fugitiveSummaryInputs"
                  : "summaryInputs"
              }
            >
              <div className="summaryTitle">{summaryTitle}</div>
              <div className="flex-column-align">
                {summaryInputs.map(function (input, index) {
                  return (
                    <CalculatorInput
                      key={summaryLabels[index]}
                      value={input}
                      label={summaryLabels[index]}
                      disabled={true}
                      isSummaryInput={true}
                    />
                  );
                })}
              </div>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}
