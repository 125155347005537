import React, { useState, useEffect } from "react";

import CalculatorSection from "../../../Components/Calculator/CalculatorSection";

// API
import { getTankSubTotal } from "../../../APIs/calculatorAPI";
export default function TankEmissions(
  methaneMolPercentage,
  setMethaneMolPercentage
) {
  // Fluid Composition
  const [wct, setWct] = useState("");
  const [gasOilRatio, setGasOilRatio] = useState("");
  const [api, setApi] = useState("");
  const gasOilMinMax = { min: 3.5, max: 369 };
  const apiMinMax = { min: 16, max: 58 };
  const fluidCompositionLabels = ["WCT %", "Gas Oil Ratio", "API"];
  const infoTexts = [
    "Water cut percentage is the percentage of water produced along with oil or gas from a well, calculated as the volume of water produced divided by the total volume of fluids produced %",
    "Value must be between 3.5 - 369. Oil Gas Ratio (OGR) is the ratio of the volume of oil to the volume of gas produced from a well or a reservoir - cubic meters of oil per thousand cubic meters of gas (m³/m³)",
    "Value must be between 16 - 58. API is used to refer to the API gravity, which is a measure of the density of petroleum liquids relative to water and is expressed in degrees and is used to classify crude oil based on its density, with higher API numbers indicating lighter, more easily refined crude oil.",
  ];

  // Fluid Composition Assumptions
  // Its hook is being passed down by CalculationsPage.js
  const fluidCompositionAssumptionLabels = ["Methane Mol %"];
  const fluidCompositionInfoTexts = [
    ["Incorporate and report: Methane Mol Percentage (%) of the Gas."],
  ];
  const fluidDefaults = [["93"]];

  // Tank Emission Factors
  const [maxTankCapacity, setMaxTankCapacity] = useState("");
  const [turnoverPerMonth, setTurnoverPerMonth] = useState("");
  const [netThroughput, setNetThroughPut] = useState("");
  const tankEmissionFactorLabels = [
    "Max Oil Vol. Tank Capacity",
    "Turnover per Month",
    "Net Throughput",
  ];
  const tankEmissionInfoTexts = [
    "Capacity Maximum oil volume tank capacity refers to the maximum amount of oil that can be stored in an oil storage tank, expressed in barrels (bbl) and is determined by the size and specifications of the tank. (Bbl)",
    "Average/Estimated total amount of Events for processed oil in and out of the tank within a given month (it is calculated by dividing the total volume of product that has been processed through the tank during the month by the tank's storage capacity).",
    "Net throughput of oil refers to the total amount of oil that is processed. (Bbl/month)",
  ];
  const tankAdornments = ["Bbl", null, "Bbl/month"];

  // Tank Emission Assumptions
  const [numberOfTanks, setNumberOfTanks] = useState("1");
  const [gaugePressureTest, setGaugePressureTest] = useState("200");
  const [gaugePressureTank, setGaugePressureTank] = useState("0");
  const tankEmissionAssumptionLabels = [
    "Number of Tanks",
    "Pg @ test",
    "Pg @ tank",
  ];
  const tankAssumptionInfoText = [
    [null, "Gauge pressure at test. (kPa)", "Gauge pressure at tank. (kPa)"],
  ];
  const tankAssumptionAdornments = [[null, "kPa", "kPa"]];
  const tankDefaults = [["1", "200", "0"]];

  // Summary Inputs
  const [kgCH4PerMonth, setKgCH4PerMonth] = useState("");
  const [tco2e, setTco2e] = useState("");
  const summaryLabels = ["KgCH4/Month", "tC02e/Year"];

  useEffect(() => {
    getTankSubTotal(
      "tank",
      methaneMolPercentage,
      maxTankCapacity,
      turnoverPerMonth,
      numberOfTanks,
      gaugePressureTest,
      gaugePressureTank,
      function (response) {
        const monthly = response[0];
        const yearly = response[1];
        setKgCH4PerMonth(monthly);
        setTco2e(yearly);
      }
    );
  }, [
    gaugePressureTank,
    gaugePressureTest,
    maxTankCapacity,
    methaneMolPercentage,
    numberOfTanks,
    turnoverPerMonth,
  ]);

  useEffect(() => {
    if (maxTankCapacity && turnoverPerMonth) {
      setNetThroughPut(maxTankCapacity * turnoverPerMonth);
    }
  }, [maxTankCapacity, turnoverPerMonth]);

  const tankEmissionInputs = {
    tankEmissionFactors: {
      maxTankCapacity,
      turnoverPerMonth,
      netThroughput,
      numberOfTanks,
      gaugePressureTest,
      gaugePressureTank,
    },
    fluidComposition: { wct, gasOilRatio, api, methaneMolPercentage },
  };

  return {
    tankEmissionInputs,
    tankEmissionsSection: (
      <div>
        <div className="white-font sectionTitle">Tank Emission</div>
        <CalculatorSection
          title="Fluid Composition"
          // Inputs
          inputs={[wct, gasOilRatio, api]}
          inputMinMaxRange={[null, gasOilMinMax, apiMinMax]}
          inputValueSetters={[setWct, setGasOilRatio, setApi]}
          labels={fluidCompositionLabels}
          infoTexts={infoTexts}
          type="number"
          // Assumptions
          assumptionTitle="Fluid Composition Assumptions"
          assumptionInputs={[[methaneMolPercentage]]}
          assumptionLabels={[fluidCompositionAssumptionLabels]}
          assumptionValueSetters={[[setMethaneMolPercentage]]}
          assumptionInfoTexts={fluidCompositionInfoTexts}
          assumptionDefaults={fluidDefaults}
        />

        <CalculatorSection
          title="Tank Emission Inputs"
          // Inputs
          inputs={[maxTankCapacity, turnoverPerMonth, netThroughput]}
          inputValueSetters={[setMaxTankCapacity, setTurnoverPerMonth]}
          labels={tankEmissionFactorLabels}
          infoTexts={tankEmissionInfoTexts}
          type="number"
          // Summary Inputs
          summaryTitle="Tank Emission Summary"
          summaryInputs={[kgCH4PerMonth, tco2e]}
          summaryLabels={summaryLabels}
          summaryValueSetters={[setKgCH4PerMonth, setTco2e]}
          // Assumption Inputs
          assumptionTitle="Tank Emission Assumptions"
          assumptionInputs={[
            [numberOfTanks, gaugePressureTest, gaugePressureTank],
          ]}
          assumptionValueSetters={[
            [setNumberOfTanks, setGaugePressureTest, setGaugePressureTank],
          ]}
          assumptionDefaults={tankDefaults}
          assumptionLabels={[tankEmissionAssumptionLabels]}
          assumptionInfoTexts={tankAssumptionInfoText}
          adornments={tankAdornments}
          assumptionAdorments={tankAssumptionAdornments}
        />
      </div>
    ),
  };
}
