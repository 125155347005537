import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { IconButton, Menu, MenuItem, Typography, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

//API
import { getCurrentUser } from "../APIs/databaseAPI";
import AuthContext from "../context/AuthContext";
export default function AvatarComponent() {
  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [user, setUser] = useState(null);
  useEffect(() => {
    getCurrentUser(function (response) {
      setUser(response);
    });
  }, []);

  const { getLoggedIn } = useContext(AuthContext);
  async function logOut() {
    //Actually delete the cookie
    await axios.get("https://api.carbonemissionscanada.com/auth/logout");
    //Send the cookie back to the server to update the state.
    await getLoggedIn();
    navigate("/");
  }

  return (
    <div>
      {/* Account Setting Avatar */}

      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <Avatar>
          {user ? user.firstName.substring(0, 1).toUpperCase() : null}
        </Avatar>
      </IconButton>

      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          onClick={() => {
            handleCloseUserMenu();
            navigate("/settings");
          }}
        >
          <Typography textAlign="center">Account Settings</Typography>
        </MenuItem>
        <MenuItem onClick={logOut}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}
