import React, { useState, useEffect } from "react";

import CalculatorSection from "../../../Components/Calculator/CalculatorSection";

// API
import { getFugitiveSubTotal } from "../../../APIs/calculatorAPI";

export default function FugitiveEmission() {
  // Inputs
  const [gasValves, setGasValves] = useState("");
  const [oilValves, setOilValves] = useState("");
  const [gasConnectors, setGasConnectors] = useState("");
  const [oilConnectors, setOilConnectors] = useState("");
  const [pumpSeals, setPumpSeals] = useState("");
  const [pressureReliefs, setPressureReliefs] = useState("");
  const [openEndedLines, setOpenEndedLines] = useState("");
  const fugitiveLabels = [
    "Gas Valve",
    "Oil Valve",
    "Gas Connector",
    "Oil Connector",
    "Pump Seals",
    "Pressure Relief",
    "Open-ended lines",
  ];
  const fugitiveInfoTexts = [
    "Number of Gas Valves.",
    "Number of Oil Valves.",
    "Number of Gas Connector.",
    "Number of Oil Connector.",
    "Number of Pump Seals.",
    "Number of Pressure Relief.",
    "Number of Open-ended lines.",
  ];

  // Select Options
  const ppmSelectOptions = ["low", "moderate", "high"];
  const ppmSelectDefaultOption = ppmSelectOptions[0];
  const [gasValvePPM, setGasValvePPM] = useState(ppmSelectDefaultOption);
  const [oilValvePPM, setOilValvePPM] = useState(ppmSelectDefaultOption);
  const [gasConnectorPPM, setGasConnectorPPM] = useState(
    ppmSelectDefaultOption
  );
  const [oilConnectorPPM, setOilConnectorPPM] = useState(
    ppmSelectDefaultOption
  );
  const [pumpSealsPPM, setPumpSealsPPM] = useState(ppmSelectDefaultOption);
  const [pressureReliefsPPM, setPressureReliefsPPM] = useState(
    ppmSelectDefaultOption
  );
  const [openEndedLinesPPM, setOpenEndedLinesPPM] = useState(
    ppmSelectDefaultOption
  );

  // Assumption Inputs
  const [gasValveHours, setGasValveHours] = useState("7200");
  const [oilValveHours, setOilValveHours] = useState("7200");
  const [gasConnectorHours, setGasConnectorHours] = useState("7200");
  const [oilConnectorHours, setOilConnectorHours] = useState("7200");
  const [pumpSealsHours, setPumpSealsHours] = useState("7200");
  const [pressureReliefsHours, setPressureReliefsHours] = useState("7200");
  const [openEndedLinesHours, setOpenEndedLinesHours] = useState("7200");
  const fugitiveDefaults = [
    ["7200", "7200", "7200", "7200", "7200", "7200", "7200"],
  ];

  const fugitiveHourLabels = fugitiveLabels.map(function (label, index) {
    return label + " Hours";
  });

  const hoursPerYear = "hours/year";
  const assumptionAdornments = [
    [
      hoursPerYear,
      hoursPerYear,
      hoursPerYear,
      hoursPerYear,
      hoursPerYear,
      hoursPerYear,
      hoursPerYear,
    ],
  ];

  // Assumption Select Values
  const sweetSourLightOptions = ["Sweet Gas", "Sour Gas", "Light Liquid"];
  const gasVapourLightLiquidOptions = ["Gas/Vapour", "Light Liquid"];
  const lightLiquidOption = gasVapourLightLiquidOptions[1];
  const gasVapourOption = gasVapourLightLiquidOptions[0];
  const allOption = "All";
  const [gasValveType, setGasValveType] = useState("sweet");
  const [oilValveType, setOilValveType] = useState("lightliquid");
  const [gasConnectorType, setGasConnectorType] = useState("sweet");
  const [oilConnectorType, setOilConnectorType] = useState("lightliquid");
  const [pumpSealsType, setPumpSealsType] = useState("lightliquid");
  const [pressureReliefsType, setPressureReliefsType] = useState("gasvapour");
  const [openEndedLinesType, setOpenEndedLinesType] = useState("all");
  const fugitiveSelectDefaults = [
    "sweet",
    "lightliquid",
    "sweet",
    "lightliquid",
    "lightliquid",
    "gasvapour",
    "all",
  ];

  const fugitiveEmissionInputs = {
    gasValves: {
      value: gasValves,
      ppm: gasValvePPM,
      hours: gasValveHours,
      type: gasValveType,
    },
    oilValves: {
      value: oilValves,
      ppm: oilValvePPM,
      hours: oilValveHours,
      type: oilConnectorType,
    },
    gasConnectors: {
      value: gasConnectors,
      ppm: gasConnectorPPM,
      hours: gasConnectorHours,
      type: gasConnectorType,
    },
    oilConnectors: {
      value: oilConnectors,
      ppm: oilConnectorPPM,
      hours: oilConnectorHours,
      type: oilConnectorType,
    },
    pumpSeals: {
      value: pumpSeals,
      ppm: pumpSealsPPM,
      hours: pumpSealsHours,
      type: pumpSealsType,
    },
    pressureReliefs: {
      value: pressureReliefs,
      ppm: pressureReliefsPPM,
      hours: pressureReliefsHours,
      type: pressureReliefsType,
    },
    openEndedLines: {
      value: openEndedLines,
      ppm: openEndedLinesPPM,
      hours: openEndedLinesHours,
      type: openEndedLinesType,
    },
  };

  // Summary Inputs
  const [kgCH4PerMonth, setKgCH4PerMonth] = useState("");
  const [tco2e, setTco2e] = useState("");
  const summaryLabels = ["KgCH4/Month", "tC02e/Year"];

  useEffect(() => {
    getFugitiveSubTotal(
      "fugitive",
      fugitiveEmissionInputs.gasValves,
      fugitiveEmissionInputs.oilValves,
      fugitiveEmissionInputs.gasConnectors,
      fugitiveEmissionInputs.oilConnectors,
      fugitiveEmissionInputs.pumpSeals,
      fugitiveEmissionInputs.pressureReliefs,
      fugitiveEmissionInputs.openEndedLines,
      function (response) {
        const monthly = response[0];
        const yearly = response[1];
        setKgCH4PerMonth(monthly);
        setTco2e(yearly);
      }
    );
  }, [
    fugitiveEmissionInputs.gasConnectors,
    fugitiveEmissionInputs.gasValves,
    fugitiveEmissionInputs.oilConnectors,
    fugitiveEmissionInputs.oilValves,
    fugitiveEmissionInputs.openEndedLines,
    fugitiveEmissionInputs.pressureReliefs,
    fugitiveEmissionInputs.pumpSeals,
    gasValves,
  ]);

  return {
    fugitiveEmissionInputs,
    fugitiveEmissionSection: (
      <div>
        <div className="white-font sectionTitle">Fugitive Emission</div>
        <CalculatorSection
          title="Fugitive Emission Inputs"
          // Inputs
          inputs={[
            gasValves,
            oilValves,
            gasConnectors,
            oilConnectors,
            pumpSeals,
            pressureReliefs,
            openEndedLines,
          ]}
          inputValueSetters={[
            setGasValves,
            setOilValves,
            setGasConnectors,
            setOilConnectors,
            setPumpSeals,
            setPressureReliefs,
            setOpenEndedLines,
          ]}
          labels={fugitiveLabels}
          infoTexts={fugitiveInfoTexts}
          type="number"
          // Select Options
          selectOptions={ppmSelectOptions}
          selectValues={[
            gasValvePPM,
            oilValvePPM,
            gasConnectorPPM,
            oilConnectorPPM,
            pumpSealsPPM,
            pressureReliefsPPM,
            openEndedLinesPPM,
          ]}
          selectValueSetters={[
            setGasValvePPM,
            setOilValvePPM,
            setGasConnectorPPM,
            setOilConnectorPPM,
            setPumpSealsPPM,
            setPressureReliefsPPM,
            setOpenEndedLinesPPM,
          ]}
          // Summary Inputs
          summaryTitle="Fugitive Emissions"
          summaryInputs={[kgCH4PerMonth, tco2e]}
          summaryLabels={summaryLabels}
          summaryValueSetters={[setKgCH4PerMonth, setTco2e]}
          // Assumption Inputs
          assumptionTitle="Fugitive Emission Assumptions"
          assumptionInputs={[
            [
              gasValveHours,
              oilValveHours,
              gasConnectorHours,
              oilConnectorHours,
              pumpSealsHours,
              pressureReliefsHours,
              openEndedLinesHours,
            ],
          ]}
          assumptionLabels={[fugitiveHourLabels]}
          assumptionValueSetters={[
            [
              setGasValveHours,
              setOilValveHours,
              setGasConnectorHours,
              setOilConnectorHours,
              setPumpSealsHours,
              setPressureReliefsHours,
              setOpenEndedLinesHours,
            ],
          ]}
          // Assumption Select Options
          assumptionSelectOptions={[
            sweetSourLightOptions,
            gasVapourLightLiquidOptions,
            sweetSourLightOptions,
            gasVapourLightLiquidOptions,
            [lightLiquidOption],
            [gasVapourOption],
            [allOption],
          ]}
          assumptionSelectValues={[
            gasValveType,
            oilValveType,
            gasConnectorType,
            oilConnectorType,
            pumpSealsType,
            pressureReliefsType,
            openEndedLinesType,
          ]}
          assumptionSelectSetters={[
            setGasValveType,
            setOilValveType,
            setGasConnectorType,
            setOilConnectorType,
            setPumpSealsType,
            setPressureReliefsType,
            setOpenEndedLinesType,
          ]}
          assumptionAdorments={assumptionAdornments}
          assumptionDefaults={fugitiveDefaults}
          assumptionSelectDefaults={fugitiveSelectDefaults}
        />
      </div>
    ),
  };
}
