import React from "react";
import { styled, FormControlLabel, Switch } from "@mui/material/";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 120,
  height: 40,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(2px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(75px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/loginIcons/loginKey.png)`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor:
      theme.palette.mode === "dark" ? "#003892" : "rgb(17,86,172)",
    width: 40,
    height: 40,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/loginIcons/loginMail.png)`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

/**
 *
 * @returns A customized toggle switch for the login page. Toggles onFocus.
 * OnFocus Email - switch should be false and at the top of the track.
 * OnFocus Password - switch should be true and at the bottom of the track.
 */
export default function CustomizedSwitches({ checked }) {
  return (
    <div>
      <FormControlLabel
        control={<MaterialUISwitch sx={{ m: 1 }} checked={checked} />}
      />
    </div>
  );
}
