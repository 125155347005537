import React, { useEffect, useRef } from "react";

//Components
import { TextField, Select, MenuItem, InputAdornment } from "@mui/material";
import PopoverComponent from "../Popover";

/**
 *
 *@param {useState hook} value - useState hook that holds the value of input.
 *@param {Function} valueChangeHandler - Function/setter that changs the value of the value.
 *@param {String} type - type of input. Ex. "text"
 *@param {Boolean} isRequired - Determines of the input is required.
 *@param {String} popoverText - Text that fills the info icon popover.
 *@param {Boolean} disabled -Determines if the input should be disabled.
 *@param {Boolean} isSummaryInput - Determines the styling if the input is for calculation or a summary input.
 *@param {Array of strings} selectOptions - Options for the select box.
 *@param {String} selectDefaultOption - Default value for select box.
 *@param {useState hook} selectValue - value for the select box.
 *@param {Function} selectValueSetter - Setter for the value of select box.
 *@param {String} adornments - Unit for the end adornment.
 * @returns A label + input + popover info icon (optional) + select box (optional).
 */
export default function CalculatorInput({
  label,
  value,
  inputMinMaxRange,
  valueChangeHandler,
  type,
  isRequired,
  popoverText,
  disabled,
  isSummaryInput,
  selectOptions,
  selectDefaultOption,
  selectValue,
  selectValueSetter,
  adornments,
  onBlur,
}) {
  // Event handler for the text change.
  const handleOnChange = (event) => {
    const newValue = event.target.value;
    if (valueChangeHandler) {
      valueChangeHandler(newValue);
    }
  };

  // makes the uwi format look like
  // 100 / 06-20-38-04W5 / 00
  function handleUwiChange(event) {
    let value = event.target.value;

    // Remove all non-digit and non-letter characters from the input
    value = value.replace(/[^0-9a-zA-Z]/g, "");
    // Insert the separator characters at the appropriate positions
    if (value.length > 3 && value.charAt(3) !== " ") {
      value = value.slice(0, 3) + " / " + value.slice(3);
    }
    if (value.length > 8 && value.charAt(8) !== "-") {
      value = value.slice(0, 8) + "-" + value.slice(8);
    }
    if (value.length > 11 && value.charAt(11) !== "-") {
      value = value.slice(0, 11) + "-" + value.slice(11);
    }
    if (value.length > 15 && value.charAt(15) !== "-") {
      value = value.slice(0, 15) + "-" + value.slice(15);
    }
    if (value.length > 20 && value.charAt(20) !== " ") {
      value = value.slice(0, 20) + " / " + value.slice(20);
      // This is for COAST meridian (Alberta to BC implementation)
      // if (value.charAt(17).toUpperCase() !== "C") {
      //   console.log("HIT THIS");
      //   value = value.slice(0, 19) + " / " + value.slice(19);
      // } else {
      // }
    }

    // This is for COAST meridian (Alberta to BC implementation)
    // if (value.length > 22 && value.charAt(19) !== " ") {
    //   console.log(value.charAt(17));
    //   if (value.charAt(17).toUpperCase() === "C") {
    //     value = value.slice(0, 22) + " / " + value.slice(22);
    //   }
    // }

    valueChangeHandler(value);
  }

  // Event handler for dropdown select change.
  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    console.log(newValue);
    selectValueSetter(newValue);
  };

  // Prevents changing the value with scrolling.
  const myTextFieldRef = useRef(null);
  const preventIncrementScroll = (event) => {
    event.preventDefault();
  };

  // Stops the scrolling feature on the input box.
  useEffect(() => {
    const input = myTextFieldRef.current;
    input.addEventListener("wheel", preventIncrementScroll, { passive: false });
    return () => {
      input.removeEventListener("wheel", preventIncrementScroll);
    };
  }, []);

  // Returns a helper message for inputs with ranges.
  const checkRange = () => {
    const max = inputMinMaxRange.max;
    const min = inputMinMaxRange.min;
    if (value > max || value < min) {
      return (
        <div style={{ width: "100vh", marginLeft: "-10px" }}>
          {"Value must be between " + min + " - " + max}
        </div>
      );
    }
  };

  // includes a one word version of the option.
  // "sweet"
  // "sour"
  // "lightliquid"
  // "gasvapour"
  // "all"

  const getOption = (option) => {
    const lowercaseOption = option.toLowerCase();
    let noGasWord = lowercaseOption;
    if (lowercaseOption.includes("sweet") || lowercaseOption.includes("sour")) {
      noGasWord = lowercaseOption.replace("gas", "");
    }

    const strippedString = noGasWord
      .replace(/[^\w\s]|_/g, "")
      .replace(/\s+/g, "");
    return strippedString;
  };

  return (
    <div
      className={
        isSummaryInput ? "summaryInputContainer" : "calculatorInputContainer"
      }
    >
      <div className="flex-row-vertical-align">
        <label
          className={isSummaryInput ? "summaryLabel" : "calculatorLabel"}
          htmlFor={label + "ID"}
        >
          {label}
        </label>
        {popoverText ? (
          popoverText[0] ? (
            <PopoverComponent infoText={popoverText} />
          ) : null
        ) : null}
      </div>
      <div className="flex-row-vertical-align">
        <TextField
          inputRef={myTextFieldRef}
          value={value}
          onChange={
            label === "UWI Surface"
              ? (e) => handleUwiChange(e)
              : (e) => handleOnChange(e)
          }
          type={type}
          required={isRequired}
          id={label + "ID"}
          size="small"
          className={
            isSummaryInput === true
              ? "summaryInput"
              : label === "Open-ended lines Hours"
              ? "openEndedLinesInput"
              : "calculatorInput"
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{adornments}</InputAdornment>
            ),
            //If the input is for the summary results in the sections. Use this style.
            ...(isSummaryInput
              ? {
                  style: {
                    width: "70%",
                    borderRadius: 20,
                    backgroundColor: "rgb(134, 133, 135)",
                    marginRight: "10%",
                  },
                }
              : null),
          }}
          disabled={disabled ? disabled : null}
          helperText={inputMinMaxRange ? checkRange(value) : null}
          onBlur={label === "UWI Surface" ? onBlur : null}
        />

        {/* Only create select drop down if there are select options to use. */}
        {selectOptions ? (
          <Select
            className="selectBox"
            size="small"
            value={selectValue}
            defaultValue={selectDefaultOption}
            onChange={handleSelectChange}
          >
            {selectOptions.map(function (option, index) {
              return (
                <MenuItem key={option + index} value={getOption(option)}>
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </MenuItem>
              );
            })}
          </Select>
        ) : null}
      </div>
    </div>
  );
}
