import React, { useState, useEffect } from "react";
import CalculatorSection from "../../../Components/Calculator/CalculatorSection";

// API
import { getWellSubTotal } from "../../../APIs/calculatorAPI";

export default function WellEmission(methaneMolPercentage) {
  const [eventsPerMonth, setEventsPerMonth] = useState("");
  const wellEmissionLabels = ["Events per Month"];
  const wellInfoTexts = [
    "Number of events per month to unload/liquid loaded well to atmosphere is a process in which the fluid (oil, gas, or water) that has accumulated in a wellbore is removed and released into the atmosphere.",
  ];
  // Assumptions
  const [casingDiameter, setCasingDiameter] = useState("22");
  const [wellDepth, setWellDepth] = useState("2000");
  const [kpa, setKpa] = useState("1200");
  const [hoursOpen, setHoursOpen] = useState("0.3");
  const [productionRate, setProductionRate] = useState("10");
  const wellDefaults = [["22", "2000", "1200", "0.3", "10"]];
  const assumptionLabels = [
    "Casing Diameter",
    "Well Depth",
    "kPa",
    "Hours Open",
    "Production Rate",
  ];
  const assumptionInputs = [
    casingDiameter,
    wellDepth,
    kpa,
    hoursOpen,
    productionRate,
  ];
  const assumptionValueSetters = [
    setCasingDiameter,
    setWellDepth,
    setKpa,
    setHoursOpen,
    setProductionRate,
  ];
  const assumptionInfoIcons = [
    [
      "cm",
      "m",
      "g",
      "Hours that the well was left open with natural gas flowing to the atmosphere; periods when the well was blocked or when inert gas (such as nitrogen) dominated the flowback composition should not be included.",
      "Maximum monthly production rateas observed over the last 12 months froom production recods at standard conditions",
    ],
  ];
  const assumptionAdornments = [["cm", "m", "g", "hours/event", "m3/hour"]];

  // Summary Inputs
  const [kgCH4PerMonth, setKgCH4PerMonth] = useState("");
  const [tco2e, setTco2e] = useState("");
  const summaryLabels = ["KgCH4/Month", "tC02e/Year"];

  useEffect(() => {
    getWellSubTotal(
      "well",
      methaneMolPercentage,
      eventsPerMonth,
      casingDiameter,
      wellDepth,
      kpa,
      hoursOpen,
      productionRate,
      function (response) {
        const monthly = response[0];
        const yearly = response[1];
        setKgCH4PerMonth(monthly);
        setTco2e(yearly);
      }
    );
  }, [
    casingDiameter,
    eventsPerMonth,
    hoursOpen,
    kpa,
    methaneMolPercentage,
    productionRate,
    wellDepth,
  ]);

  const wellEmissionInputs = {
    eventsPerMonth,
    casingDiameter,
    wellDepth,
    kpa,
    hoursOpen,
    productionRate,
  };

  return {
    wellEmissionInputs,
    wellEmissionSection: (
      <div>
        <div className="white-font sectionTitle">Well Emissions</div>
        <CalculatorSection
          title="Well Unloading Inputs"
          // Inputs
          inputs={[eventsPerMonth]}
          inputValueSetters={[setEventsPerMonth]}
          labels={wellEmissionLabels}
          infoTexts={wellInfoTexts}
          type="number"
          // Summary Inputs
          summaryTitle="Unloading Emissions"
          summaryInputs={[kgCH4PerMonth, tco2e]}
          summaryLabels={summaryLabels}
          summaryValueSetters={[setKgCH4PerMonth, setTco2e]}
          // Assumption Inputs
          assumptionTitle="Well Unloading Assumptions"
          assumptionInputs={[assumptionInputs]}
          assumptionLabels={[assumptionLabels]}
          assumptionValueSetters={[assumptionValueSetters]}
          assumptionInfoTexts={assumptionInfoIcons}
          assumptionAdorments={assumptionAdornments}
          assumptionDefaults={wellDefaults}
        />
      </div>
    ),
  };
}
