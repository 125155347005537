import React, { useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
//Components
import CaseStudy from "../../../Components/CaseStudy";
import Footer from "../../../Components/Footer/Footer";

import "./caseStudyStyles.css";
export default function PrioritizingInitiatives() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  const initiativeParagraphs = [
    "Montrose Environmental Group is a company specializing in environmental consulting and management services. In an effort to help companies reduce their methane emissions, Montrose conducted an extensive investigation into the data collected from 2,650 Leak Detection and Repair (LDAR) surveys across 6 provinces, in 21 locations. The study focused on the use of Optical Gas Imaging (OGI) for detection and a Hi Flow sampler for gas rate quantification.",
    "The results of the investigation were startling. Montrose detected just under 6,000 leaks, with 490 sources emitting at rates greater than 0.5 ft3/minute. These 490 sources represented 64% of the total emitted volume of the data set. The estimated repair cost for these sources of emissions was $227,000. However, the value of the fugitive natural gas that would be lost if left unchecked over a year was estimated to be $1,343,000.",
    "The study highlights the importance of collecting and analyzing data on fugitive methane emissions. By having management firms accumulate and analyze this data, they can provide valuable recommendations to clients on how to prioritize initiatives to reduce methane emissions. Montrose's investigation demonstrates the need for companies to take action to address their methane emissions, not only from an environmental perspective but also from an economic perspective.",
    "In conclusion, Montrose Environmental Group's investigation provides a compelling case for the value of data collection and analysis in addressing fugitive methane emissions. It underscores the importance of taking action to reduce emissions to not only benefit the environment but also to reduce the economic impact of lost product. Companies that prioritize initiatives to reduce methane emissions can not only reduce their environmental impact but also benefit financially in the long run.",
  ];

  return (
    <div className="caseStudyPage">
      <div className="caseStudyBackground">
        <CaseStudy
          title="Prioritizing Initiatives to Reduce Methane Emissions"
          paragraphs={initiativeParagraphs}
          hasButton={true}
          className="caseStudy"
          buttonText={"Go Back"}
          buttonNavigateTo="/resources"
        />
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
