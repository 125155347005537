import axios from "axios";

/**
 * @description - Uses the two list provided to filter through the companies and return relevant solutions.
 * @param {String Array} primary - List of primary categories that the user is looking for.
 * @param {String Array} secondary - List of secondary categorires that the user is looking for.
 * @param {Function} callback - Callback function that handles the response.
 * @returns An array of objects (companies).
 */
const filterCompanies = function (primary, secondary, callback) {
  axios
    .post("https://api.carbonemissionscanada.com/company/", {
      primary,
      secondary,
    })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) =>
      console.log("Something went wrong with send inputs to database")
    );
};

export { filterCompanies };
