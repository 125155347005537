import React, { useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
//Components
import CaseStudy from "../../../Components/CaseStudy";
import Footer from "../../../Components/Footer/Footer";

import "./caseStudyStyles.css";
export default function Detection() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  const detectionParagraphs = [
    "A large producer in Canada set a goal to achieve net-zero methane emissions by 2030, but monitoring their vast assets presented a challenge. To address this issue, the company recognized the potential of Unmanned Aerial Vehicle (UAV) operations due to their sensor miniaturization, fully electric vehicle, and ability to fly close to the ground. However, the costs associated with these operations were uneconomical until Canadian UAVs (CUAVs) provided demonstrations of their long-range UAV capabilities in October and November 2020.",
    "CUAVs was able to cover over 100 km² of area from a single launch location outside of the operator’s boundaries, reducing the need for operator intervention. The UAVs never entered the site, so no permitting or security check-in was required. The operator received the required data the following day through CUAVs' secure online portal.",
    "With the integration of their ground-based radar systems into various airport locations throughout the oil sands region, CUAVs can now cover a total area of over 3,200 km² and approximately 1.2 million bbls/day of producing assets. CUAVs is a valued addition for methane monitoring that would otherwise require shutting down parts of mining operations for shorter-ranged UAVs. Canadian UAVs model has taken the use of UAVs to the next level for methane monitoring, providing a cost-effective and efficient solution to achieving net-zero methane emissions.",
  ];

  return (
    <div className="caseStudyPage">
      <div className="caseStudyBackground">
        <CaseStudy
          title="Detection, Measurement, Quantification, and Monitoring"
          paragraphs={detectionParagraphs}
          hasButton={true}
          className="caseStudy"
          buttonText={"Go Back"}
          buttonNavigateTo="/resources"
        />
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
