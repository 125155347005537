import React, { useContext, useEffect } from "react";
import NavBar from "../../Components/NavBar/NavBar";

// Components
import { Grid, Typography, Box } from "@mui/material";
import PurpleButton from "../../Components/Buttons/PurpleButton";
import TitleAndPoint from "../../Components/TitleAndPoint";
import CustomCarousel from "../../Components/Carousel/CustomCarousel";
import EmissionGraph from "./EmissionGraph";
import Footer from "../../Components/Footer/Footer";

// Styles
import "./resourcesStyles.css";
import "../Home/homeStyles.css";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

const policyAndRegulationParagraphs = [
  "In adherence to the Paris Agreement, the Canadian government devised a comprehensive strategy aimed at achieving emission targets, fostering economic growth, and enhancing resilience to climate change. A pivotal component of this plan involved enacting legislation to curtail methane emissions in the oil and gas sector by 40-45% by 2025. This target was later revised in November 2021 to seek a more ambitious 75% reduction in oil and gas methane emissions by 2030. The new draft of Federal regulations is anticipated to be released in 2023.",
  "Furthermore, the Canadian government has outlined a progressive carbon tax increase, which is set to rise from $50 CAD per tonne of GHG emissions today to $170 CAD per tonne by 2030. This taxation strategy offers an economic incentive for producers to improve their practices, independent of fluctuations in natural gas prices. Notably, Alberta emerged as the first regional government in North America to commit to a methane emissions reduction target specifically for the oil and gas sector. The province plans to accomplish these objectives through an amalgamation of policy instruments, such as regulatory requirements, market-based programs, and investments in technology and innovation.",
  "Both federal and provincial governments in Canada ensure responsible hydrocarbon resource development by employing scientific study findings, stakeholder engagement, and technological advancements to govern the industry. By collaborating with industry stakeholders, Canadian regulators and governments cultivate a regulatory environment that promotes resource development through intelligent policies and regulations that simultaneously minimize costs and optimize outcomes. Consequently, these policies encourage companies to enhance their environmental, social, and governance (ESG) performance in areas such as land use, water conservation, air quality, well abandonment, land reclamation and rehabilitation, and community and stakeholder engagement and consultation.",
];

const impactsOfProgressParagraphs = [
  "Alberta's oil and gas sector demonstrates a strong commitment to responsible development and impressive ESG performance. Companies actively engage with local communities and stakeholders, ensuring land preservation and reclamation while providing well-remunerated employment prospects. Despite a decrease in capital spending since 2014, the industry continues to invest in technological advancements, resulting in lower operational expenses and improved ESG performance.",
  "Cutting-edge technologies and services aimed at reducing methane emissions offer producers opportunities to expand capital access, recover marketable products, and generate additional revenue via carbon credit programs. These efforts not only bolster profitability but also diminish emissions. Through domestic initiatives, the Canadian strategy contributes significantly to the worldwide goal of mitigating methane emissions.",
  "The combination of regulatory and policy incentives, along with the growing support from investors, innovators, and producers for the demonstration and implementation of methane mitigation technologies, has led to a substantial decrease in methane emissions since 2014. This progress is directed towards achieving the stated goal of a 45% reduction by 2025. The chart below illustrates the performance to date for Alberta.",
  "The plateau observed between 2018 and 2020 can be attributed to a variety of economic factors (such as fluctuating commodity prices), societal impacts (including pandemic disruptions), systemic changes (like the redefinition of certain vent sources in the 2020 AER ST60B report), and general uncertainties within the industry. However, since 2020, there has been a resurgence of funding and enthusiasm directed towards further methane emission reductions. ",
  "It is important to note that the conversion used in the chart is based on 1 tCH4 being equal to 25 tCO2e, with adjustments made for gas stream composition and conversions in combustion equipment. This highlights the ongoing commitment to addressing methane emissions in Alberta and signifies the potential for even more significant progress in the years to come.",
];

export default function ResourcesPage() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);

  return (
    <div className="resourcePage">
      <div className="resourceBackground">
        <div>
          <NavBar />
        </div>

        <div className="resourceContent">
          <Grid container>
            {/*-------First Section ------ */}
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="indent" style={{ marginTop: "5%" }}>
                <Typography variant="h3" className="assetTitle white-font">
                  RESOURCES
                </Typography>
                <div className="indent justifyParagraph">
                  <div className="resourceBody white-font">
                    Did you know that tank vents, while designed to alleviate
                    pressureand vacuum buildup, are a significant source of
                    methane emissions?
                  </div>
                  <div className="resourceBody white-font">
                    PTAC and CanERIC recognize the significance of facilitating
                    emission reduction opportunities.
                  </div>
                  <div className="resourceBody white-font">
                    The platform plays a crucial role in providing access to
                    innovative solutions, essential emission tools, and a
                    company directory.
                  </div>
                  <div className="indent">
                    <TitleAndPoint
                      title="By Identifying What Matters"
                      points={[
                        "Quickly highlight buisness relevance - saving time, cost and eliminating risk.",
                      ]}
                      indent={true}
                    />
                    <TitleAndPoint
                      title="Providing Emission Inight"
                      points={[
                        "Understanding Tank emission is critical for any future regulatory implication.",
                      ]}
                      indent={true}
                    />
                    <TitleAndPoint
                      title="And Industry Attribution"
                      points={[
                        "Data summary enables operators to swiftly pinpoint the source of emissions from tanks or  equipment.",
                      ]}
                      indent={true}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={10} xs={10}>
              <div
                style={{ margin: "10% 0 0 20%" }}
                className="flex-center-column"
              >
                <Box
                  component="img"
                  sx={{
                    p: "1em",
                    opacity: 0.7,
                    width: 400,
                    maxWidth: { sm: 300, md: 400 },
                    maxHeight: { sm: 300, md: 350 },
                  }}
                  alt="PTAC Logo."
                  src="/images/Bricks.png"
                />
                <PurpleButton
                  text="Get Started"
                  onClickHandler={() => navigate("/calculations")}
                />
              </div>
            </Grid>
            {/*-------Second Section / Carousel ------ */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="indent" style={{ marginTop: "5%" }}>
                <Typography variant="h5" className="assetTitle white-font">
                  CASE STUDIES
                </Typography>
              </div>
              <div>
                <CustomCarousel />
              </div>
            </Grid>
            {/*-------Third Section ------ */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div style={{ width: "95%", marginLeft: "5%" }}>
                <TitleAndPoint
                  title="METHANE EMISSION SOURCES"
                  points={[
                    'Upstream oil and gas methane emissions are categorized to facilitate mitigation efforts, while midstream and downstream sources remain outside the scope of this platform. The "ST60B Upstream Petroleum Industry Emissions Report" by the Alberta Energy Regulator presents updated percentages and volumes, reflecting improved accuracy in reporting since January 2020. These figures may vary by region and are expected to decrease as mitigation strategies are adopted.',
                  ]}
                  indent={true}
                />
              </div>
            </Grid>
            {/*-------Forth Section / Graph ------ */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <EmissionGraph />
            </Grid>
            {/*-------Fifth Section ------ */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="indent">
                <TitleAndPoint
                  title="POLICY AND REGULATION"
                  points={policyAndRegulationParagraphs}
                  indent={true}
                />
                <TitleAndPoint
                  title="IMPACTS OF PROGRESS TO DATE IN ALBERTA"
                  points={impactsOfProgressParagraphs}
                  indent={true}
                />
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="flex-center-column">
                <Box
                  component="img"
                  sx={{
                    p: "1em",
                    borderRadius: "30px",
                    width: 500,
                    maxWidth: { xs: 300, sm: 400, md: 500 },
                    maxHeight: { xs: 200, sm: 300, md: 400 },
                  }}
                  alt="PTAC Logo."
                  src="/images/methaneHistory.jpg"
                />
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="indent">
                <TitleAndPoint
                  points={[
                    "Canada has spearheaded the development of pioneering technologies, services, and expertise for the detection, management, and reduction of methane emissions. This progress creates opportunities for exporting these innovations and expertise globally. To summarize, this platform will primarily concentrate on addressing methane emissions, and tackling the global climate change challenge in a cost-effective and efficient manner.",
                  ]}
                />
              </div>
            </Grid>
            {/*-------Footer ------ */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Footer />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
