import React, { useState, useEffect } from "react";
import validator from "validator";
import {
  Button,
  Grid,
  Typography,
  Link,
  IconButton,
} from "@mui/material";

// Utilities
import ReCAPTCHA from "react-google-recaptcha";
import { captchaCheck } from "../../APIs/databaseAPI";

// Components
import TextInput from "../../Components/TextInput";
import SuccessfulRegisterDialog from "./SuccessfulRegisterDialog";
// APIs
import { registerUser, checkEmailIsAvailable } from "../../APIs/databaseAPI";
// Validation
import {
  checkName,
  checkEmail,
  checkCompanyNameAndPosition,
  checkPassword,
} from "./registerValidation";

// Styles
import "./registerStyles.css";

// footer
import "../../Components/Footer/footerStyles.css";
import PolicyDialog from "../../Components/Footer/FooterDialogs/privacyPolicyDialog";
import TermsDialog from "../../Components/Footer/FooterDialogs/termsDialog";

export default function RegisterPage() {
  // Inputs
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // footer dialog handlers
  const [policyOpen, setPolicyOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const handlePolicyDialogOpen = () => (setPolicyOpen(true))
  const handlePolicyDialogClose = () => (setPolicyOpen(false));
  const handleTermsDialogOpen = () => (setTermsOpen(true));
  const handleTermsDialogClose = () => (setTermsOpen(false));

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);

  const nonErrorObject = {
    hasError: false,
    message: "",
  };

  // Error values
  const [emailError, setEmailError] = useState(nonErrorObject);
  const [firstNameError, setFirstNameError] = useState(nonErrorObject);
  const [lastNameError, setLastNameError] = useState(nonErrorObject);
  const [companyNameError, setCompanyNameError] = useState(nonErrorObject);
  const [positionError, setPositionError] = useState(nonErrorObject);
  const [passwordError, setPasswordError] = useState(nonErrorObject);
  const [confirmPasswordError, setConfirmPasswordError] =
    useState(nonErrorObject);

  const [captchaError, setCaptchaError] = useState();

  const onSubmit = () => {
    const passwordRequirement = checkPassword(
      password,
      confirmPassword,
      setPasswordError,
      setConfirmPasswordError
    );
    const firstNameRequirement = checkName(firstName, setFirstNameError);
    const lastNameRequirement = checkName(lastName, setLastNameError);
    const emailRequirement = checkEmail(email, setEmailError);
    const companyRequirement = checkCompanyNameAndPosition(
      companyName,
      setCompanyName,
      setCompanyNameError
    );
    const positionRequirement = checkCompanyNameAndPosition(
      position,
      setPosition,
      setPositionError
    );

    // If email passes regex test and validation.
    // Check if email already exists in database
    if (emailRequirement) {
      checkEmailIsAvailable(email, function (response) {
        if (response.status === 400) {
          setEmailError({
            hasError: true,
            message: response.message,
          });
        } else {
          setEmailError({
            hasError: false,
            message: "",
          });
        }
      });
    }

    // Check all inputs pass validation.
    if (
      firstNameRequirement &&
      lastNameRequirement &&
      !emailError.hasError &&
      companyRequirement &&
      positionRequirement &&
      passwordRequirement
    ) {
      // If all inputs pass validation.
      // Check if captcha was checked.
      if (captchValue) {
        captchaCheck(captchValue, function (response) {
          const captchaSuccess = response;
          if (captchaSuccess) {
            try {
              setCaptchaError("");
              registerUser(
                validator.escape(firstName),
                validator.escape(lastName),
                validator.escape(validator.normalizeEmail(email)),
                validator.escape(companyName),
                validator.escape(position),
                validator.escape(phone),
                password,
                function (response) {
                  // 400 = Email already exists.
                  // 201 = Success
                  // 500 = Uncaught error
                  if (response.status === 400) {
                    setEmailError({
                      hasError: true,
                      message: response.message,
                    });
                  } else {
                    handleClickSuccessRegisterOpen();
                  }
                }
              );
            } catch (error) {}
          } else {
            setCaptchaError("You did not pass the reCaptcha.");
          }
        });
      } else {
        setCaptchaError("Please check the reCAPTCHA to proceed.");
      }
    }
  };

  // Handles the dialog that opens and closes if registration was successful
  const [successRegisterOpen, setSuccessRegisterOpen] = useState(false);

  const handleClickSuccessRegisterOpen = () => {
    setSuccessRegisterOpen(true);
  };

  const handleClickSuccessRegisterClose = () => {
    setSuccessRegisterOpen(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setCompanyName("");
    setPosition("");
    setPhone("");
    setPassword("");
    setConfirmPassword("");
  };
  const [captchValue, setCaptchaValue] = useState();
  const handleCaptchaOnChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <div className="registerPage">
      <div className="registerMain">
        <div className="registerContainer">
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="flex-center  margin-around-2-percent">
                <Typography variant="h4">Registration</Typography>
              </div>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center ">
                <TextInput
                  label="First Name"
                  value={firstName}
                  valueChangeHandler={setFirstName}
                  type="text"
                  isRequired={true}
                  error={firstNameError.hasError}
                  helperText={firstNameError.message}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center">
                <TextInput
                  label="Last Name"
                  value={lastName}
                  valueChangeHandler={setLastName}
                  type="text"
                  isRequired={true}
                  error={lastNameError.hasError}
                  helperText={lastNameError.message}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center">
                <TextInput
                  label="Email"
                  value={email}
                  valueChangeHandler={setEmail}
                  type="email"
                  isRequired={true}
                  error={emailError.hasError}
                  helperText={emailError.message}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center">
                <TextInput
                  label="Company Name"
                  value={companyName}
                  valueChangeHandler={setCompanyName}
                  type="text"
                  isRequired={true}
                  error={companyNameError.hasError}
                  helperText={companyNameError.message}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center">
                <TextInput
                  label="Position"
                  value={position}
                  valueChangeHandler={setPosition}
                  type="text"
                  isRequired={true}
                  error={positionError.hasError}
                  helperText={positionError.message}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center ">
                <TextInput
                  label="Phone (optional)"
                  value={phone}
                  valueChangeHandler={setPhone}
                  type="tel"
                  isRequired={false}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center">
                <TextInput
                  label="Password"
                  value={password}
                  valueChangeHandler={setPassword}
                  type="password"
                  isRequired={true}
                  error={passwordError.hasError}
                  helperText={passwordError.message}
                  popoverText={[
                    "Must include at least 1 for each of the following.",
                    "An uppercase letter",
                    "A lowercase letter",
                    "A digit",
                    "A special character",
                  ]}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center">
                <TextInput
                  label="Re-confirm Password"
                  value={confirmPassword}
                  valueChangeHandler={setConfirmPassword}
                  type="password"
                  isRequired={true}
                  popoverText={["Passwords must match."]}
                  error={confirmPasswordError.hasError}
                  helperText={confirmPasswordError.message}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>
            {/* ----- END OF INPUTS ----- */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="flex-center">
                {ReCAPTCHA ? (
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleCaptchaOnChange}
                    // ref={(ref) => {
                    //     // Store a reference to the ReCaptcha component
                    //     if (ref) {
                    //         ref.reset(); // Reset the ReCaptcha component when it is mounted
                    //     }
                    // }}
                  />
                ) : (
                  <span>Captcha loading..</span>
                )}
              </div>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="flex-center-column errorMessage">
                <Typography variant="caption">{captchaError}</Typography>
              </div>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="flex-center-column">
                <Button variant="contained" onClick={onSubmit}>
                  Create Account
                </Button>

                <div className="textMargin">
                  <Typography>
                    By creating an account, you agree to our Terms & Conditions.
                  </Typography>
                </div>

                <div className="margin-around-2-percent">
                  <Typography>
                    Already have an Account?
                    <Link href="/"> Login here!</Link>
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          {successRegisterOpen ? (
            <SuccessfulRegisterDialog
              open={successRegisterOpen}
              handleOpen={handleClickSuccessRegisterOpen}
              handleClose={handleClickSuccessRegisterClose}
            />
          ) : null}
        </div>
        {/* footer */}
        <div className="footerFlexBox white-font">
          <div
            onClick={handlePolicyDialogOpen}
            className="indent footerText"
            style={{ cursor: "pointer" }}
          >
            Privacy Policy
          </div>
          <PolicyDialog
            policyOpen={policyOpen}
            handlePolicyDialogOpen={handlePolicyDialogOpen}
            handlePolicyDialogClose={handlePolicyDialogClose}
          />
          <div
            onClick={handleTermsDialogOpen}
            className="indent footerText"
            style={{ cursor: "pointer" }}
          >
            Terms & Conditions
          </div>
          <TermsDialog
            termsOpen={termsOpen}
            handleTermsDialogOpen={handleTermsDialogOpen}
            handleTermsDialogClose={handleTermsDialogClose}
          />
          <div className="footerCompanyName footerText white-font">
            <div>Powered by</div>
            <IconButton
              className="breakWaterIconButton"
              // onClick={handleBWLogoClick}
            >
              <img
                className="footerBWVantageLogo"
                src="images\BreakwaterVantageLogo .png"
                alt="BWVantage Logo"
              />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}
