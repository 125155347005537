import React, { useState } from "react";

// Components
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import AvatarComponent from "../AvatarComponent";

const pages = [
  ["Why tank venting model?", "/home"],
  ["Resources", "/resources"],
  ["Calculations", "/calculations"],
  ["tank emission solutions/directory", "/solutions"],
  ["PDF Handbook", "/handbook"],
  ["Glossary", "/glossary"],
];

function NavBar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);

  // Opens Nav menu when in a smaller display.
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  // Close Nav menu when in a smaller display.
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // Returns true/false depending on if the current href location equals the button in the nav.
  const getCurrentLocation = (page) => {
    const splitURL = window.location.href.split("/");
    const thisLocation = splitURL[splitURL.length - 1];
    const thisPage = page.substring(1);
    if (thisLocation === thisPage) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <AppBar position="static" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
      <Container maxWidth="x2">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "none", lg: "flex" },
              letterSpacing: ".3rem",
              color: "inherit",
            }}
          >
            <Box
              component="img"
              sx={{
                p: "1em",
                width: 200,
                maxWidth: { xs: 250, md: 250 },
                maxHeight: { xs: 250, md: 100 },
              }}
              alt="PTAC Logo."
              src="/images/ptac-logo.png"
            />
          </Typography>

          <Box
            sx={{
              flexGrow: 0.5,
              display: { xs: "flex", md: "flex", lg: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            {/* Hamburger */}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "block", lg: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem onClick={() => navigate(page[1])} key={page[1]}>
                  <Typography
                    sx={{ textTransform: "uppercase" }}
                    textAlign="center"
                  >
                    {page[0]}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            component="a"
            sx={{
              mr: 2,
              letterSpacing: ".3rem",
              color: "inherit",
              marginRight: "10%",
              flexGrow: 0.5,
              display: { xs: "flex", md: "flex", lg: "none" },
            }}
          >
            <Box
              component="img"
              sx={{
                flexGrow: 1,

                p: "1em",
                width: 100,
                maxWidth: { xs: 110, md: 150 },
                maxHeight: { xs: 100, md: 140 },
              }}
              alt="PTAC Logo."
              src="/images/ptac-logo.png"
            />
          </Typography>

          {/* Full nav bar */}
          <Box
            sx={{
              flexGrow: 2,
              display: { xs: "none", md: "none", lg: "flex" },
            }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() =>
                  !getCurrentLocation(page[1]) ? navigate(page[1]) : null
                }
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  fontSize: "0.9em",
                  opacity: "1",
                  textTransform: "uppercase",
                  width: "100%",
                }}
              >
                <p className={getCurrentLocation(page[1]) ? "glow" : null}>
                  {page[0]}
                </p>
              </Button>
            ))}
          </Box>
          <Typography
            variant="h6"
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "none", lg: "flex" },
              letterSpacing: ".3rem",
              color: "inherit",
            }}
          >
            <Box
              component="img"
              sx={{
                p: "1em",
                width: 200,
                maxWidth: { xs: 250, md: 250 },
                maxHeight: { xs: 250, md: 100 },
              }}
              alt="cerin Logo."
              src="/images/cerinLogo_nobg.png"
            />
          </Typography>
          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "flex", md: "flex" },
            }}
          >
            <AvatarComponent />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
