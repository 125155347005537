import React, { useState, useEffect } from "react";

import CalculatorSection from "../../../Components/Calculator/CalculatorSection";
import { getGlycolSubTotal } from "../../../APIs/calculatorAPI";

export default function GlycolDehydrators(methaneMolPercentage) {
  // Inputs
  const [dehydrators, setDehydrators] = useState("");
  const dehydratorLabels = ["Dehydrators"];
  const glycolInfoTexts = [
    "A glycol dehydrator is a piece of equipment used to remove water vapor from natural gas.",
  ];

  // Assumptions
  const [wellAllocation, setWellAllocation] = useState("2");
  const glycolSelectOptions = ["High", "Low"];
  const [select, setSelect] = useState(glycolSelectOptions[1]);
  const glycolAssumptionLabels = ["Well Allocation %", "Select"];
  const glycolAssumptionInputs = [wellAllocation, select];
  const glycolAssumptionSetters = [setWellAllocation, setSelect];
  const glycolAssumptionSelectOptions = [glycolSelectOptions];
  const glycolAssumptionInfoTexts = [
    [
      "Allocation Percentage (%) corresponding to a particular Unique Well Identifier (UWI).",
    ],
  ];
  const glycolDefaults = [["2", "Low"]];

  // Summary Inputs
  const [kgCH4PerMonth, setKgCH4PerMonth] = useState("");
  const [tco2e, setTco2e] = useState("");
  const summaryLabels = ["KgCH4/Month", "tC02e/Month"];

  useEffect(() => {
    getGlycolSubTotal(
      "glycol",
      methaneMolPercentage,
      dehydrators,
      wellAllocation,
      select,
      function (response) {
        const monthly = response[0];
        const yearly = response[1];
        setKgCH4PerMonth(monthly);
        setTco2e(yearly);
      }
    );
  }, [dehydrators, methaneMolPercentage, select, wellAllocation]);

  const glycolDehydratorInputs = { dehydrators, wellAllocation, select };

  return {
    glycolDehydratorInputs,
    glycolDehydratorSection: (
      <div>
        <div className="white-font sectionTitle">Glycol Dehydrator</div>
        <CalculatorSection
          // Inputs
          title="Glycol Dehydrators Inputs"
          inputs={[dehydrators]}
          inputValueSetters={[setDehydrators]}
          labels={dehydratorLabels}
          infoTexts={glycolInfoTexts}
          type="number"
          // Summary Inputs
          summaryTitle="Glycol Dehydrator Emissions"
          summaryInputs={[kgCH4PerMonth, tco2e]}
          summaryLabels={summaryLabels}
          summaryValueSetters={[setKgCH4PerMonth, setTco2e]}
          // Assumption Inputs
          assumptionTitle="Glycol Dehydrators Assumptions"
          assumptionInputs={[glycolAssumptionInputs]}
          assumptionValueSetters={[glycolAssumptionSetters]}
          assumptionLabels={[glycolAssumptionLabels]}
          assumptionIsolateSelectOptions={glycolAssumptionSelectOptions}
          assumptionInfoTexts={glycolAssumptionInfoTexts}
          assumptionDefaults={glycolDefaults}
        />
      </div>
    ),
  };
}
