import React, { useContext, useEffect } from "react";

// Components
import PurpleButton from "../../Components/Buttons/PurpleButton";
import DownloadButton from "../../Components/Buttons/DownloadButton";
import InfoCard from "../../Components/InfoCard";
import CapabilityBody from "./CapabilityBody";
import Footer from "../../Components/Footer/Footer";
import NavBar from "../../Components/NavBar/NavBar";
import { Typography, Grid, Box, IconButton } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
// Styles
import "./homeStyles.css";

export default function HomePage() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);
  return (
    <div className="homePage">
      <div className="homeBackground">
        <div>
          <NavBar />
        </div>
        <div className="homeContent">
          <Grid container>
            {/*-------First Section ------ */}
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="assetSection indentSection">
                <div className="assetTitle white-font">SOURCE OF EMISSIONS</div>
                <div className="assetBody white-font justifyParagraph">
                  Improving the insight into storage vessel assets is essential
                  for unlocking the potential to decrease greenhouse gas
                  emissions in the oil and gas sector, particularly concerning
                  methane emissions. Guarantee your organization's sustained
                  leadership in innovation by proficiently monitoring and
                  mitigating emissions from containment facilities in the
                  ever-changing landscape.
                </div>
                <div className="assetButton" style={{ width: "15%" }}>
                  <PurpleButton
                    text="Get Started"
                    onClickHandler={() => navigate("/calculations")}
                  />
                </div>
                <button
                  className="flex-center cerinButton"
                  onClick={() => window.location = 'https://www.cerinprojects.ca/reports/64e9124b34f8adedd1f4db64'}
                >
                  <p className="signUpText">CERIN Report</p>
                </button>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="capabilitySection indentSection">
                <div className="capabilityTitle  white-font">OVERVIEW</div>

                <div>
                  <CapabilityBody />
                </div>
              </div>
            </Grid>
            {/*-------Second Section / header and image ------ */}
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="indentSection">
                <div className="white-font secondSectionHeader">
                  ACHIEVE ESSENTIAL UNDERSTANDING TO MITIGATE OR MINIMIZE
                  EMISSIONS FROM STORAGE VESSELS
                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="indentSection metroImage">
                <Box
                  component="img"
                  sx={{
                    p: "1em",
                    borderRadius: "30px",

                    maxWidth: { xs: 250, sm: 350, md: 350 },
                    maxHeight: { xs: 150, sm: 200, md: 250 },
                  }}
                  alt="PTAC Logo."
                  src="/images/MetropolisBackground.jpg"
                />
              </div>
            </Grid>

            {/*-------Third Section / Info Cards ------ */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="entireInfoCardWrapper">
                <div className="infoCardContainer indentSection ">
                  <InfoCard
                    title="Gain Context"
                    bodyText="Maximize your operational efficiency by identifying sources of emissions, increasing transparency in your operational framework, and enhancing your emissions management approach."
                    mainImage={"Tank.jpg"}
                    backgroundImage={"Rectangle1.jpg"}
                  />
                </div>

                <div className="infoCardContainer indentSection">
                  <InfoCard
                    title="Technology and Address Blind spots"
                    bodyText="Refine your understanding of storage vessel emissions by gaining an in-depth awareness of asset conditions and keep up-to-date with the latest advancements. Invest in technology to maintain a competitive edge and implement emission reduction solutions at the well or asset/field level."
                    mainImage={"RectangleforTank.jpg"}
                    backgroundImage={"Rectangle2.jpg"}
                  />
                </div>

                <div className="infoCardContainer indentSection">
                  <InfoCard
                    title="Eliminate/Reduce Tank Emissions"
                    bodyText="Unleash the full potential of tank emission solutions by obtaining crucial insights, ensuring the continuous optimization of your operations."
                    mainImage={"Dashboard.png"}
                    backgroundImage={"Rectangle3.jpg"}
                  />
                </div>
              </div>
            </Grid>

            {/*-------Forth Section / header + resource button + image ------ */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="white-font helpIndustryHeader">
                <p className="helpIndustryText">
                  The platform will support in developing a deeper understanding
                  of storage vessel venting emissions occurring throughout
                  operational processes.
                </p>
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={7} md={7} sm={12} xs={12}>
                  <div className="homeResouceSection indentSection">
                    <div className="white-font helpIndustryPoints">
                      <p className="white-font indent justifyParagraph">
                        Access to information related to storage vessel and
                        emissions.
                      </p>
                      <p className="white-font indent justifyParagraph">
                        Delivers insight and knowledge about technology and
                        corresponding emissions to enhance comprehension.
                      </p>
                      <p className="white-font indent justifyParagraph">
                        Supports the measurement, and risk mitigation by
                        providing emission reduction technologies and solutions.
                      </p>
                      <div>
                        <Typography className="indent" variant="h4">
                          <div
                            style={{ marginTop: "20%" }}
                            className="flex-align-base"
                          >
                            <span className="resourceTool">
                              RESOURCE TOOLS{" "}
                            </span>
                            <div>
                              <button
                                onClick={() => navigate("/resources")}
                                className="play-button"
                              >
                                <span className="play-icon"></span>
                              </button>
                            </div>
                          </div>
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div className="indentSection sphereImage">
                    <Box
                      component="img"
                      sx={{
                        p: "1em",

                        maxWidth: { xs: 0, sm: 0, md: 400 },
                        maxHeight: { xs: 0, sm: 0, md: 800 },
                        opacity: 0.6,
                      }}
                      alt="PTAC Logo."
                      src="/images/Globe.png"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {/*-------Fifth Section / wide purple image + getStarted button ------ */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="homeTankEmissionsSection">
                <div className="white-font padding-top-10 widePurpleImageText">
                  Bring complete visibility to your Tank Emissions
                </div>

                <div style={{ margin: "5% 0 5% 0" }}>
                  <PurpleButton
                    text="Get Started"
                    onClickHandler={() => navigate("/calculations")}
                  />
                </div>
              </div>
            </Grid>
            {/*------- Final Section / download buttons ------ */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="mobileButtonGroup">
                <DownloadButton text="Download IOS" />
                <DownloadButton
                  navigationLink={
                    "https://play.google.com/store/apps/details?id=com.bwvantage.ecoefficiency.eco_efficiency&authuser=0"
                  }
                  text="Download Android"
                />
              </div>
            </Grid>
            {/*-------Footer ------ */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Footer />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
