import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Components
import NavBar from "../../Components/NavBar/NavBar";
import DeleteAccountDialog from "./DeleteAccountDialog";
import Footer from "../../Components/Footer/Footer";
// Styles
import "./settingStyles.css";
// API
import { getCurrentUser } from "../../APIs/databaseAPI";
import AuthContext from "../../context/AuthContext";
export default function SettingsPage() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [user, setUser] = useState("");

  useEffect(() => {
    getCurrentUser(function (response) {
      setUser(response);
    });
  }, []);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="settingsPage">
      <div className="settingsBackground">
        <div>
          <NavBar />
        </div>
        <div className="accordianWrapper">
          <div>
            <Accordion
              className="accordian"
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Account Information
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Name, email, company, position, and phone number.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table>
                  <TableRow>
                    <TableCell>First Name: </TableCell>
                    <TableCell align="left">{user.firstName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Last Name: </TableCell>
                    <TableCell align="left">{user.lastName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Email: </TableCell>
                    <TableCell align="left">{user.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Company: </TableCell>
                    <TableCell align="left">{user.companyName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Position: </TableCell>
                    <TableCell align="left">{user.position}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Phone: </TableCell>
                    <TableCell align="left">{user.phone}</TableCell>
                  </TableRow>
                </Table>
              </AccordionDetails>
            </Accordion>

            {/* Deactivate Account */}
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Delete Account
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Permanently delete your account.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex-align-horizontal-vertical">
                  <Button
                    onClick={handleClickOpen}
                    style={{ width: "100%" }}
                    color="error"
                    variant="contained"
                  >
                    DELETE ACCOUNT
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <DeleteAccountDialog
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
          />
        </div>
        <div className="settingFooter">
          <Footer />
        </div>
      </div>
    </div>
  );
}
