import React from 'react'
import { Select, MenuItem } from '@mui/material'

export default function SelectBox({ selectValue, selectDefaultOption, handleSelectChange, selectOptions, label }) {
    return (
        <div className="calculatorInputContainer">
            <div>
                <label className="calculatorLabel">{label}</label>
            </div>

            <Select
                className='selectBoxIsolated'
                size='small'
                value={selectValue}
                defaultValue={selectDefaultOption}
                onChange={handleSelectChange}
            >
                {selectOptions.map(function (option, index) {
                    return <MenuItem
                        key={option + index}
                        value={option}>{option.charAt(0).toUpperCase() + option.slice(1)}</MenuItem>
                })}

            </Select>
        </div>

    )
}
