import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Components
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import AuthContext from "../../context/AuthContext";

// Styles
import "./flippingBookStyles.css";

export default function FlippingBookPage() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);
  const params = useParams();
  // renders flipbook
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=159260233";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div className="flippingBookPage">
      {/* <button onClick={() => {parapms}}>test</button> */}
      <div className="flippingBookBackground">
        <div>
          <NavBar />
        </div>

        <div className="flipbook">
          <a
            href={
              "https://online.flippingbook.com/view/159260233/" +
              params.pageNumber
            }
            className="fbo-embed"
            data-fbo-id="8df66fd0b2"
            data-fbo-ratio="3:2"
            data-fbo-lightbox="yes"
            data-fbo-width="100%"
            data-fbo-height="auto"
            data-fbo-version="1"
            style={{ maxWidth: "100%" }}
          >
            Canadian Capabilities in Methane Emissions Reduction
          </a>
        </div>
        <div className="flippingPageFooter">
          <Footer />
        </div>
      </div>
    </div>
  );
}
