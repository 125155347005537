import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./equationStyles.css";
import PurpleButton from "../../Components/Buttons/PurpleButton";
import Footer from "../../Components/Footer/Footer";
import AuthContext from "../../context/AuthContext";

export default function EquationFrameworkPage() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);
  return (
    <div className="equationsPage">
      <div className="equationsBackground">
        <div className="indent">
          <h1 className="white-font caseTitle">Equations Framework</h1>

          <div className="white-font caseBody">
            Overview of the multiple emission calculations used specific for the
            energy industry, and geographical locations. By analyzing the
            diverse range of emissions data, we aim to provide valuable insights
            into the trends, patterns, and key factors driving these emissions.
            This comprehensive understanding will aid in the development of
            targeted and effective strategies for reducing greenhouse gas
            emissions and mitigating the impacts of climate change.
          </div>

          {/* Equations */}
          <div className="equations">
            <div className="white-font equationContianer">
              <p className="equationTitle">General Emissions:</p>
              <p className="equation">M = V x 𝜙CH4 x ρCH4</p>
              <p className="equation">M = V x 𝜔CH4 x ρgas</p>
            </div>
            <div className="white-font equationContianer">
              <p className="equationTitle">
                Tank Emissions - GIS factor “rule of thumb” from Directive 017:
              </p>
              <p className="equation">VGIS = Vliq x GIS</p>
              <p className="equation">
                Tank Breathing and Working Losses is excluded
              </p>
            </div>
            <div className="white-font equationContianer">
              <p className="equationTitle">
                Hydrocarbon Liquid Loading Losses:
              </p>
              <p className="equation">
                VLL = (SF x Pv x Tstd) / (Pstd x Tt) X VOIL
              </p>
            </div>
            <div className="white-font equationContianer">
              <p className="equationTitle">Solid Desiccant Dehydrators:</p>
              <p className="equation">
                VDD = (H x D^2 x Pi x P2 x G)/(4 X P1) X f
              </p>
            </div>
            <div className="white-font equationContianer">
              <p className="equationTitle">Pig Trap Openings and Purges:</p>
              <p className="equation">
                VPT = Vv [ (273.15 + Ts)(Pa,1 - Pa,2)/(273.15+Ta)Ps +M]
              </p>
            </div>
            <div className="white-font equationContianer">
              <p className="equationTitle">
                Well Venting for Liquids Unloading:
              </p>
              <p className="equation">
                VWLU = [(7.854 x 10^-5 x Dt^2 x WD x [Pshut-in/101.325]) + (Qsfr
                x topen)]
              </p>
            </div>
            <div className="white-font equationContianer">
              <p className="equationTitle">Engine or Turbine Starts</p>
              <p className="equation">𝑉=𝐸R × 𝑡</p>
            </div>
            <div className="white-font equationContianer">
              <p className="equationTitle">Pneumatic Instruments</p>
              <p className="equation">Pneumatic Instruments 𝐸R𝑖 =𝑚×𝑆P</p>
            </div>
            <div className="white-font equationContianer">
              <p className="equationTitle">Pneumatic Pump</p>
              <p className="equation">ERp = (g x SP) + (n x DP) + (p x SPM)</p>
            </div>
            <div className="white-font equationContianer">
              <p className="equationTitle">Fugitive Emissions</p>
              <p className="equation">VFE-S = VL + VAP</p>
            </div>
          </div>
        </div>
        <div className="flex-align-horizontal-vertical">
          <PurpleButton
            text={"Go Back"}
            onClickHandler={() => navigate("/glossary")}
          />
        </div>

        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
