const definitions = [
  "Absolute emissions: Total amount of greenhouse gas emissions released over a specific period",
  "Baseline emissions: Reference point for comparing emission levels over time",
  "Bioenergy with carbon capture and storage (BECCS): A technology that combines the use of bioenergy (such as burning wood or crops) with carbon capture and storage to produce negative emissions",
  "Biogenic emissions: Emissions produced from biological processes (e.g., plant decay)",
  "Cap-and-trade: System where emission allowances are traded to control total emissions",
  "Carbon budget: The total amount of greenhouse gases that can be emitted while still remaining within a certain temperature threshold, usually expressed in gigatons of carbon dioxide equivalent",
  "Carbon capture and storage (CCS): A process that captures carbon dioxide emissions from industrial sources, such as power plants, and stores them underground or in other long-term storage solutions",
  "Carbon capture, utilization, and storage (CCUS): A technology that captures carbon dioxide emissions from industrial processes or power generation and either stores it underground or uses it for other purposes",
  "Carbon credit: Tradable certificate representing the reduction or removal of one metric ton of carbon dioxide",
  "Carbon dioxide (CO2): A colorless gas produced during the combustion of fossil fuels, cement production, and other industrial processes. It is the primary greenhouse gas emitted by human activities",
  "Carbon dioxide removal (CDR): The process of removing carbon dioxide from the atmosphere, either naturally or through human intervention",
  "Carbon farming: The practice of using agricultural techniques to sequester carbon in soil or plants, such as through no-till farming, cover cropping, and agroforestry",
  "Carbon footprint: Total amount of greenhouse gases emitted directly or indirectly by an individual, organization, or product",
  "Carbon intensity: Emissions produced per unit of energy or economic output",
  "Carbon labeling: The practice of including information about the carbon footprint of a product on its label or packaging",
  "Carbon leakage: The phenomenon of greenhouse gas emissions being transferred from one country or sector to another as a result of emissions reduction policies",
  "Carbon neutral: A state in which the amount of greenhouse gas emissions produced is balanced by the amount removed from the atmosphere",
  "Carbon offset: A reduction in greenhouse gas emissions made to compensate for emissions produced elsewhere, typically achieved by funding projects that reduce or remove emissions - Tradable credit representing the reduction of greenhouse gas emissions elsewhere",
  "Carbon pricing: A policy instrument that puts a price on carbon emissions in order to incentivize emissions reductions and promote the transition to a low-carbon economy - Method of charging emitters for each ton of carbon dioxide released and/or the use of taxes or other pricing mechanisms to encourage the reduction of greenhouse gas emissions",
  "Carbon sequestration: The process of capturing and storing carbon dioxide emissions, usually through natural processes such as reforestation or using artificial technologies such as carbon capture and storage",
  "Carbon sequestration: The process of capturing and storing carbon dioxide, usually underground, to prevent its release into the atmosphere",
  "Carbon sink: A natural or artificial reservoir that absorbs more carbon dioxide than it emits",
  "Carbon tax: A type of carbon pricing policy that places a tax on the carbon content of fossil fuels - Fee imposed on emissions per metric ton of carbon dioxide equivalent (CO2e)",
  "Clean Development Mechanism (CDM): A United Nations framework for reducing greenhouse gas emissions in developing countries",
  "Clean energy: Energy sources that produce little to no greenhouse gas emissions, such as renewable energy and nuclear power",
  "Climate action plan: A strategic plan developed by governments, businesses, or organizations to identify and implement actions to reduce greenhouse gas emissions and address climate change",
  "Climate adaptation: Actions taken to adapt to the impacts of climate change, such as rising sea levels and more extreme weather events",
  "Climate change adaptation plan: A strategic plan developed by governments, businesses, or organizations to identify and implement actions to adapt to the impacts of climate change, such as sea level rise, extreme weather events, and changes in precipitation patterns",
  "Climate change adaptation: Actions taken to reduce the impacts of climate change on natural and human systems",
  "Climate change mitigation: Actions taken to reduce greenhouse gas emissions and prevent or reduce the severity of climate change",
  "Climate change: Long-term changes in Earth's temperature and weather patterns due to human activities",
  "Climate feedback: A process in which the impacts of climate change, such as melting ice sheets, can further amplify global warming by releasing more greenhouse gases or altering the Earth's reflectivity",
  "Climate finance: Financial investments and flows that support climate change mitigation and adaptation efforts - provided by governments, businesses, or organizations to support climate mitigation and adaptation efforts, such as funding for renewable energy projects or climate resilience initiatives",
  "Climate forcing: The imbalance between incoming and outgoing radiation in the Earth's atmosphere, caused by greenhouse gases and other factors",
  "Climate inertia: The tendency of the climate system to continue changing even after greenhouse gas emissions are reduced or stabilized, due to the slow response time of the oceans and other factors",
  "Climate justice: The concept that those who are most affected by climate change, such as marginalized communities and future generations, should have a voice in climate policy and decision-making",
  "Climate mitigation: Actions taken to reduce greenhouse gas emissions and prevent further global warming",
  "Climate modeling: The use of computer simulations to predict future climate scenarios based on factors such as greenhouse gas emissions, atmospheric conditions, and land use changes",
  "Climate policy: Policies and regulations implemented by governments to address climate change, such as renewable energy mandates or emissions standards for vehicles",
  "Climate resilience: The ability of natural and human systems to adapt to and recover from the impacts of climate change",
  "Climate scenario: A description of possible future climate conditions based on different assumptions about greenhouse gas emissions and other factors",
  "Climate vulnerability: The degree to which a natural or human system is susceptible to harm from climate change, based on factors such as exposure, sensitivity, and adaptive capacity",
  "CO2e: Carbon dioxide equivalent, a standard unit for comparing emissions of different greenhouse gases",
  "Decarbonization: The process of transitioning to a low-carbon economy and reducing reliance on fossil fuels",
  "Direct emissions: Greenhouse gas emissions released from sources owned or controlled by an entity (Scope 1)",
  "Emission factor: Value representing emissions per unit of activity or fuel consumption",
  "Emission inventory: Comprehensive list of greenhouse gas emissions from a specific source or sector",
  "Emission reduction: Decrease in greenhouse gas emissions compared to a baseline level",
  "Emission trading system (ETS): Market-based approach for regulating emissions by allowing participants to buy, sell, or trade allowances",
  "Emissions intensity: The amount of greenhouse gas emissions produced per unit of economic activity or product",
  "Emissions trading: A type of carbon pricing policy that creates a market for emissions reductions or allowances that can be bought and sold, allowing companies to trade emissions permits and incentivizing emissions reductions",
  "Energy efficiency: Reduction of energy consumption while achieving the same output or service",
  "Fluorinated gases (F-gases): A group of greenhouse gases used in a variety of industrial applications, such as refrigeration and air conditioning. They have a high global warming potential and can persist in the atmosphere for thousands of years",
  "Fossil fuel subsidies: Government subsidies or tax breaks that support the production and consumption of fossil fuels",
  "Fugitive emissions: Unintended emissions released during production, processing, or transportation of fossil fuels",
  "Global warming potential (GWP): A measure of the relative warming effect of a greenhouse gas compared to carbon dioxide over a specific time period, usually 100 years",
  "Global warming potential (GWP): Measurement of how much heat a greenhouse gas traps in the atmosphere compared to carbon dioxide",
  "Green economy: An economy that aims to reduce its environmental impact, create jobs in sustainable industries, and promote social and economic equity",
  "Green jobs: Jobs in industries that promote environmental sustainability, such as renewable energy and energy efficiency",
  "Greenhouse effect: Warming of Earth's surface due to the trapping of heat by greenhouse gases",
  "Greenhouse gas (GHG): A gas that absorbs and emits radiation in the Earth's atmosphere, contributing to the greenhouse effect and global warming",
  "Greenhouse gas inventory: A detailed accounting of greenhouse gas emissions produced by a specific entity, such as a company or government",
  "Greenhouse gas inventory: A report that quantifies an entity's greenhouse gas emissions, usually broken down by source and sector",
  "Greenhouse gases (GHGs): Gases that trap heat in the atmosphere, including carbon dioxide, methane, and nitrous oxide",
  "Hydrofluorocarbons (HFCs): Synthetic greenhouse gases used in refrigeration and air conditioning",
  "Indirect emissions: Emissions that occur as a result of an entity's activities but are not released from sources owned or controlled by the entity (Scopes 2 and 3)",
  "Intergovernmental Panel on Climate Change (IPCC): A scientific body established by the United Nations to provide assessments of the science of climate change and its potential impacts",
  "Joint implementation (JI): A mechanism similar to the CDM, but between developed countries",
  "Kyoto Protocol: International treaty committing countries to reduce greenhouse gas emissions",
  "Life cycle assessment (LCA): A methodology used to assess the environmental impact of a product or service over its entire life cycle, including its production, use, and disposal",
  "Low-carbon fuel standard: A regulation that requires transportation fuel suppliers to reduce the carbon intensity of their products",
  "Methane (CH4): A colorless, odorless gas that is emitted from natural and human sources, including livestock, landfills, and fossil fuel production. It is a potent greenhouse gas, with a much higher global warming potential than carbon dioxide",
  "Mitigation potential: The maximum amount of greenhouse gas emissions that could be reduced or prevented through the implementation of a particular mitigation measure or technology",
  "National Inventory Report (NIR): A report submitted by countries to the United Nations Framework Convention on Climate Change that details their greenhouse gas emissions and removals",
  "Nationally determined contributions (NDCs): The greenhouse gas emissions reduction targets and plans submitted by countries under the Paris Agreement",
  "Net-zero emissions: A state in which an entity's greenhouse gas emissions are balanced out by an equivalent amount of emissions reductions or removals, usually achieved through a combination of emissions reductions and carbon offsets",
  "Nitrous oxide (N2O): A greenhouse gas emitted by natural and human sources, including agriculture and industrial processes. It has a higher global warming potential than carbon dioxide",
  "Paris Agreement: An international treaty under the United Nations Framework Convention on Climate Change that aims to limit global warming to well below 2°C above pre-industrial levels, and pursue efforts to limit it to 1.5°C",
  "Reducing emissions from deforestation and forest degradation (REDD+): A mechanism that provides incentives to countries to reduce greenhouse gas emissions from deforestation and forest degradation, while also promoting sustainable forest management",
  "Renewable energy: Energy generated from sources that can be replenished, such as wind, solar, hydro, and geothermal power",
  "Renewable portfolio standard - A policy tool that requires utilities or other entities to generate a certain percentage of their energy from renewable sources, such as wind or solar",
  "Resilience: The capacity of a system, community, or society to adapt, recover, and thrive in the face of adverse events, such as climate change impacts or other shocks and stresses",
  "Scope 1 emissions: Direct emissions from sources owned or controlled by a reporting entity, such as fuel combustion in boilers, vehicles, or industrial processes or resource recovery",
  "Scope 2 emissions: Indirect emissions resulting from the generation of purchased electricity, steam, or other forms of energy consumed by a reporting entity",
  "Scope 3 emissions: Indirect emissions that occur in a reporting entity's value chain, including both upstream and downstream activities, such as transportation, waste disposal, and employee commuting",
  "Sustainable development: Development that meets the needs of the present without compromising the ability of future generations to meet their own needs, typically involving the simultaneous pursuit of economic, environmental, and social goals",
];

export { definitions };
