import React from "react";

// Text blurbs
import { capabilitySection } from "./HomeTextBlurbs";

// Styles
import "./homeStyles.css";

/**
 *
 * @returns A component that loops and creates paragraphs. Mainly for the scrollable text in the home page.
 */
export default function CapabilityBody() {
  return (
    <div className="capabilityContainer">
      <div className="capabilityBody justifyParagraph">
        {capabilitySection
          ? capabilitySection.map(function (paragraph, index) {
              return (
                <p className="white-font" key={"capabilityParagraph" + index}>
                  {paragraph}
                </p>
              );
            })
          : null}
      </div>
    </div>
  );
}
