import React from "react";

// Styles
import "./buttonStyles.css";

/**
 *
 * @param {String} text - The text that goes inside the button. Ex. "Download IOS"
 * @returns A purple button variant that is bigger than ususal.
 */

export default function DownloadButton({ text, navigationLink }) {
  function handleNav() {
    window.open(navigationLink, "_blank");
  }

  return (
    <button
      onClick={navigationLink ? handleNav : null}
      className="purple-button-box-shadow downloadSize"
    >
      <span className="purpleButtonText">{text}</span>
    </button>
  );
}
