import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "./carouselStyles.css";

/**
 *
 * @param {String} image - The file of the image. Should be in the case-study folder.
 * @param {String} caption - The caption for the image.
 * @param {String} navigateTo - The final route of where the image onClick should navigate to. Ex. "combustion"
 * @returns A clickable image with a caption that navigates to a case study page.
 */
export default function CarouselItem({ image, caption, navigateTo }) {
  const navigate = useNavigate();
  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => navigate("case-study/" + navigateTo)}
    >
      <Box
        component="img"
        sx={{
          p: "1em",
          width: 400,
          height: 250,
          maxWidth: { xs: 350, md: 350, lg: 400 },
          maxHeight: { xs: 150, md: 250 },
          borderRadius: "30px",
        }}
        alt="PTAC Logo."
        src={"/images/caseStudies/" + image}
      />
      <div className="white-font indent">
        <div className="indent">
          <p className="carouselCaption" style={{ textTransform: "uppercase" }}>
            {caption}
          </p>
        </div>
      </div>
    </div>
  );
}
