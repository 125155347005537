import axios from "axios";
const getTankEmissionSolutions = function (tankSolutions, callback) {
    /*Return the axios call so that we can chain .then() to the function outside of the api.
    Example:
                setIsLoading(true); // set loading state to true
                getTankEmissionSolutions(tankEmissionSelections,function(response){
                setResultSolutions(response)
                .then(() => {
                setIsLoading(false); // set loading state back to false when download is complete
            });
        })
        */
 return  axios
    .post(
      "https://api.carbonemissionscanada.com/solutions/",
      { tankSolutions },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "technologySolutions.pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => console.log(error));
};

export { getTankEmissionSolutions };
