import React, { useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

import "./versionHistoryStyles.css";

// Components
import Footer from "../../Components/Footer/Footer";
import PurpleButton from "../../Components/Buttons/PurpleButton";

// Verion Paragraphs
const versionParagraphs = [
  "Version 1.0 (Oct. 18, 2024)",
  "We are pleased to announce the release of Version 1.0 of our Emission Calculation Page. This update aims to provide users with more standardized, accurate, and user-friendly tools and resources for estimating emissions based on AER (Alberta Energy Regulator) requirements.",
  "Key updates and changes in Version 1.0 include:",
  "Standardized emission calculations: We have revamped our emission calculation tools to align with the most up-to-date industry best practices and AER requirements. This standardization ensures that our tools are more reliable and consistent, enabling users to generate more accurate and comparable emissions estimates.",
  "Improved estimated methodologies: We have refined our estimated methodologies based on the latest scientific research and AER requirements for calculations. These enhancements allow users to obtain more precise and applicable emissions estimates for their specific situations or geographical locations.",
  "Enhanced user interface: The Emission Calculation Page now features a more intuitive and user-friendly interface, making it easier for users to navigate and access the various tools and resources available.",
  "Comprehensive documentation: We have updated our documentation to provide clearer guidance on the proper use of our emission calculation tools and estimated methodologies. This information will help users better understand the underlying principles and limitations of these resources and ensure they are used correctly and effectively.",
  "Version history and updates: To keep users informed about any future changes or improvements to the Emission Calculation Page, we have introduced a version history section. This section will provide a detailed account of updates, bug fixes, and other modifications, ensuring that users are always aware of the latest features and improvements.",
  "We encourage users to explore the updated Emission Calculation Page and familiarize themselves with the new features and enhancements. As always, we welcome your feedback and suggestions for further improvements. Please feel free to reach out to our support team with any questions, concerns, or ideas for future updates.",
  "Thank you for your continued support and trust in our platform. We look forward to providing you with even more advanced and reliable emission calculation tools and resources in the future.",
];

const disclaimerParagraphs = [
  "Please read this disclaimer carefully before using any information, tools, or methodologies provided for emission calculations. By accessing or using these resources, you acknowledge that you have read, understood, and agree to be bound by the terms of this disclaimer.",

  "Estimated methodology: The emission calculations provided on this platform are based on estimated methodologies, which are developed using available scientific research, industry best practices, and commonly accepted emission factors. However, these methodologies may not be exhaustive or universally applicable to every situation or geographical location. They should be considered as a starting point for estimating emissions and should not be considered as a definitive or legally binding measurement. ",

  "Accuracy and completeness: While we strive to provide accurate and up-to-date information, we make no warranties or representations, express or implied, as to the accuracy, completeness, reliability, or suitability of the emission calculations or estimated methodologies. Users are responsible for verifying the accuracy and appropriateness of any calculations or methodologies for their specific circumstances and for obtaining professional advice as necessary. ",

  "Limitation of liability: In no event shall we be liable for any direct, indirect, incidental, consequential, or special damages, including but not limited to loss of data, profits, or goodwill, arising from or in connection with the use of or reliance upon the emission calculations or estimated methodologies. ",

  "Changes and updates: We reserve the right to modify or update the emission calculations or estimated methodologies at any time without notice. It is the user's responsibility to stay informed about any changes and to ensure they are using the most recent version of the resources. ",

  "External links: This platform may contain links to third-party websites or resources. We are not responsible for the content or accuracy of any such external sites and do not endorse or guarantee the information, products, or services offered therein. ",

  "By using the emission calculations or estimated methodologies, you agree to indemnify and hold harmless the provider of these resources, its affiliates, officers, employees, subcontractors, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with your access to or use of these resources.",
];
export default function VersionHistoryPage() {
  const navigate = useNavigate();

  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);
  return (
    <div className="caseStudyPage">
      <div className="caseStudyBackground">
        <div className="flex-end-ptac">
          <Box
            component="img"
            sx={{
              p: "1em",

              maxWidth: { xs: 200, md: 300 },
              maxHeight: { xs: 200, md: 300 },
            }}
            alt="PTAC Logo."
            src="/images/PTAC.png"
          />
        </div>
        <div className="white-font indent">
          <div className="caseTitle">Version History</div>
          <div className="indent-3-percent versionParagraphScroll">
            <div className="caseBody versionParagraph">
              {versionParagraphs[0]}
            </div>
            <div className="caseBody versionParagraph">
              {versionParagraphs[1]}
            </div>
            <div className="caseBody versionParagraph">
              {versionParagraphs[2]}
            </div>
            <div className="caseBody versionParagraph indent-3-percent">
              {versionParagraphs[3]}
            </div>
            <div className="caseBody versionParagraph indent-3-percent">
              {versionParagraphs[4]}
            </div>
            <div className="caseBody versionParagraph indent-3-percent">
              {versionParagraphs[5]}
            </div>
            <div className="caseBody versionParagraph indent-3-percent">
              {versionParagraphs[6]}
            </div>
            <div className="caseBody versionParagraph indent-3-percent">
              {versionParagraphs[7]}
            </div>
            <div className="caseBody versionParagraph ">
              {versionParagraphs[8]}
            </div>
            <div className="caseBody versionParagraph">
              {versionParagraphs[9]}
            </div>
          </div>
          <div className="caseTitle" style={{ marginTop: "5%" }}>
            Disclaimer
          </div>
          <div className="indent-3-percent versionParagraphScroll">
            <div className="caseBody versionParagraph">
              {disclaimerParagraphs[0]}
            </div>
            <div className="caseBody versionParagraph indent-3-percent">
              {disclaimerParagraphs[1]}
            </div>
            <div className="caseBody versionParagraph indent-3-percent">
              {disclaimerParagraphs[2]}
            </div>
            <div className="caseBody versionParagraph indent-3-percent">
              {disclaimerParagraphs[3]}
            </div>
            <div className="caseBody versionParagraph  indent-3-percent">
              {disclaimerParagraphs[4]}
            </div>
            <div className="caseBody versionParagraph indent-3-percent">
              {disclaimerParagraphs[5]}
            </div>
            <div className="caseBody versionParagraph">
              {disclaimerParagraphs[6]}
            </div>
          </div>
        </div>
        <div
          style={{ margin: "3%" }}
          className="flex-align-horizontal-vertical"
        >
          <PurpleButton
            text="Go Back"
            onClickHandler={() => navigate("/calculations")}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}
