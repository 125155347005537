import React, { useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
//Components
import CaseStudy from "../../../Components/CaseStudy";
import Footer from "../../../Components/Footer/Footer";

import "./caseStudyStyles.css";
export default function ManagingEmissions() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  const emissionParagraphs = [
    "A medium-sized producer in Canada was struggling to keep up with the fast-changing environmental regulatory landscape, and dozens of technology companies were approaching them with various solutions for abatement and monitoring. However, the producer was unsure of which solutions to adopt. Feeling overwhelmed, they reached out to various emissions management companies for help.",
    "These companies provided the producer with several options and recommendations for how best to manage its data. With a strong understanding of the producer's assets and baseline emissions, they worked with the producer to develop a cost-effective, long-term emissions reduction strategy that would keep investors happy and demonstrate global leadership.",
    "Emissions Management took everything off the producer's hands, developing and implementing abatement strategies and a fugitive emissions monitoring program while taking responsibility for all the reporting requirements. The producer noted, 'We are now spending less to achieve more, and we are proud to be showcasing our use of cutting - edge technologies to the public.' Emissions management companies have demonstrated that it is possible to reduce emissions significantly with limited resources, and its approach has become a valuable case study for other operators.",
  ];

  return (
    <div className="caseStudyPage">
      <div className="caseStudyBackground">
        <CaseStudy
          title="Managing Emissions Reductions with Limited Resources"
          paragraphs={emissionParagraphs}
          hasButton={true}
          className="caseStudy"
          buttonText={"Go Back"}
          buttonNavigateTo="/resources"
        />
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
