import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter as Router, Switch, Route, Link, useNavigate } from 'react-router-dom';
import LogOutBtn from '../../Components/auth/LogOutBtn';
import NavBar from '../../Components/NavBar/NavBar';
import AuthContext from '../../context/AuthContext';

function TestingPage() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);


  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
    });

  const [user, setUser] = useState(null);

  // Call your API to fetch the user's data from the session after they log in

  useEffect(() => {
      const script = document.createElement("script");

      script.src = "https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=949517519";
      script.async = true;
  
      document.body.appendChild(script);
  }, []);

  

  // Render the user's data if they are logged in

    return (
      <div>
        <NavBar/>
        <h2>Welcome! </h2>
        <p>Email</p>
        <a
  href="https://online.flippingbook.com/view/949517519/"
  className="fbo-embed"
  data-fbo-id="8df66fd0b2"
  data-fbo-ratio="3:2"
  data-fbo-lightbox="yes"
  data-fbo-width="100%"
  data-fbo-height="auto"
  data-fbo-version="1"
  style={{ maxWidth: "100%" }}
>
  Canadian Capabilities in Methane Emissions Reduction
</a>
        <LogOutBtn />
      </div>
    );
  

}

export default TestingPage;
