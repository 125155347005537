import React, { useContext, useEffect } from "react";

import { definitions } from "./GlossaryDefinitions";
// Components
import CaseStudy from "../../Components/CaseStudy";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import PurpleButton from "../../Components/Buttons/PurpleButton";
// Styles
import "../../Pages/Resources/CaseStudyPages/caseStudyStyles.css";
import "./glossaryStyles.css";

/**
 *
 * @returns The glossary page and all its definitions.
 */
export default function GlossaryPage() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);

  const boldDefinition = (paragraph) => {
    const term = paragraph.substring(0, paragraph.indexOf(":") + 1);
    const definition = paragraph.substring(
      paragraph.indexOf(":") + 1,
      paragraph.length
    );
    return (
      <div>
        <span style={{ fontWeight: "bold", letterSpacing: "1.5px" }}>
          {term}
        </span>
        <span>{definition}.</span>
      </div>
    );
  };

  return (
    <div className="caseStudyPage">
      <div className="caseStudyBackground">
        <div>
          <NavBar />
        </div>

        <div className="indent">
          <h1 className="white-font caseTitle">Glossary</h1>
          <div className="white-font indent font-weight-300 caseBody glossaryDefinitions justifyParagraph">
            {definitions
              ? definitions.map(function (paragraph, index) {
                  return (
                    <div>
                      <p key={"glossary" + index}>
                        {boldDefinition(paragraph)}
                      </p>
                    </div>
                  );
                })
              : null}
          </div>
          <div
            style={{ margin: "5% 0 150px 0" }}
            className="flex-align-horizontal-vertical"
          >
            <PurpleButton
              text={"Equations Framework"}
              onClickHandler={() => navigate("/equation-frameworks")}
            />
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
