import React, { useState, useContext, useEffect } from "react";
import validator from "validator";
import { Link, useNavigate } from "react-router-dom";

import { loginUser } from "../../APIs/databaseAPI";
import AuthContext from "../../context/AuthContext";

// Styles
import "./loginStyles.css";

// Components
import TextInput from "../../Components/TextInput";
import { Typography, Box, IconButton } from "@mui/material";
import CustomizedSwitches from "../../Components/CustomToggleSwitch";

// footer
import "../../Components/Footer/footerStyles.css";
import PolicyDialog from "../../Components/Footer/FooterDialogs/privacyPolicyDialog";
import TermsDialog from "../../Components/Footer/FooterDialogs/termsDialog";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState({
    error: false,
    text: "",
  });

  // footer dialog handlers
  const [policyOpen, setPolicyOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const handlePolicyDialogOpen = () => (setPolicyOpen(true))
  const handlePolicyDialogClose = () => (setPolicyOpen(false));
  const handleTermsDialogOpen = () => (setTermsOpen(true));
  const handleTermsDialogClose = () => (setTermsOpen(false));

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);
  const { getLoggedIn, loggedIn } = useContext(AuthContext);
  // Redirect to home page if logged in
  useEffect(() => {
    if (loggedIn) {
      navigate("/home");
    }
  });

  const onSubmit = () => {
    loginUser(
      validator.escape(email),
      validator.escape(password),
      function (response) {
        if (response.status === 200) {
          if (getLoggedIn()) {
            navigate("/home");
          }
        } else {
          setErrorText({
            error: true,
            text: "Invalid email or password.",
          });
        }
      }
    );
  };

  const [emailFocused, setEmailFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);

  const handleEmailFocus = () => {
    setEmailFocused(true);
  };
  const handleEmailBlur = () => {
    setEmailFocused(true);
  };
  const handlePasswordFocus = () => {
    setPasswordFocused(true);
  };
  const handlePasswordBlur = () => {
    setPasswordFocused(true);
  };

  const handleSubmitOnEnter = (event) => {
    if (event.key === "Enter" && (emailFocused || passwordFocused)) {
      onSubmit();
    }
  };

  const [switchChecked, setSwitchChecked] = useState(false);

  useEffect(() => {
    if (email.length > 0 && password.length > 0) {
      setSwitchChecked(true);
    } else {
      setSwitchChecked(false);
    }
  }, [email.length, password.length]);

  const [filled, setFilled] = useState(false);
  useEffect(() => {
    setFilled(email.length > 0 && password.length > 0);
  }, [email, password]);

  const handleReset = () => {
    setEmail("");
    setPassword("");
  };
  return (
    <div className="loginPage">
      <div className="loginMain">
        <div className="ptacLogoContainer">
          <div className="loginPtacLogo">
            <Box
              component="img"
              sx={{
                p: "0",
                width: 400,
                maxWidth: { xs: 200, sm: 250, md: 300 },
                maxHeight: { xs: 200, sm: 250, md: 300 },
              }}
              src="/images/PTAC.png"
            />
          </div>
          <div className="cerinLogo">
            <Box
              component="img"
              sx={{
                p: "0",
                width: 250,
                maxWidth: { xs: 150, sm: 200, md: 250 },
                maxHeight: { xs: 150, sm: 200, md: 250 },
              }}
              src="/images/cerinLogo_nobg.png"
            />
          </div>
        </div>
        <div className="loginTitleContainer">
          <p className="loginTitle bold-white-font">
            EMISSIONS WEB PORTAL / APP FOR TANK VENTING INVESTIGATIONS & MITIGATION PRACTICES
          </p>
        </div>

        <div className="loginContainer">
          <div className="loginSubTitleContainer">
            <p className="loginSubTitle bold-white-font">Portal Login</p>
          </div>
          <div className="inputContainer">
            <div className="resetContainer">
              <IconButton onClick={handleReset}>
                <div className="white-font resetButton">Reset All</div>
              </IconButton>
            </div>

            <div className="inputSwitchContainer">
              <div className="loginSwitch">
                <CustomizedSwitches checked={switchChecked} />
              </div>

              <div className="emailPasswordWrapper">
                <div className="loginInputWrapper">
                  <TextInput
                    label="Email"
                    value={email}
                    valueChangeHandler={setEmail}
                    type="text"
                    isRequired={true}
                    inputStyle="loginInput"
                    labelStyle="bold-400-white "
                    page={"login"}
                    onBlur={handleEmailBlur}
                    onFocus={handleEmailFocus}
                    onKeyDown={handleSubmitOnEnter}
                  />
                </div>
                <div className="loginInputWrapper">
                  <TextInput
                    label="Password"
                    value={password}
                    valueChangeHandler={setPassword}
                    type="password"
                    isRequired={true}
                    inputStyle="loginInput"
                    labelStyle="bold-400-white"
                    page={"login"}
                    onBlur={handlePasswordBlur}
                    onFocus={handlePasswordFocus}
                    onKeyDown={handleSubmitOnEnter}
                  />
                  <p style={{ color: "red" }}>{errorText.text}</p>
                </div>
                <div className="flex-center">
                  <a href="https://carbonemissionscanada.com/forgot-password">
                    Forgot Password?
                  </a>
                </div>

                <div className="flex-center">
                  <button className="loginButton" onClick={onSubmit}>
                    LOGIN
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-center signUpContainer">
            <Typography className="bold-400-white" variant="h6">
              Don't have an Account?
            </Typography>

            <button
              className="flex-center signUpButton"
              onClick={() => navigate("/register")}
              style={{ marginLeft: "2%" }}
            >
              <p className="signUpText">Sign Up</p>
            </button>
          </div>
        </div>
        {/* footer */}
        <div className="footerFlexBox white-font">
          <div
            onClick={handlePolicyDialogOpen}
            className="indent footerText"
            style={{ cursor: "pointer" }}
          >
            Privacy Policy
          </div>
          <PolicyDialog
            policyOpen={policyOpen}
            handlePolicyDialogOpen={handlePolicyDialogOpen}
            handlePolicyDialogClose={handlePolicyDialogClose}
          />
          <div
            onClick={handleTermsDialogOpen}
            className="indent footerText"
            style={{ cursor: "pointer" }}
          >
            Terms & Conditions
          </div>
          <TermsDialog
            termsOpen={termsOpen}
            handleTermsDialogOpen={handleTermsDialogOpen}
            handleTermsDialogClose={handleTermsDialogClose}
          />
          <div className="footerCompanyName footerText white-font">
            <div>Powered by</div>
            <IconButton
              className="breakWaterIconButton"
              // onClick={handleBWLogoClick}
            >
              <img
                className="footerBWVantageLogo"
                src="images\BreakwaterVantageLogo .png"
                alt="BWVantage Logo"
              />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}
