import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Styles
import "./footerDialogStyles.css";

// Text
import { termPoints } from "./termsPoints";
export default function TermsDialog({
  termsOpen,
  handleTermsDialogOpen,
  handleTermsDialogClose,
}) {
  return (
    <div>
      <Dialog
        open={termsOpen}
        onClose={handleTermsDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="termsBackground white-font">
          <DialogTitle>Terms and Conditions</DialogTitle>
          <DialogContentText
            className="white-font"
            id="alert-dialog-description"
          >
            <div className="white-font policyContent">
              {termPoints.map(function (point, index) {
                return (
                  <div key={point + "the" + index}>
                    <h3>{point.title}</h3>
                    {point.points.map(function (innerPoint, index) {
                      return <p key={innerPoint + "" + index}>{innerPoint}</p>;
                    })}
                  </div>
                );
              })}
            </div>
          </DialogContentText>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                backgroundImage:
                  "linear-gradient(45deg, rgb(10,0,222) , #5711b9)",
                marginTop: "20px",
              }}
              onClick={handleTermsDialogClose}
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
