import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";

import CalculatorInput from "./CalculatorInput";
import SelectBox from "../SelectBox";

/**
 * @description - An assumption dialog that holds inputs and select boxes for the calculations.
 * Value changes will only persist when the user clicks save.
 * If the user makes changes and closes/cancels the dialog. The values will revert to their initial values.
 * @param {String} open - A boolean value determining the open state of the dialog.
 * @param {Function} handleClose - A function/setter that sets the 'open' state to false.
 * @param {String} assumptionTitle - Main title at the top of dialog.
 * **IMPORTANT - assumptionSubTitles, assumptionInputs, assumptionLabels, and assumptionValueSetters must be in the same order.
 * **IMPORTANT - Each item is for one section of the array. So if you only need one section (aka no subtitle/one subtitle). You're array will only hold on array item.
 * @example assumptionInputs [[firstSectionOfInputs], [secondSectionOfInputs]]
 * @param {Nested array of strings} assumptionSubTitles - Each item will be an array that holds subtitles for sections of the assumptions.
 * @param {Nested array of strings} assumptionInputs- Each item will be an array of useState hooks that hold the value of each input.
 * @param {Nested array of strings} assumptionLabels - Each item will be an array of labels for each input.
 * @param {Nested array of strings} assumptionValueSetters - Each item will be an array of useState setters that change the value of each input.
 * @param {String} type - Type of input. Ex. "text" or "number"
 * **IMPORTANT - For having a select box beside each input. Must  also be in order as the assumptionInputs..etc.
 * @param {Array of strings} assumptionSelectValues - Array of useState hooks that hold the current value of select option. Also acts as the default value.
 * @param {Nested array of strings} assumptionSelectSetters - Each item will be an array of strings that represent the options for each select box.
 * @param {Array of strings} assumptionSelectOptions - Array of useState setters that change the value of each select option.
 * **IMPORTANT - To create a select box that doesnt need an input box beside it. An isolated select box.
 * @param {Array of strings} assumptionIsolateSelectOptions - Each item will be an array of strings that represent the options for each select box.
 * @param {Array of strings} assumptionInfoTexts - Each item will be an array of strings that reperesent the info texts for the popover
 * @param {Array of strings} assumptionAdorments - Each item will be an array of strings that reperesent the unit for the adornment
 * @returns A dialog for assumptions for a section of calculations.
 */
export default function AssumptionDialog({
  open,
  handleClose,
  assumptionTitle,
  assumptionSubTitles,
  assumptionInputs,
  assumptionLabels,
  assumptionValueSetters,
  type,
  assumptionSelectValues,
  assumptionSelectSetters,
  assumptionSelectOptions,
  assumptionIsolateSelectOptions,
  assumptionInfoTexts,
  assumptionAdorments,
  assumptionDefaults,
  assumptionSelectDefaults,
}) {
  // Holds the initial values of when the dialog is open
  const [initialInputs, setInitialInputs] = useState(
    assumptionInputs ? JSON.parse(JSON.stringify(assumptionInputs)) : null
  );
  // For value changing.
  const [currentInputs, setCurrentInputs] = useState(assumptionInputs);

  const [initialSelects, setInitialSelects] = useState(
    assumptionSelectValues ? assumptionSelectValues : null
  );
  const [currentSelects, setCurrentSelects] = useState(
    assumptionSelectValues ? assumptionSelectValues : null
  );

  const handleSelectChange = (index, value) => {
    const newInputs = [...currentSelects];
    newInputs[index] = value;
    setCurrentSelects(newInputs);
  };

  // Sets only the current inputs to the value changes.
  const handleInputValueChange = (outerIndex, index, value) => {
    const newInputs = [...currentInputs];
    newInputs[outerIndex][index] = value;
    setCurrentInputs(newInputs);
  };
  // Sets the initialInputs to be the new value.
  const handleSaveClick = () => {
    setInitialInputs(currentInputs);
    assumptionValueSetters.forEach((setterGroup, outerIndex) => {
      setterGroup.forEach((setter, index) => {
        setter(currentInputs[outerIndex][index]);
      });
    });

    setInitialSelects(currentSelects);
    handleClose();
  };

  const handleDialogClose = () => {
    // reset the current inputs to the initial inputs only if Cancel button is clicked
    setCurrentInputs(JSON.parse(JSON.stringify(initialInputs)));
    setCurrentSelects(JSON.parse(JSON.stringify(initialSelects)));
    // close the dialog
    handleClose();
  };

  const handleResetClick = () => {
    assumptionValueSetters.forEach((setterGroup, outerIndex) => {
      setterGroup.forEach((setter, index) => {
        setter(assumptionDefaults[outerIndex][index]);
      });
    });
    setCurrentInputs(JSON.parse(JSON.stringify(assumptionDefaults)));
    if (currentSelects) {
      setCurrentSelects(assumptionSelectDefaults);
    }
  };

  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleDialogClose}>
        {/* -------Title--------- */}
        <DialogTitle>{assumptionTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Below are standardized assumption values that you are free to change
            to your needs.
          </DialogContentText>

          <div className="flex-align-end">
            <Button variant="text" onClick={handleResetClick}>
              Reset
            </Button>
          </div>

          <div>
            {initialInputs
              ? initialInputs.map((input, outerIndex) => (
                  /* Create a section for every subtitle */
                  <div key={assumptionLabels[outerIndex] + "OuterKey"}>
                    <div className="subTitleText">
                      {/* -------Subtitle--------- */}

                      {/** If there is an array of subtitles. Use it. */}
                      {assumptionSubTitles
                        ? assumptionSubTitles[outerIndex]
                        : null}
                    </div>
                    {initialInputs
                      ? initialInputs[outerIndex].map(function (input, index) {
                          const thisLabel = assumptionLabels[outerIndex][index];
                          //For isolated select box
                          if (thisLabel === "Select") {
                            return (
                              <div
                                key={
                                  assumptionLabels[outerIndex][index] + "Input"
                                }
                                style={{
                                  marginRight: "5%",
                                  marginLeft: "-10%",
                                }}
                              >
                                <SelectBox
                                  selectValue={currentInputs[outerIndex][index]}
                                  handleSelectChange={(e) =>
                                    handleInputValueChange(
                                      outerIndex,
                                      index,
                                      e.target.value
                                    )
                                  }
                                  selectOptions={
                                    assumptionIsolateSelectOptions[0]
                                  }
                                  label={thisLabel}
                                />
                              </div>
                            );
                          } else {
                            // Normal input
                            return (
                              <div
                                style={{
                                  marginRight: "5%",
                                  marginLeft: "-10%",
                                }}
                              >
                                <CalculatorInput
                                  className="calculationInputAndLabel"
                                  key={
                                    assumptionLabels[outerIndex][index] +
                                    "Input"
                                  }
                                  value={currentInputs[outerIndex][index]}
                                  label={assumptionLabels[outerIndex][index]}
                                  valueChangeHandler={(value) =>
                                    handleInputValueChange(
                                      outerIndex,
                                      index,
                                      value
                                    )
                                  }
                                  type={type}
                                  isRequired={false}
                                  //Select Options
                                  selectOptions={
                                    assumptionSelectOptions
                                      ? assumptionSelectOptions[index]
                                      : null
                                  }
                                  selectValue={
                                    assumptionSelectValues
                                      ? currentSelects[index]
                                      : null
                                  }
                                  selectValueSetter={
                                    assumptionSelectSetters
                                      ? (value) =>
                                          handleSelectChange(index, value)
                                      : null
                                  }
                                  popoverText={
                                    assumptionInfoTexts
                                      ? [assumptionInfoTexts[outerIndex][index]]
                                      : null
                                  }
                                  adornments={
                                    assumptionAdorments
                                      ? [assumptionAdorments[outerIndex][index]]
                                      : null
                                  }
                                />
                              </div>
                            );
                          }
                        })
                      : null}
                    <Divider variant="middle" />
                  </div>
                ))
              : null}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveClick}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
