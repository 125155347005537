import React, { useEffect, useState } from "react";
//Components
import CalculatorSection from "../../../Components/Calculator/CalculatorSection";

// API
import { getPneumaticSubTotal } from "../../../APIs/calculatorAPI";

export default function PneumaticInstrument(methaneMolPercentage) {
  // Inputs
  const [levelControllers, setLevelControllers] = useState("");
  const [positioners, setPositioners] = useState("");
  const [pressureControllers, setPressureControllers] = useState("");
  const [transducers, setTransducers] = useState("");
  const [genericInstruments, setGenericInstruments] = useState("");
  const [genericPistonPumps, setGenericPistonPumps] = useState("");
  const [genericDiaphragmPumps, setGenericDiaphragmPumps] = useState("");

  const pneumaticLabels = [
    "Level Controllers",
    "Positioners",
    "Pressure Controller",
    "Transducer",
    "Generic Instrument",
    "Generic Piston Pump",
    "Generic Diaphragm Pump",
  ];
  const pneumaticInfoTexts = [
    "A device used to maintain a constant level of liquid in a tank or vessel by controlling the flow of liquid into or out of the tank.",
    "A device used to accurately position control valves or other components based on feedback from sensors or other measurement devices.",
    "A device used in the oil and gas industry to maintain a constant pressure in a system by regulating the flow of gas or liquid into or out of the system.",
    "A device used in the oil and gas industry to convert one form of energy to another, such as converting mechanical force into an electrical signal.",
    "A measuring or control device that is not specialized for a particular application or industry.",
    "A pump that is not specialized for a particular application or industry, but can be used in a wide range of applications in the oil and gas industry.",
    "A type of pump that uses a flexible diaphragm to move fluids, typically used for low-pressure applications in the oil and gas industry.",
  ];

  // Assumptions
  const defaultEmissionHour = "1700";
  const [levelControllerHours, setLevelControllerHours] =
    useState(defaultEmissionHour);
  const [positionersHours, setPositionersHours] = useState(defaultEmissionHour);
  const [pressureControllerHours, setPressureControllerHours] =
    useState(defaultEmissionHour);
  const [transducerHours, setTransducerHours] = useState(defaultEmissionHour);
  const [genericInstrumentHours, setGenericInstrumentHours] =
    useState(defaultEmissionHour);
  const [genericPistonPumpHours, setGenericPistonPumpHours] =
    useState(defaultEmissionHour);
  const [genericDiaphragmPumpHours, setGenericDiaphragmPumpHours] =
    useState(defaultEmissionHour);
  const pneumaticHourAssumptionInputs = [
    levelControllerHours,
    positionersHours,
    pressureControllerHours,
    transducerHours,
    genericInstrumentHours,
    genericPistonPumpHours,
    genericDiaphragmPumpHours,
  ];
  const pneumaticHourSetters = [
    setLevelControllerHours,
    setPositionersHours,
    setPressureControllerHours,
    setTransducerHours,
    setGenericInstrumentHours,
    setGenericPistonPumpHours,
    setGenericDiaphragmPumpHours,
  ];
  const pneumaticHourLabels = pneumaticLabels.map(function (label, index) {
    return label + " Hours";
  });
  const hoursPerYear = "hours/year";
  const assumptionAdornments = [
    [
      hoursPerYear,
      hoursPerYear,
      hoursPerYear,
      hoursPerYear,
      hoursPerYear,
      hoursPerYear,
      hoursPerYear,
    ],
  ];
  const pneumaticDefaults = [
    [
      defaultEmissionHour,
      defaultEmissionHour,
      defaultEmissionHour,
      defaultEmissionHour,
      defaultEmissionHour,
      defaultEmissionHour,
      defaultEmissionHour,
    ],
  ];
  // Summary Inputs
  const [kgCH4PerMonth, setKgCH4PerMonth] = useState("");
  const [tco2e, setTco2e] = useState("");
  const summaryLabels = ["KgCH4/Month", "tC02e/Year"];

  useEffect(() => {
    getPneumaticSubTotal(
      "pneumatic",
      methaneMolPercentage,
      levelControllers,
      positioners,
      pressureControllers,
      transducers,
      genericInstruments,
      genericPistonPumps,
      genericDiaphragmPumps,
      levelControllerHours,
      positionersHours,
      pressureControllerHours,
      transducerHours,
      genericInstrumentHours,
      genericPistonPumpHours,
      genericDiaphragmPumpHours,
      function (response) {
        const monthly = response[0];
        const yearly = response[1];
        setKgCH4PerMonth(monthly);
        setTco2e(yearly);
      }
    );
  }, [
    genericDiaphragmPumpHours,
    genericDiaphragmPumps,
    genericInstrumentHours,
    genericInstruments,
    genericPistonPumpHours,
    genericPistonPumps,
    levelControllerHours,
    levelControllers,
    methaneMolPercentage,
    positioners,
    positionersHours,
    pressureControllerHours,
    pressureControllers,
    transducerHours,
    transducers,
  ]);

  const pneumaticInstrumentInputs = {
    levelControllers: { value: levelControllers, hours: levelControllerHours },
    positioners: { value: positioners, hours: positionersHours },
    pressureControllers: {
      value: pressureControllers,
      hours: pressureControllerHours,
    },
    transducers: { value: transducers, hours: transducerHours },
    genericInstruments: {
      value: genericInstruments,
      hours: genericInstrumentHours,
    },
    genericPistonPumps: {
      value: genericPistonPumps,
      hours: genericDiaphragmPumpHours,
    },
    genericDiaphragmPumps: {
      value: genericDiaphragmPumps,
      hours: genericDiaphragmPumpHours,
    },
  };

  return {
    pneumaticInstrumentInputs,
    pneumaticInstrumentSection: (
      <div>
        <div className="white-font sectionTitle">Pneumatic Instrument Pump</div>

        <CalculatorSection
          title="Emission Calculation"
          // Inputs
          inputs={[
            levelControllers,
            positioners,
            pressureControllers,
            transducers,
            genericInstruments,
            genericPistonPumps,
            genericDiaphragmPumps,
          ]}
          inputValueSetters={[
            setLevelControllers,
            setPositioners,
            setPressureControllers,
            setTransducers,
            setGenericInstruments,
            setGenericPistonPumps,
            setGenericDiaphragmPumps,
          ]}
          labels={pneumaticLabels}
          infoTexts={pneumaticInfoTexts}
          type="number"
          // Summary Inputs
          summaryTitle="Pneumatic Emissions"
          summaryInputs={[kgCH4PerMonth, tco2e]}
          summaryLabels={summaryLabels}
          summaryValueSetters={[setKgCH4PerMonth, setTco2e]}
          // Assumption Inputs
          assumptionTitle="Pneumatic Instrument Site Assumptions"
          assumptionInputs={[pneumaticHourAssumptionInputs]}
          assumptionLabels={[pneumaticHourLabels]}
          assumptionValueSetters={[pneumaticHourSetters]}
          assumptionAdorments={assumptionAdornments}
          assumptionDefaults={pneumaticDefaults}
        />
      </div>
    ),
  };
}
