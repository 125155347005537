import React, { useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
//Components
import CaseStudy from "../../../Components/CaseStudy";
import Footer from "../../../Components/Footer/Footer";

export default function Vru() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  const researchParagraphs = [
    "During various stages of oil and gas production, controlled venting of methane and other hydrocarbon gases has traditionally been an integral part of the process at facilities. However, the industry is shifting its focus towards eliminating atmospheric venting of hydrocarbon gases to comply with new regulations, boost revenue, and enhance Environmental, Social, and Governance (ESG) performance. Presently, the largest reported source of controlled venting stems from atmospheric tanks, where produced gases escape as the pressure decreases. Addressing the unique challenges posed by tank vents, Marathon Compression has spent the past decade developing specialized product solutions.",
    "Vapor Recovery Units (VRUs) have been designed to capture vent gas from atmospheric tanks and increase its pressure to a level suitable for reintroduction into the process. By utilizing this equipment, valuable gas production, which was previously discarded as waste-gas into the atmosphere, can now be captured and repurposed. Marathon Compression collaborates with producers and Engineering, Procurement, Construction, and Management (EPCM) firms to design custom single or multi-stage VRUs tailored to meet specific site requirements, creating a more efficient and environmentally-friendly solution for the industry.",
  ];
  return (
    <div className="caseStudyPage">
      <div className="caseStudyBackground">
        <CaseStudy
          title="Vapour Recovery Units (VRU's)"
          paragraphs={researchParagraphs}
          hasButton={true}
          className="caseStudy"
          buttonText={"Go Back"}
          buttonNavigateTo="/resources"
        />
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
