import React, { useState } from "react";
import { IconButton } from "@mui/material";
// Styles
import "./footerStyles.css";

// Components
import PolicyDialog from "./FooterDialogs/privacyPolicyDialog";
import TermsDialog from "./FooterDialogs/termsDialog";

/**
 *
 * @returns The footer with clickable logos that navigate to the appropriate links.
 */
export default function Footer() {
  function handleLinkedInLogoClick() {
    window.open(
      "https://www.linkedin.com/company/breakwater-vantage-inc/?originalSubdomain=ca",
      "_blank"
    );
  }
  function handleTwitterLogoClick() {
    window.open("https://twitter.com/bw_vantage?lang=en", "_blank");
  }

  function handleBWLogoClick() {
    window.open(
      "https://www.breakwatervantage.com/?fbclid=IwAR1TOlRhbyqubdmi0Zac-kKCKl4ylqVb6upnh7jiSPQm8IwkHbX4sSDPN3s",
      "_blank"
    );
  }

  const [policyOpen, setPolicyOpen] = useState(false);

  const handlePolicyDialogOpen = () => {
    setPolicyOpen(true);
  };

  const handlePolicyDialogClose = () => {
    setPolicyOpen(false);
  };

  const [termsOpen, setTermsOpen] = useState(false);

  const handleTermsDialogOpen = () => {
    setTermsOpen(true);
  };

  const handleTermsDialogClose = () => {
    setTermsOpen(false);
  };

  return (
    <div>
      <div className="logoContainer">
        <div>
          <IconButton onClick={handleLinkedInLogoClick}>
            <img
              className="footerSocialMediaLogos"
              src="/images/LinkedInLogo.png"
              alt="linkedIn logo"
            />
          </IconButton>
          <IconButton onClick={handleTwitterLogoClick}>
            <img
              className="footerSocialMediaLogos"
              src="/images/TwitterLogo.png"
              alt="linkedIn logo"
            />
          </IconButton>
        </div>

        <div className="bwLogoContainer"></div>
      </div>
      <div className="footerFlexBox white-font">
        <div
          onClick={handlePolicyDialogOpen}
          className="indent footerText"
          style={{ cursor: "pointer" }}
        >
          Privacy Policy
        </div>
        <PolicyDialog
          policyOpen={policyOpen}
          handlePolicyDialogOpen={handlePolicyDialogOpen}
          handlePolicyDialogClose={handlePolicyDialogClose}
        />

        <div
          onClick={handleTermsDialogOpen}
          className="indent footerText"
          style={{ cursor: "pointer" }}
        >
          Terms & Conditions
        </div>
        <TermsDialog
          termsOpen={termsOpen}
          handleTermsDialogOpen={handleTermsDialogOpen}
          handleTermsDialogClose={handleTermsDialogClose}
        />
        <div className="footerCompanyName footerText">
          <div>Powered by</div>

          <IconButton
            className="breakWaterIconButton"
            onClick={handleBWLogoClick}
          >
            <img
              className="footerBWVantageLogo"
              src="/images/BreakwaterVantageLogo .png"
              alt="BWVantage Logo"
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
