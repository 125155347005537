import React from "react";
// Styles
import "./buttonStyles.css";

/**
 *
 * @param {String} text - The text that goes inside the button. Ex. "Download IOS"
 * @param {Function} onClickHandler - Takes a function to determine the onClick behaviour of the button.
 * @returns The main purple button.
 */
export default function PurpleButton({ text, onClickHandler }) {
  const getTextStyle = (text) => {
    if (text.toLowerCase() === "go back") {
      return "goBackSize purple-button-box-shadow ";
    } else if (text === "Get Started") {
      return "getStartedSize purple-button-box-shadow ";
    } else if (
      text === "Equations Framework" ||
      text === "Tank Venting Eco-Efficiency and Operations Handbook" ||
      "PDF DOWNLOAD"
    ) {
      return "frameworkButtonSize purple-button-box-shadow";
    }
  };

  return (
    <button onClick={onClickHandler} className={getTextStyle(text)}>
      <div
        className={
          text.toLowerCase() === "go back"
            ? "goBackButtonText"
            : "purpleButtonText"
        }
      >
        {text}
      </div>
    </button>
  );
}
