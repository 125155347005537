import React, { useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
//Components
import CaseStudy from "../../../Components/CaseStudy";
import Footer from "../../../Components/Footer/Footer";

import "./caseStudyStyles.css";
export default function InstrumentAir() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  const instrumentAirParagraphs = [
    "This case study highlights how Shell Canada updated its well pad design in its Alberta Shales business in 2019 to eliminate methane emissions from pneumatic devices by transitioning to instrument air. At the time, Shell used surplus generators with a load bank to supply the power required to run an air compressor on its sites. However, in April 2020, Shell decided to trial a 20kW Engineered Power on Demand (EPOD) remote power generation module from Westgen Technologies. This powers an instrument air compressor using a generator/battery/solar panel system.",
    "The EPOD deployment on the Shell site resulted in greenhouse gas reductions through reduced power demand (elimination of oversize generator + load bank), elimination of venting from pneumatics, and minimizing construction scope. By integrating components such as a generator, distribution skid, uninterruptible power supply, and instrument air skid into one modular shop-fabricated solution, Shell realized a 26% capital cost savings from its previous design. Coupled with Shell's zero venting well pad design, switching to EPOD resulted in over 35% overall reduction in greenhouse emissions from previous gas pneumatic designs.",
    "In addition to the improved environmental performance, Shell also realized capital and operating cost savings. The success of this trial has resulted in the adoption of the EPOD in all future well pads in Shell's Alberta Shales operations. According to David Hatch, Shell Facilities Engineer, 'The EPOD fills a void that existed in the power generation market for remote locations.The EPOD trial unit operated consistently and demonstrated a significant reduction of fuel gas consumption, GHG emissions, and maintenance costs.' ",
    "This case study highlights the success of Shell Canada's initiative to transition to instrument air to eliminate methane emissions from pneumatic devices. The adoption of EPOD has not only resulted in significant environmental benefits, but also capital and operating cost savings. The success of this initiative also highlights the importance of innovation and collaboration with technology providers to achieve sustainable energy production.",
  ];

  return (
    <div className="caseStudyPage">
      <div className="caseStudyBackground">
        <CaseStudy
          title="Instrument Air and Power on Demand"
          paragraphs={instrumentAirParagraphs}
          hasButton={true}
          className="caseStudy"
          buttonText={"Go Back"}
          buttonNavigateTo="/resources"
          bigImage={"instrumentBig.jpg"}
          smallImage={"instrumentSmall.jpg"}
        />
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
