import axios from "axios";

const getCoordinates = function (uwi, callback) {
  axios
    .post("https://api.carbonemissionscanada.com/map/getCoordinates", {
      uwi: uwi,
    })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) =>
      console.log("Something went wrong with getting coordinates.")
    );
};

export { getCoordinates };
