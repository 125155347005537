import React, { useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
//Components
import CaseStudy from "../../../Components/CaseStudy";
import Footer from "../../../Components/Footer/Footer";

import "./caseStudyStyles.css";
export default function ElectricalDevices() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  const electricalParagraphs = [
    "This case study highlights the success of Calscan Solutions' electric actuators, which have been replacing pneumatic controllers for ten years. By using electric devices instead of traditional pneumatic ones, the challenge of wet or sour gas causing issues with pneumatic controllers is eliminated. When the industry began searching for solutions to reduce methane emissions through pneumatic devices, Calscan was already there with a solution.",
    "In 2018, a producer approached Calscan seeking a complete electrical solution for reducing greenhouse gases at its well sites in Northern Alberta, for both brownfield and greenfield developments. Calscan designed the solution and supplied various electrically controlled actuators, controllers, and chemical injection pumps powered by solar panels and batteries. The solutions were included in the design, and the separators were built and retrofitted using these electrical devices.",
    "The producer was very pleased with the results of eliminating all methane emissions from pneumatics, which not only saved on maintenance costs but also plumbing and installation of traditional pneumatically controlled devices. The producer estimated that they reduced methane emissions by over 300 Tonnes CO2eq/year for each separator package since deploying the Calscan devices. The success of the solution resulted in the producer installing over 20 packages on brownfield and greenfield well-site separators.",
    "This case study showcases the effectiveness of Calscan Solutions' electric actuators in reducing methane emissions, resulting in significant environmental and cost benefits for producers. The success of this solution also highlights the importance of innovation in the industry to achieve sustainable energy production.",
  ];

  return (
    <div className="caseStudyPage">
      <div className="caseStudyBackground">
        <CaseStudy
          title="Electric Devices Replace Pneumatics"
          paragraphs={electricalParagraphs}
          hasButton={true}
          className="caseStudy"
          buttonText={"Go Back"}
          buttonNavigateTo="/resources"
        />
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
