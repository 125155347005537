import React from "react";
import { Grid, Box } from "@mui/material";

// Styles
import "./componentStyles.css";

/**
 *
 * @param {String} title - The title of the text.
 * @param {String} bodyText - Body of the text.
 * @param {String} mainImage - The showcased image in the card.
 * @param {String} backgroundImage - The background image for the card.
 * @returns A card that includes an image, title and body. Also a backgound image that has a transparant opacity.
 */
export default function InfoCard({
  title,
  bodyText,
  mainImage,
  backgroundImage,
}) {
  return (
    <div className="infoCard" style={{ position: "relative" }}>
      <div
        className="infoCardBackground"
        style={{
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/${backgroundImage})`,
          backgroundSize: "cover",
          opacity: "0.4",
        }}
      />
      <Grid container>
        <Grid item lg={5} md={12} sm={12} xs={12}>
          <Box
            component="img"
            sx={{
              borderRadius: 10,
              opacity: 0.4,
              p: "1em",
              maxHeight: { sm: 300 },
              maxWidth: { xs: 220, sm: 400, md: 400, lg: 400 },
            }}
            alt="Card Image"
            src={"/images/" + mainImage}
          />
        </Grid>
        <Grid item lg={7} md={12} sm={12} xs={12}>
          <div>
            <p className="infoCardTitle">{title}</p>
          </div>
          <div className="infoCardBody justifyParagraph">{bodyText}</div>
        </Grid>
      </Grid>
    </div>
  );
}
