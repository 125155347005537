import React, { useEffect, useState } from "react";

// Components
import CalculatorSection from "../../../Components/Calculator/CalculatorSection";

// API
import { getFacilitySubTotal } from "../../../APIs/calculatorAPI";

export default function FacilityEquipmentEmission(methaneMolPercentage) {
  // Inputs
  const [genericVent, setGenericVent] = useState("");
  const [fuelConsumption, setFuelConsumption] = useState("");
  const [engTurbineStart, setEngTurbineStart] = useState("");
  const [numberOfRailTankTrucks, setNumberOfRailTankTrucks] = useState("");
  const [numberOfDehydrators, setNumberOfDehydrators] = useState("");
  const [numberOfCompressorSeals, setNumberOfCompressorSeals] = useState("");
  const [numberOfPigTraps, setNumberOfPigTraps] = useState("");
  const inputLabels = [
    "Generic Vent",
    "Fuel Consumption",
    "Eng/Turbine Start",
    "Rail/Tank Trucks",
    "Dehydrators",
    "Compressor Seals",
    "Pig Traps",
  ];
  const adornments = ["KgCO2e", "MCF", null, null, null, null, null];
  const facilityInfoTexts = [
    "User Input Emission in KgCO2e.",
    "Onsite consumption of natural gas produced, where the lower or higher heating value (LHV/HHV) is used as fuel for energy generation or other processes. (MCF)",
    "Number of Engine/Turbine Starts per month.",
    "Number of rail or tank trucks required for transporting oil from the production site.",
    "A dehydrator is a piece of equipment used in the oil and gas industry to remove water and other contaminants from natural gas.",
    "Compressor seals are used in gas compressors to prevent leakage of gas from the compressor.",
    "Pig traps are devices used in pipeline systems to facilitate the insertion and removal of pipeline inspection gauges (etc.) also known as pigs",
  ];

  //---------Assumptions -------
  const assumptionSubTitle = [
    "Fuel Consumption",
    "Eng/Turbine Starts",
    "Rail/Tank Trucks",
    "Dehydrators",
    "Compressor Seals",
    "Pig Traps",
  ];
  // fuel consumption
  const [lhv, setLhv] = useState("34.6");
  const [fuelAllocation, setFuelAllocation] = useState("10");
  const fuelConsumptionAssumptionLabels = ["LHV", "Allocation %"];
  const fuelConsumptionInputs = [lhv, fuelAllocation];
  const fuelConsumptionSetters = [setLhv, setFuelAllocation];
  // eng/turbine starts
  const [er, setEr] = useState("5172");
  const [t, setT] = useState("0.05");
  const [engAllocation, setEngAllocation] = useState("10");
  const engTurbineAssumptionLabels = ["ER", "t", "Allocation %"];
  const engTurbineInputs = [er, t, engAllocation];
  const engTurbineSetters = [setEr, setT, setEngAllocation];
  // Rail/Tank Trucks
  const [rvp, setRvp] = useState("40.5");
  const [sf, setSf] = useState("0.5");
  const [oilVolume, setOilVolume] = useState("100");
  const [tt, setTt] = useState("283.15");
  const railTankTrucksAssumptionLabels = ["RVP", "SF", "Oil Volume", "Tt"];
  const railTankInputs = [rvp, sf, oilVolume, tt];
  const railTankSetters = [setRvp, setSf, setOilVolume, setTt];
  // Dehydrators
  const [h, setH] = useState("8");
  const [d, setD] = useState("2");
  const [p2, setP2] = useState("1200");
  const [fractionFill, setFractionFill] = useState("0.7");
  const [frequency, setFrequency] = useState("1");
  const dehydratorsAssumptionLabels = [
    "H",
    "D",
    "P2",
    "Fraction Fill",
    "Frequency",
  ];
  const dehydratorInputs = [h, d, p2, fractionFill, frequency];
  const dehydratorSetters = [setH, setD, setP2, setFractionFill, setFrequency];
  // Compressor Seals
  const [numberOfHours, setNumberOfHours] = useState("8000");
  const [compressorAllocation, setCompressorAllocation] = useState("10");
  const compressorSelectOptions = ["High", "Low"];
  const [select, setSelect] = useState(compressorSelectOptions[0]);
  const compressorSealAssumptionLabels = [
    "Number Of Hours",
    "Allocation %",
    "Select",
  ];
  const compressorSealInputs = [numberOfHours, compressorAllocation, select];
  const compressorSealSetters = [
    setNumberOfHours,
    setCompressorAllocation,
    setSelect,
  ];
  const facilitySelectOptions = [compressorSelectOptions];
  // Pig Traps
  const [vv, setVv] = useState("3.693");
  const [ta, setTa] = useState("10");
  const [pa1, setPa1] = useState("260");
  const [pa2, setPa2] = useState("50");
  const [n, setN] = useState("1");
  const pigTrapAssumptionLabels = ["Vv", "Ta", "Pa, 1", "Pa,2", "N"];
  const pigTrapsInputs = [vv, ta, pa1, pa2, n];
  const pigTrapsSetters = [setVv, setTa, setPa1, setPa2, setN];

  const facilityDefaults = [
    ["34.6", "10"],
    ["5172", "0.05", "10"],
    ["40.5", "0.5", "100", "283.15"],
    ["8", "2", "1200", "0.7", "1"],
    ["8000", "10", "High"],
    ["3.693", "10", "260", "50", "1"],
  ];

  // Assumption info text
  const assumptionInfoTexts = [
    [
      "Lower Heating Value (MJ/m3)",
      "Allocation Percentage(%) corresponding to a particular Unique Well Identifier (UWI)",
    ],
    [
      "Emission rate for the device from table 28 or 29. (m3/hour)",
      "Amount of time in each month the starter is in use. (hours/month)",
      null,
    ],
    [
      "Reid vapour pressure of liquid. (kPa)",
      "Saturation factor in definition/unit to account for the method of loading.",
      "Monthly volume of the LVP product loaded. (m3)",
      "Truck tank operating temperature. (K)",
    ],
    [
      "Height of the dehydrator vessel. (m)",
      "Diameter of the dehydrator vessel. (m)",
      "Pressure of gas.(kPa(g))",
      "Fraction of the vessel that is filled with gas.",
      "Frequency of refilling. (cycles/month)",
    ],
    [
      "(hours/year)",
      "Allocation Percentage (%) corresponding to a particular Unique Well Identifier (UWI)",
      null,
    ],
    [
      "Total physical volume of equipment chambers between isolation valves being depressurized (including pig traps, pipes, compressors, and vessels). (m3)",
      "Initial temperature of gas at actual conditions in the equipment system before depressurization. (\u00B0C)",
      "Gauge pressure actual conditions in the equipment system before depressurization. (kPa(g))",
      "Gauge pressure actual conditions in the equipment system after depressurization. 0 if equipment is purged upon depressurization using inert gasses. (kPa(g))",
      "Number of blowdown events for the subject month and vessel.",
    ],
  ];
  const assumptionAdorments = [
    ["MJ/m3", null],
    ["m3/hour", "hrs/month", null],
    ["kPa", null, "m3", "K"],
    ["m", "m", "kPa(g)", null, "cycles/month"],
    ["hrs/year", null, null],
    ["m3", "\u00B0C", "kPa(g)", "kPa(g)", null],
  ];

  // Summary Inputs
  const [kgCH4PerMonth, setKgCH4PerMonth] = useState("");
  const [tco2e, setTco2e] = useState("");
  const summaryLabels = ["KgCH4/Month", "tC02e/Year"];

  useEffect(() => {
    getFacilitySubTotal(
      "facility",
      methaneMolPercentage,
      genericVent,
      fuelConsumption,
      fuelAllocation,
      lhv,
      engTurbineStart,
      er,
      t,
      engAllocation,
      numberOfRailTankTrucks,
      oilVolume,
      tt,
      rvp,
      sf,
      numberOfDehydrators,
      h,
      d,
      p2,
      fractionFill,
      frequency,
      numberOfCompressorSeals,
      numberOfHours,
      select,
      compressorAllocation,
      numberOfPigTraps,
      ta,
      pa1,
      pa2,
      vv,
      n,
      function (response) {
        const monthly = response[0];
        const yearly = response[1];
        setKgCH4PerMonth(monthly);
        setTco2e(yearly);
      }
    );
  }, [
    methaneMolPercentage,
    genericVent,
    fuelConsumption,
    fuelAllocation,
    lhv,
    engTurbineStart,
    er,
    t,
    engAllocation,
    numberOfRailTankTrucks,
    oilVolume,
    tt,
    rvp,
    sf,
    numberOfDehydrators,
    h,
    d,
    p2,
    fractionFill,
    frequency,
    numberOfCompressorSeals,
    numberOfHours,
    select,
    compressorAllocation,
    numberOfPigTraps,
    ta,
    pa1,
    pa2,
    vv,
    n,
  ]);

  const facilityEquipmentEmissionInputs = {
    genericVent: Number(genericVent),
    fuelConsumption: { lhv, fuelAllocation, fuelConsumption },
    engineTurbine: { engTurbineStart, er, t, allocation: engAllocation },
    railTankTrucks: { numberOfRailTankTrucks, rvp, sf, oilVolume, tt },
    dehydrators: { numberOfDehydrators, h, d, p2, fractionFill, frequency },
    compressorSeals: {
      numberOfCompressorSeals,
      hours: numberOfHours,
      allocation: compressorAllocation,
      select,
    },
    pigTraps: { numberOfPigTraps, vv, ta, pa1, pa2, n },
  };

  return {
    facilityEquipmentEmissionInputs,
    facilityEquipmentEmissionSection: (
      <div>
        <div className="white-font sectionTitle">
          Facility Transportation Emission
        </div>
        <CalculatorSection
          title="Facility Emission Factors/Inputs"
          inputs={[
            genericVent,
            fuelConsumption,
            engTurbineStart,
            numberOfRailTankTrucks,
            numberOfDehydrators,
            numberOfCompressorSeals,
            numberOfPigTraps,
          ]}
          inputValueSetters={[
            setGenericVent,
            setFuelConsumption,
            setEngTurbineStart,
            setNumberOfRailTankTrucks,
            setNumberOfDehydrators,
            setNumberOfCompressorSeals,
            setNumberOfPigTraps,
          ]}
          labels={inputLabels}
          adornments={adornments}
          infoTexts={facilityInfoTexts}
          // Summary
          summaryTitle="Equipment Emissions"
          summaryInputs={[kgCH4PerMonth, tco2e]}
          summaryLabels={summaryLabels}
          summaryValueSetters={[setKgCH4PerMonth, setTco2e]}
          // Assumptions
          assumptionTitle="Facility Equipment Assumptions"
          assumptionSubTitles={assumptionSubTitle}
          assumptionInputs={[
            fuelConsumptionInputs,
            engTurbineInputs,
            railTankInputs,
            dehydratorInputs,
            compressorSealInputs,
            pigTrapsInputs,
          ]}
          assumptionLabels={[
            fuelConsumptionAssumptionLabels,
            engTurbineAssumptionLabels,
            railTankTrucksAssumptionLabels,
            dehydratorsAssumptionLabels,
            compressorSealAssumptionLabels,
            pigTrapAssumptionLabels,
          ]}
          assumptionValueSetters={[
            fuelConsumptionSetters,
            engTurbineSetters,
            railTankSetters,
            dehydratorSetters,
            compressorSealSetters,
            pigTrapsSetters,
          ]}
          type="number"
          assumptionIsolateSelectOptions={facilitySelectOptions}
          assumptionInfoTexts={assumptionInfoTexts}
          assumptionAdorments={assumptionAdorments}
          assumptionDefaults={facilityDefaults}
        />
      </div>
    ),
  };
}
