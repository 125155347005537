import React, { useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
//Components
import CaseStudy from "../../../Components/CaseStudy";
import Footer from "../../../Components/Footer/Footer";

import "./caseStudyStyles.css";
export default function ResearchTechnology() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  const researchParagraphs = [
    "Carbon Management Canada’s Containment and Monitoring Institute (CaMI) has been instrumental in helping companies commercialize methane detection equipment in Canada. One vendor, in particular, sought to test its laser technology under harsh weather conditions on the Canadian Prairies, including summer heat waves and winter snow blizzards.",
    "The vendor chose to test its equipment at CaMI, which provided facilities, expert advice, and access to operating industrial emission sources in a real-world environment. The methane detection technology was tested on an area of 7.5 km2, and its performance was monitored and evaluated. The tests helped fine-tune and adapt the system’s peripheral equipment to specific features of the Canadian environment, ensuring maximum performance and nearly continuous monitoring. ",
    "The vendor gained valuable experience dealing with extreme weather conditions and improved its technology. The company noted that the Canadian industry tends to have a different way of viewing things and a different way of operating, making CaMI’s progressive and knowledgeable perspective a valuable asset.",
    "Overall, CaMI’s expertise in methane detection and its ability to provide a real-world testing environment have been instrumental in advancing the development and commercialization of new methane detection technologies in Canada.",
  ];

  return (
    <div className="caseStudyPage">
      <div className="caseStudyBackground">
        <CaseStudy
          title="Research and Testing of New Technologies"
          paragraphs={researchParagraphs}
          hasButton={true}
          className="caseStudy"
          buttonText={"Go Back"}
          buttonNavigateTo="/resources"
        />
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
