import React, { useContext, useEffect, useState } from "react";
import { Card, Grid, Checkbox } from "@mui/material";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
// Components
import NavBar from "../../Components/NavBar/NavBar";
import CompanyCard from "../../Components/CompanyCard";
import EmissionSolutions from "./EmissionSolutions";
import CaseStudy from "../../Components/CaseStudy";
import PurpleButton from "../../Components/Buttons/PurpleButton";
// Styles
import "./solutionStyles.css";

// API
import { filterCompanies } from "../../APIs/companyAPI";
import Footer from "../../Components/Footer/Footer";

const companyDirectorySummary =
  "Begin by opting for a principal search category, and if required, integrate a supplementary services category. This form will aid in identifying a company that can effectively support your emissions management initiatives.";
export default function SolutionPage() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);
  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  // Primary Category
  const [combustion, setCombustion] = useState(false);
  const [compressMethane, setCompressMethane] = useState(false);
  const [electricityGeneration, setElectricityGeneration] = useState(false);

  // Secondary Category
  const [detection, setDetection] = useState(false);
  const [research, setResearch] = useState(false);
  const [reporting, setReporting] = useState(false);
  const [management, setManagement] = useState(false);
  const [newTechnology, setNewTechnology] = useState(false);

  const handleCheckChange = (currentValue, updater) => {
    updater(!currentValue);
  };

  const [primaryPicks, setPrimaryPicks] = useState([]);
  const [secondaryPicks, setSecondaryPicks] = useState([]);

  useEffect(() => {
    const newPrimaryPicks = [];

    // Primary Pushes
    if (combustion) {
      newPrimaryPicks.push("Combustion");
    }

    if (compressMethane) {
      newPrimaryPicks.push("Compress Methane");
    }

    if (electricityGeneration) {
      newPrimaryPicks.push("Electricity Generation");
    }

    setPrimaryPicks(newPrimaryPicks);
  }, [combustion, compressMethane, electricityGeneration]);

  useEffect(() => {
    const newSecondaryPicks = [];
    // Secondary Pushes
    if (detection) {
      newSecondaryPicks.push(
        "Detection, Measurement, Quantification, Monitoring"
      );
    }
    if (research) {
      newSecondaryPicks.push("Research");
    }
    if (reporting) {
      newSecondaryPicks.push("Reporting");
    }
    if (management) {
      newSecondaryPicks.push("Management");
    }
    if (newTechnology) {
      newSecondaryPicks.push("New Technology/Redesign");
    }

    setSecondaryPicks(newSecondaryPicks);
  }, [detection, management, newTechnology, reporting, research]);

  const [filteredPrimaryResults, setFilteredPrimaryResults] = useState([]);

  useEffect(() => {
    handleCompanies();
  }, [primaryPicks, secondaryPicks]);

  useEffect(() => {
    if (primaryPicks.length === 0 && secondaryPicks === 0) {
      setFilteredPrimaryResults([]);
    }
  }, [primaryPicks.length, secondaryPicks]);

  const handleCompanies = () => {
    filterCompanies(primaryPicks, secondaryPicks, function (response) {
      setFilteredPrimaryResults(response);
    });
  };

  const getCompanySecondary = (thisCompanySecondaryCategories) => {
    let newSecondaryCatList = [];
    secondaryPicks.forEach(function (cat, index) {
      thisCompanySecondaryCategories.forEach(function (cat2, index) {
        if (cat === cat2) {
          newSecondaryCatList.push(cat);
        }
      });
    });

    return newSecondaryCatList;
  };

  return (
    <div className="solutionPage">
      <div className="solutionBackground">
        <div>
          <NavBar />
        </div>

        <div className="indent">
          <div>
            <EmissionSolutions />
          </div>
          <div className="indent white-font">
            <h1 className="caseTitle">Company Directory</h1>
            <p className="caseBody">{companyDirectorySummary}</p>
          </div>

          <p className="directoryDisclaimer">
            (PLEASE NOTE THAT THE COMPANIES INCLUDED HAVE BEEN CHOSEN
            IMPARTIALLY; IT IS RECOMMENDED THAT USERS STILL CONSULT THEIR
            CURRENT VENDORS REGARDING SPECIFIC PRODUCTS AND SERVICES TO
            GUARANTEE A THOROUGH ASSESSMENT.)
          </p>
          <div className="flex-align-horizontal-vertical ">
            {/* <button onClick={() => console.log(primaryPicks)}>primary</button>
                        <button onClick={() => console.log(secondaryPicks)}>secondary</button> */}
            {/* Search Card */}
            <div className="servicesCard">
              <Card
                sx={{ backgroundColor: "rgba(90,91,97, 0.8)", color: "white" }}
              >
                <Grid container>
                  {/* Primary Categories */}
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <div className="indent">
                      <h3>Product Solution (Primary)</h3>
                    </div>
                    <div className="indent">
                      <div>
                        <Checkbox
                          checked={combustion}
                          onChange={() =>
                            handleCheckChange(combustion, setCombustion)
                          }
                        />
                        <label>Combustion</label>
                      </div>
                      <div>
                        <Checkbox
                          checked={compressMethane}
                          onChange={() =>
                            handleCheckChange(
                              compressMethane,
                              setCompressMethane
                            )
                          }
                        />
                        <label>Compress Methane</label>
                      </div>
                      <div>
                        <Checkbox
                          checked={electricityGeneration}
                          onChange={() =>
                            handleCheckChange(
                              electricityGeneration,
                              setElectricityGeneration
                            )
                          }
                        />
                        <label>Electricity Generation</label>
                      </div>
                    </div>
                  </Grid>

                  {/* Secondary Categories */}
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <div className="indent">
                      <h3>Additional Services (Optional)</h3>
                      <div>
                        <div>
                          <Checkbox
                            checked={detection}
                            onChange={() =>
                              handleCheckChange(detection, setDetection)
                            }
                          />
                          <label>
                            Detection, Measurement, Quantification, Monitoring
                          </label>
                        </div>
                        <div>
                          <Checkbox
                            checked={research}
                            onChange={() =>
                              handleCheckChange(research, setResearch)
                            }
                          />
                          <label>Research</label>
                        </div>
                        <div>
                          <Checkbox
                            checked={reporting}
                            onChange={() =>
                              handleCheckChange(reporting, setReporting)
                            }
                          />
                          <label>Reporting</label>
                        </div>
                        <div>
                          <Checkbox
                            checked={management}
                            onChange={() =>
                              handleCheckChange(management, setManagement)
                            }
                          />
                          <label>Management</label>
                        </div>
                        <div>
                          <Checkbox
                            checked={newTechnology}
                            onChange={() =>
                              handleCheckChange(newTechnology, setNewTechnology)
                            }
                          />
                          <label>New Technology/Redesign</label>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </div>
            {/* Result Card */}
            <div className="directoryResultCard">
              <Card
                sx={{ backgroundColor: "rgba(90,91,97, 0.8)", color: "white" }}
              >
                <div>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className="indent">
                        <h3>List of Primary Search Category</h3>

                        {filteredPrimaryResults.length > 0 ? (
                          <ol>
                            {filteredPrimaryResults.map(function (
                              company,
                              index
                            ) {
                              return (
                                <li>
                                  <CompanyCard
                                    companyName={company.name}
                                    contactInformation={
                                      company.contactInformation
                                    }
                                    companyWebsite={company.link}
                                    companyLocation={company.location}
                                    companyLogo={company.logo}
                                    primaryCategories={
                                      company.primaryCategories
                                    }
                                    secondaryCategories={getCompanySecondary(
                                      company.secondaryCategories
                                    )}
                                    pageNumber={
                                      company.pageNumber
                                        ? company.pageNumber
                                        : null
                                    }
                                  />
                                </li>
                              );
                            })}
                          </ol>
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
