import axios from "axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

function LogOutBtn() {
  const { getLoggedIn } = useContext(AuthContext);

  const navigate = useNavigate();

  async function logOut() {
    //Actually delete the cookie
    await axios.get("https://api.carbonemissionscanada.com/auth/logout");
    // await axios.get(
    //   "https://mern-auth-template-tutorial.herokuapp.com/auth/logout"
    // );

    //Send the cookie back to the server to update the state.
    await getLoggedIn();
    navigate('/')
  }

  return <button onClick={logOut}>Log out</button>;
}

export default LogOutBtn;
