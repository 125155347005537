import React, { useState } from "react";
import { Checkbox, Grid, styled } from "@mui/material";

// Styles
import "../../../Components/Calculator/calculatorComponentStyles.css";

const BlueCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "rgb(6,122,139)",
  "&.Mui-checked": {
    color: "rgb(6,122,139)",
  },
}));

/**
 *
 * @returns A section that consists of checkboxes that is in charge of deciding which other sections should render.
 */
export default function CheckBoxSection() {
  const [tankCheck, setTankCheck] = useState(false);
  const [facilityCheck, setFacilityCheck] = useState(false);
  const [pneumaticCheck, setPneumaticCheck] = useState(false);
  const [glycolCheck, setGlycolCheck] = useState(false);
  const [wellCheck, setWellCheck] = useState(false);
  const [fugitiveCheck, setFugitiveCheck] = useState(false);

  const handleCheckChange = (value, updater) => {
    updater(!value);
  };
  const emissionSummaryCheck = {
    tankCheck,
    facilityCheck,
    pneumaticCheck,
    glycolCheck,
    wellCheck,
    fugitiveCheck,
  };
  return {
    emissionSummaryCheck,
    emissionSummarySection: (
      <div className="sectionCard">
        <div className=" sectionSubTitle">EMISSION SUMMARY SELECTION</div>

        <Grid container>
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <div className="allCheckboxContianer">
              <div className="checkBoxContainer">
                <BlueCheckbox
                  checked={tankCheck}
                  onChange={() => handleCheckChange(tankCheck, setTankCheck)}
                />
                <div>
                  <label style={{ fontSize: "1.2em" }}>
                    Tank Emission Calculations
                  </label>
                  <p style={{ fontSize: "1em" }}>
                    (Exclude Tank Breathing and Working Losses - US EPA 2006)
                  </p>
                </div>
              </div>
              <h2 style={{ fontWeight: "400", textDecoration: "underline" }}>
                ADDITIONAL EMISSION CALCULATIONS
              </h2>
              <div className="checkBoxContainer">
                <BlueCheckbox
                  checked={facilityCheck}
                  onChange={() =>
                    handleCheckChange(facilityCheck, setFacilityCheck)
                  }
                />
                <div>
                  <label style={{ fontSize: "1.2em" }}>
                    Facility Equipment Emission Calculations
                  </label>
                  <p style={{ fontSize: "0.7em" }}>
                    (Wellsite Pig Traps, Eng/Turbine Start-ups, Compressor Seal
                    & Rail/Tank Trucks)
                  </p>
                </div>
              </div>
              <div className="checkBoxContainer">
                <BlueCheckbox
                  checked={pneumaticCheck}
                  onChange={() =>
                    handleCheckChange(pneumaticCheck, setPneumaticCheck)
                  }
                />
                <div>
                  <label style={{ fontSize: "1.2em" }}>
                    Pneumatic Instrument Site Calculations
                  </label>
                  <p style={{ fontSize: "0.7em" }}>
                    (Level, Positioner, Transducers, Pressure & Generic
                    Controllers & Generic Pumps)
                  </p>
                </div>
              </div>
              <div className="checkBoxContainer">
                <BlueCheckbox
                  checked={glycolCheck}
                  onChange={() =>
                    handleCheckChange(glycolCheck, setGlycolCheck)
                  }
                />
                <div>
                  <label style={{ fontSize: "1.2em" }}>
                    Glycol Dehydrators Site Calculations
                  </label>
                  <p style={{ fontSize: "0.7em" }}>
                    (Well-site glycol dehydrator)
                  </p>
                </div>
              </div>
              <div className="checkBoxContainer">
                <BlueCheckbox
                  checked={wellCheck}
                  onChange={() => handleCheckChange(wellCheck, setWellCheck)}
                />
                <div>
                  <label style={{ fontSize: "1.2em" }}>
                    Well Emissions Site Calculations
                  </label>
                  <p style={{ fontSize: "0.7em" }}>
                    (Well Unloading Emissions)
                  </p>
                </div>
              </div>
              <div className="checkBoxContainer">
                <BlueCheckbox
                  checked={fugitiveCheck}
                  onChange={() =>
                    handleCheckChange(fugitiveCheck, setFugitiveCheck)
                  }
                />
                <div>
                  <label style={{ fontSize: "1.2em" }}>
                    Fugitive Emission Calculations
                  </label>
                  <p style={{ fontSize: "0.7em" }}>
                    (Facility, Gathering, Battery and Site Fugitive Emissions)
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div style={{ width: "40em" }}></div>
          </Grid>
        </Grid>
      </div>
    ),
  };
}
