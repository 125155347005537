import React, { useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
//Components
import CaseStudy from "../../../Components/CaseStudy";
import Footer from "../../../Components/Footer/Footer";

export default function ChemicalPumps() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  const researchParagraphs = [
    "According to the ST60B 2021 data, chemical pumps were the third-largest source of methane emissions. In response, the PTAC Systemic Third-Party Validation (STV) project collaborated with producers to conduct extensive testing, aiming to replace gas-activated chemical pumps with electrically driven pumps powered by solar energy or grid-supplied electricity, where available. These systems were permanently installed, and producers offered insights on operability, maintenance, and suggested improvements that were addressed by equipment vendors. The advantages of features such as multi-head pumps, remote monitoring, and control of chemical dosing were also considered, among other factors. In most cases within this group, the pumps were the primary methane sources at the test sites.",
    "The chart below presents a range of abatement costs for various types of pumps and available power sources, illustrating the potential for cost-effective solutions in reducing methane emissions from chemical pumps. By employing electrically driven pumps powered by renewable or grid energy, the industry can significantly reduce its methane emissions while optimizing operations and embracing more sustainable practices.",
  ];
  return (
    <div className="caseStudyPage">
      <div className="caseStudyBackground">
        <CaseStudy
          title="Chemical Pumps"
          paragraphs={researchParagraphs}
          hasButton={true}
          className="caseStudy"
          buttonText={"Go Back"}
          buttonNavigateTo="/resources"
          bigImage={"chemicalPumpBig.png"}
          smallImage={"chemicalPumpSmall.jpg"}
        />
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
