import axios from "axios";

/**
 * @description - Should  be connected to a button as an onClick for users to download a PDF. The PDF will contain information specific to their form inputs.
 * @param {Object} inputs - An nested object that contains all values that contribute to calculation of tCO2e.
 */
const generatePdf = function (inputs,location, callback) {
  /* Return the axios.post call so that we can chain .then() outside of this api.
    Example
        setIsLoading(true); // set loading state to true
        generatePdf(inputs).then(() => {
        setIsLoading(false);// set loading state back to false when download is complete
    */
  return axios
    .post(
      "https://api.carbonemissionscanada.com/calculator/generate-pdf",
      { inputs, location},
      {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "emissionsSources.pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => console.log(error));
};

/**
 * @description - The API call  that handles the big calculation of returning a monthly, yearly, and remaining tCO2e.
 * @param {Object} inputs - Should  be connected to a button as an onClick for users to download a PDF. The PDF will contain information specific to their form inputs.
 * @param {Function} callback - A callback function that handles the response.
 * @returns An array of three items. monthly tCO2e, yearly tC02e, and Remaining Asset Life. (In order)
 */
const sendInputs = function (inputs, callback) {
  axios
    .post("https://api.carbonemissionscanada.com/calculator", {
      inputs
    })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) =>
      console.log("Something went wrong with send inputs to database")
    );
};

/**
 * Sub-Total routes
 * @description - A calculation that takes in a section and its required fields to return an array of two items.
 * @param {String} section - First word of a section (all lowercase). Determines which equation to use.
 * @param {Function} callback - Callback function that handles what to do with the response.
 * @returns An array of two elements. kgCH4/Month and tCO2e/year (In order)
 */
const getTankSubTotal = function (
  section,
  methaneMol,
  maxTankCapacity,
  turnoverPerMonth,
  numberOfTanks,
  gaugePressureTest,
  gaugePressureTank,
  callback
) {
  axios
    .post("https://api.carbonemissionscanada.com/calculator/subtotal", {
      section,
      inputs: {
        methaneMol,
        maxTankCapacity,
        turnoverPerMonth,
        numberOfTanks,
        gaugePressureTest,
        gaugePressureTank,
      },
    })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) =>
      console.log("Something went wrong with send inputs to database")
    );
};

const getFacilitySubTotal = function (
  section,
  methaneMol,
  monthlyGenericVentCh4Kg,
  fuelConsumption,
  fuelPlantAlloc,
  lhvMj,
  numberOfEngineOrTurbineStart,
  er,
  t,
  turbineAllocation,
  numberOfTrucks,
  oilVolume,
  tt,
  rvp,
  sf,
  numberOfDehydrators,
  h,
  d,
  p2,
  fractionFill,
  frequency,
  numberOfCompressorSeals,
  compressorSealHours,
  sealSelect,
  compressorWellAlloc,
  numberOfPigTraps,
  ta,
  pa1,
  pa2,
  vv,
  n,
  callback
) {
  axios
    .post("https://api.carbonemissionscanada.com/calculator/subtotal", {
      section,
      inputs: {
        methaneMol,
        monthlyGenericVentCh4Kg,
        fuelConsumption,
        fuelPlantAlloc,
        lhvMj,
        numberOfEngineOrTurbineStart,
        er,
        t,
        turbineAllocation,
        numberOfTrucks,
        oilVolume,
        tt,
        rvp,
        sf,
        numberOfDehydrators,
        h,
        d,
        p2,
        fractionFill,
        frequency,
        numberOfCompressorSeals,
        compressorSealHours,
        sealSelect,
        compressorWellAlloc,
        numberOfPigTraps,
        ta,
        pa1,
        pa2,
        vv,
        n,
      },
    })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) =>
      console.log("Something went wrong with send inputs to database")
    );
};

const getPneumaticSubTotal = function (
  section,
  methaneMol,
  numberOfLevelControllers,
  numberOfPositioners,
  numberOfPressureControllers,
  numberOfTransducers,
  numberOfGenericInstruments,
  numberOfGenericPistonPumps,
  numberOfGenericDiaphragmPumps,
  levelControllerHours,
  positionerHours,
  pressureControllerHours,
  transducerHours,
  genericInstrumentHours,
  genericPistonPumpHours,
  genericDiaphragmPumpHours,
  callback
) {
  axios
    .post("https://api.carbonemissionscanada.com/calculator/subtotal", {
      section,
      inputs: {
        methaneMol,
        numberOfLevelControllers,
        numberOfPositioners,
        numberOfPressureControllers,
        numberOfTransducers,
        numberOfGenericInstruments,
        numberOfGenericPistonPumps,
        numberOfGenericDiaphragmPumps,
        levelControllerHours,
        positionerHours,
        pressureControllerHours,
        transducerHours,
        genericInstrumentHours,
        genericPistonPumpHours,
        genericDiaphragmPumpHours,
      },
    })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) =>
      console.log("Something went wrong with send inputs to database")
    );
};

const getGlycolSubTotal = function (
  section,
  methaneMol,
  numberOfGlycolDehydrators,
  glycolWellAllocation,
  glycolSelect,
  callback
) {
  axios
    .post("https://api.carbonemissionscanada.com/calculator/subtotal", {
      section,
      inputs: {
        methaneMol,
        numberOfGlycolDehydrators,
        glycolWellAllocation,
        glycolSelect,
      },
    })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) =>
      console.log("Something went wrong with send inputs to database")
    );
};

const getWellSubTotal = function (
  section,
  methaneMol,
  eventsPerMonth,
  casingDiameter,
  wellDepth,
  gaugePressure,
  hoursOpen,
  prodRate,
  callback
) {
  axios
    .post("https://api.carbonemissionscanada.com/calculator/subtotal", {
      section,
      inputs: {
        methaneMol,
        eventsPerMonth,
        casingDiameter,
        wellDepth,
        gaugePressure,
        hoursOpen,
        prodRate,
      },
    })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) =>
      console.log("Something went wrong with send inputs to database")
    );
};

const getFugitiveSubTotal = function (
  section,
  gasValves,
  oilValves,
  gasConnectors,
  oilConnectors,
  pumpSeals,
  pressureReliefs,
  openEndedLines,
  callback
) {
  axios
    .post("https://api.carbonemissionscanada.com/calculator/subtotal", {
      section,
      inputs: {
        gasValves,
        oilValves,
        gasConnectors,
        oilConnectors,
        pumpSeals,
        pressureReliefs,
        openEndedLines,
      },
    })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) =>
      console.log("Something went wrong with send inputs to database")
    );
};

export {
  sendInputs,
  generatePdf,
  getTankSubTotal,
  getFacilitySubTotal,
  getPneumaticSubTotal,
  getGlycolSubTotal,
  getWellSubTotal,
  getFugitiveSubTotal,
};
