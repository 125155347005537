import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
// Components
import PurpleButton from "./Buttons/PurpleButton";

// Styles
import "./componentStyles.css";

/**
 *
 * @param {String} title - Title of the case study. Main header.
 * @param {String Array} paragraphs - List of paragraphs that should be rendered.
 * @param {Boolean} hasButton - True/false that dictates if this page should include purple button.
 * @param {String} buttonText - The text inside the button if the page should include a purple button.
 * @param {String} buttonNavigateTo - Absolutey path/route that the onClick of the button should navigate to.
 * @returns A page that includes a title, paragraph(s) and an (optional) button.
 */
export default function CaseStudy({
  title,
  paragraphs,
  hasButton,
  buttonText,
  buttonNavigateTo,
  bigImage,
  smallImage,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="caseStudyContainer">
      <div className="indent">
        <h1 className="white-font  caseTitle">{title}</h1>
        <div className="white-font indent font-weight-300 caseBody justifyParagraph">
          {paragraphs
            ? paragraphs.map(function (paragraph, index) {
                return <p key={title + index}>{paragraph}</p>;
              })
            : null}
        </div>
      </div>
      <div className="caseImages">
        <div className="bigImage">
          {bigImage ? (
            <Box
              component="img"
              sx={{
                p: "1em",

                width: 500,
                maxWidth: { xs: 350, sm: 500, md: 500 },
                maxHeight: { xs: 300, sm: 500, md: 400 },
                borderRadius: "30px",
              }}
              alt="PTAC Logo."
              src={"/images/caseStudies/innerImages/" + bigImage}
            />
          ) : null}
        </div>
        {smallImage ? (
          <div className="smallImage">
            <Box
              component="img"
              sx={{
                p: "1em",

                width: 500,
                maxWidth: { xs: 300, sm: 300, md: 300 },
                maxHeight: { xs: 300, sm: 300, md: 300 },
                borderRadius: "30px",
              }}
              alt="PTAC Logo."
              src={"/images/caseStudies/innerImages/" + smallImage}
            />
          </div>
        ) : null}
      </div>
      {hasButton ? (
        <div className="caseStudyButton flex-align-horizontal-vertical">
          <PurpleButton
            text={buttonText}
            onClickHandler={() => navigate("/resources")}
          />
        </div>
      ) : null}
    </div>
  );
}
