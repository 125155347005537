import React, {createContext, useEffect, useState} from 'react'
import axios from 'axios';
const AuthContext = createContext();

function AuthContextProvider(props) {
  const [loggedIn, setLoggedIn] = useState(undefined);
  const [loading, setLoading] = useState(true); // Add loading state

  async function getLoggedIn(){
    const loggedInRes = await axios.get("https://api.carbonemissionscanada.com/auth/loggedIn");
    setLoggedIn(loggedInRes.data);
    setLoading(false); // Set loading to false once the response is received
  }

  useEffect(() => {
    getLoggedIn();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Render a loading indicator
  }

  return (
    <AuthContext.Provider value={{loggedIn, getLoggedIn}}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContext;
export {AuthContextProvider};