import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from "@mui/material";
import { deleteUser } from "../../APIs/databaseAPI";
export default function DeleteAccountDialog({
  open,
  handleClickOpen,
  handleClose,
}) {
  const handleDeleteAccount = () => {
    handleClose();
    deleteUser(function (response) {
      if (response.status === 200) {
        alert("User deleted successfully");
        window.location.href = "https://carbonemissionscanada.com/";
      } else {
        alert("Error deleting user");
      }
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete your account?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Clicking "I'm sure" will permanently delete your account{" "}
            <span style={{ fontWeight: "bolder" }}>forever</span>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleDeleteAccount} autoFocus>
            I'm sure.
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
