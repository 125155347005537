import React, { useState } from "react";

// Components
import CaseStudy from "../../Components/CaseStudy";
import {
  Grid,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import PurpleButton from "../../Components/Buttons/PurpleButton";

// API
import { getTankEmissionSolutions } from "../../APIs/solutionAPI";

const tankParagraph = [
  "The Tank Emission Solution Selection Form is a resource designed to uncover emission management strategies in the oil and gas sector. Once submitted, the system evaluates the specifics, factoring in the site's unique characteristics, operational aspects, and project type (brownfield or greenfield). The form takes into account elements such as well site operations, support equipment, cutting-edge technologies, and virtual pipelines. This tool aims to address the industry's various challenges while encouraging eco-friendly, data-driven emission reduction methods. Please note that all recommendations from this form should be carefully examined, and appropriate internal engineering evaluations should be carried out. Relying on this form should not supplant a company's internal due diligence procedures.",
];

const BlueCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "rgb(6,122,139)",
  "&.Mui-checked": {
    color: "rgb(6,122,139)",
  },
}));

export default function EmissionSolutions() {
  // Site Characteristics
  const [siteCharacteristics, setSiteCharacteristics] = useState({
    casingGasManagement: { check: false, value: "Casing gas management" },
    casingGasProduction: {
      check: false,
      value: "Casing gas production at the wellhead",
    },
    centralPlantPipeLineDestination: {
      check: false,
      value: "Central plant or pipeline destination",
    },
    centralizingGasGatheringLine: {
      check: false,
      value: "Centralizing Gas Gathering Line",
    },
    comparisonMultiWellSingleWell: {
      check: false,
      value: "Comparison of multi-well oil batteries vs. single-well batteries",
    },
    complianceEnvironmentRegulations: {
      check: false,
      value: "Compliance with environmental regulations",
    },
    complianceRegulations: {
      check: false,
      value: "Compliance with regulation",
    },
    complianceSafetyRegulations: {
      check: false,
      value: "Compliance with safety regulations",
    },
    directCompressor: {
      check: false,
      value: "Direct compressor power with recovered waste gas",
    },
    methaneMonitoring: {
      check: false,
      value: "Ease of methane monitoring and mitigation at multi-well sites",
    },
    eliminationCasingGas: {
      check: false,
      value: "Elimination of casing gas venting",
    },
    eliminationUnnecessaryTanks: {
      check: false,
      value: "Elimination of unnecessary tanks",
    },
    enchancementEnergyEfficiency: {
      check: false,
      value: "Enhancement of energy efficiency",
    },
    environmentFootprintReduction: {
      check: false,
      value: "Environmental footprint reduction",
    },
    evaluationAlternativeEnergy: {
      check: false,
      value: "Evaluation of alternative energy technologies",
    },
    existenceMultipleProoducibleZones: {
      check: false,
      value: "Existence of multiple producible zones",
    },
    gasCompressionTechniques: {
      check: false,
      value: "Gas compression techniques (e.g., VRUs, GTG)",
    },
    gasProductionConservation: {
      check: false,
      value: "Gas production conservation",
    },
    gasToPowerTechnologies: {
      check: false,
      value: "Gas-to-power technologies, including combustion",
    },
    gasToPowerUnitUtilization: {
      check: false,
      value: "Gas-to-power unit utilization",
    },
    implementationOfInnovativeTechnologies: {
      check: false,
      value: "Implementation of innovative technologies",
    },
    implementationOfMitigationMeasures: {
      check: false,
      value: "Implementation of mitigation measures",
    },
    improvedOilProduction: {
      check: false,
      value: "Improved oil production through optimized well operations",
    },
    integrationOfSafetyMeasures: {
      check: false,
      value: "Integration of safety measures with environmental strategies",
    },
    integrationWithExistingInfrastructure: {
      check: false,
      value: "Integration with existing infrastructure",
    },
    interimEmissionReductionSolutions: {
      check: false,
      value: "Interim emission reduction solutions",
    },
    methaneConversionToEfficientPower: {
      check: false,
      value: "Methane conversion to efficient power",
    },
    methaneEmissionReductionGoals: {
      check: false,
      value: "Methane emission reduction goals",
    },
    minimizationOfEmissions: {
      check: false,
      value: "Minimization of emissions",
    },
    minimizingOverallImpact: {
      check: false,
      value: "Minimizing overall impact",
    },
    multiphasePump: { check: false, value: "Multiphase pump" },
    onSiteElectricityAvailability: {
      check: false,
      value: "On-site electricity availability",
    },
    operationalAndMaintenanceSafetyOfMultiWellVsSingleWellSites: {
      check: false,
      value:
        "Operational & maintenance safety of multi-well vs. single-well sites",
    },
    pipeliningLiquidsForCentralizedGasCapture: {
      check: false,
      value: "Pipelining liquids for centralized gas capture",
    },
    presenceOfMultipleOilAndGasReserves: {
      check: false,
      value: "Presence of multiple oil and gas reserves",
    },
    productionDeclineConsiderations: {
      check: false,
      value: "Production decline considerations",
    },
    recoveringWasteGas: {
      check: false,
      value: "Recovering waste gas (i.e. Disposal, Fuel systems, or Transport)",
    },
    reductionInTheNumberOfOilTanksForEnhancedSafety: {
      check: false,
      value: "Reduction in the number of oil tanks for enhanced safety",
    },
    replacementOfHighEmissionEnergySources: {
      check: false,
      value: "Replacement of high-emission energy sources",
    },
    riskAssessmentAndManagementForMultiWellInstallations: {
      check: false,
      value: "Risk assessment and management for multi-well installations",
    },
    safetyAdvantagesOfMultiWellFacilities: {
      check: false,
      value: "Safety advantages of multi-well facilities",
    },
    sealingTheAnnularSpaceBetweenCasingAndProductionTubing: {
      check: false,
      value: "Sealing the annular space between casing and production tubing",
    },
    surfaceRestrictionsAndLimitations: {
      check: false,
      value: "Surface restrictions and limitations",
    },
    utilizationOfMultiWellPadsAndBatteries: {
      check: false,
      value: "Utilization of multi-well pads and batteries",
    },
    wasteGasTransportationMethod: {
      check: false,
      value: "Waste gas transportation method",
    },
    wasteGasUtilizationForEnergyGeneration: {
      check: false,
      value: "Waste gas utilization for energy generation",
    },
    wellOptimizationForEmissionReduction: {
      check: false,
      value: "Well optimization for emission reduction",
    },
    wellSitesWithoutPowerOrElectrification: {
      check: false,
      value: "Well sites without power or electrification",
    },
  });
  // Holds an array of the any "checked" options in site characteristics as strings (EXACT NAME MATCH TO LABEL)
  const checkedSiteCharacteristicsValues = Object.values(siteCharacteristics)
    .filter((obj) => obj.check)
    .map((obj) => obj.value);

  // Handles on change on checkboxes and site characteristic object.
  const handleSiteCharacteristicChange = (key) => (event) => {
    const maxNumberOfSelections = 24;
    if (checkedSiteCharacteristicsValues.length < maxNumberOfSelections) {
      setSiteCharacteristics({
        ...siteCharacteristics,
        [key]: { ...siteCharacteristics[key], check: event.target.checked },
      });
    } else {
      if (event.target.checked === false) {
        setSiteCharacteristics({
          ...siteCharacteristics,
          [key]: { ...siteCharacteristics[key], check: event.target.checked },
        });
      }
    }
  };

  // Well Site Operations
  const [wellSiteOperations, setWellSiteOperations] = useState({
    backupEnclosedCombustors: {
      check: false,
      value: "Backup for enclosed combustors",
    },
    centralizedFacilityNo: { check: false, value: "Centralized Facility (NO)" },
    centralizedFacilityYes: {
      check: false,
      value: "Centralized Facility (YES)",
    },
    compressionReninjection: {
      check: false,
      value: "Compression and Reinjection of Casing Gas",
    },
    compressorEmissions: { check: false, value: "Compressor Emissions" },
    easyDeployment: { check: false, value: "Easy deployment and transport" },
    exceptionalDestruction: {
      check: false,
      value: "Exceptional destruction efficiencies for various waste gases",
    },
    flexibiliyAndEfficiency: {
      check: false,
      value: "Flexibility and efficiency",
    },
    highMethaneDestruction: {
      check: false,
      value: "High methane destruction efficiencies",
    },
    impactCombustion: {
      check: false,
      value: "Impact of combustion on fugitive emissions",
    },
    lowerPressureVenting: { check: false, value: "Lower Pressure Venting" },
    noCombustionDevices: { check: false, value: "No Combustion devices" },
    oilTransportation: {
      check: false,
      value: "Oil Transportation/Low pressure",
    },
    operationalOpportunities: {
      check: false,
      value: "Operational Opportunities",
    },
    parallelInstallation: {
      check: false,
      value: "Parallel installation capabilities",
    },
    portablilityAdaptability: {
      check: false,
      value: "Portability and adaptability for various site needs",
    },
    productionCasingVents: { check: false, value: "Production Casing Vents" },
    rangeAvailableModels: {
      check: false,
      value: "Range of available models suitable for low flow rates",
    },
    redundancyOfEquipment: { check: false, value: "Redundancy of Equipment" },
    vruInstallationNo: {
      check: false,
      value: "VRU Installation and integration (NO)",
    },
    vruInstallationYes: {
      check: false,
      value: "VRU Installation and integration (YES)",
    },
    wasteHeatRecovery: { check: false, value: "Waste Heat Recovery" },
  });

  // Holds an array of the any "checked" options in well site operations as strings (EXACT NAME MATCH TO LABEL)
  const checkedWellSiteOperationsValues = Object.values(wellSiteOperations)
    .filter((obj) => obj.check)
    .map((obj) => obj.value);

  // Handles on change on checkboxes and well site operations object.
  const handleWellSiteOperationsChange = (key) => (event) => {
    const maxNumberOfSelections = 11;
    if (checkedWellSiteOperationsValues.length < maxNumberOfSelections) {
      setWellSiteOperations({
        ...wellSiteOperations,
        [key]: { ...wellSiteOperations[key], check: event.target.checked },
      });
    } else {
      if (event.target.checked === false) {
        setWellSiteOperations({
          ...wellSiteOperations,
          [key]: { ...wellSiteOperations[key], check: event.target.checked },
        });
      }
    }
  };

  // Supporting Equipment and Additional Technologies
  const [equipmentAndTechnologies, setEquipmentAndTechnologies] = useState({
    adoptionOfRealTimeAutonomousOptimizers: {
      check: false,
      value: "Adoption of real-time autonomous optimizers",
    },
    anomalyDetectionCapabilities: {
      check: false,
      value: "Anomaly detection capabilities",
    },
    applicationOfArtificialIntelligenceInControlSystems: {
      check: false,
      value: "Application of artificial intelligence (AI) in control systems",
    },
    capabilityToHandleVentingDueToEquipmentFailures: {
      check: false,
      value: "Capability to handle venting due to equipment failures",
    },
    capabilityToTreatVentingFromReliefValves: {
      check: false,
      value: "Capability to treat venting from relief valves",
    },
    combustionTechnologies: { check: false, value: "Combustion technologies" },
    equipmentSolutions: { check: false, value: "Equipment Solutions" },
    gridElectrificationNo: { check: false, value: "Grid electrification (NO)" },
    gridElectrificationYes: {
      check: false,
      value: "Grid electrification",
    },
    implementationOfDesignAndConstructionBestPractices: {
      check: false,
      value: "Implementation of design and construction best practices",
    },
    implementationOfDigitalControllers: {
      check: false,
      value: "Implementation of digital controllers",
    },
    implementationOfMonitoringSystemsAndRedundantEquipmentUsage: {
      check: false,
      value:
        "Implementation of monitoring systems and redundant equipment usage",
    },
    implementationOfRealTimeMonitoringSystems: {
      check: false,
      value: "Implementation of real-time monitoring systems",
    },
    implementationOfUndergroundDisposalAndCombustion: {
      check: false,
      value: "Implementation of underground disposal and combustion",
    },
    implementingAdvancedControlSystems: {
      check: false,
      value: "Implementing advanced control systems",
    },
    implementingStrategyTechnologies: {
      check: false,
      value: "Implementing Strategy technologies",
    },
    integrationOfDigitalSolutions: {
      check: false,
      value: "Integration of digital solutions",
    },
    integrationOfMachineLearningInMonitoringSystems: {
      check: false,
      value: "Integration of machine learning in monitoring systems",
    },
    minimizationOfEmissionsThroughMonitoringAndControls: {
      check: false,
      value: "Minimization of emissions through monitoring and controls",
    },
    monitoringStorageTankPressures: {
      check: false,
      value: "Monitoring storage tank pressures",
    },
    monitoringStorageTankTemperatures: {
      check: false,
      value: "Monitoring storage tank temperatures",
    },
    operationalIssues: { check: false, value: "Operational Issues" },
    optimizationOfOperationsThroughMonitoringAndControlSystems: {
      check: false,
      value:
        "Optimization of operations through monitoring and control systems",
    },
    pressurizedStorageOfCondensate: {
      check: false,
      value: "Pressurized Storage of Condensate",
    },
    trackingStorageTankLevels: {
      check: false,
      value: "Tracking storage tank levels",
    },
    utilizationOfDigitalTechnology: {
      check: false,
      value: "Utilization of digital technology",
    },
    utilizationOfGridElectrification: {
      check: false,
      value: "Utilization of grid electrification",
    },
  });

  // Holds an array of the any "checked" options in equipment and technologies as strings (EXACT NAME MATCH TO LABEL)
  const checkedEquipmentAndTechnologiesValues = Object.values(
    equipmentAndTechnologies
  )
    .filter((obj) => obj.check)
    .map((obj) => obj.value);

  // Handles on change on checkboxes and equipment and technologies object.
  const handleEquipmentAndTechnologiesChange = (key) => (event) => {
    const maxNumberOfSelections = 12;
    if (checkedEquipmentAndTechnologiesValues.length < maxNumberOfSelections) {
      setEquipmentAndTechnologies({
        ...equipmentAndTechnologies,
        [key]: {
          ...equipmentAndTechnologies[key],
          check: event.target.checked,
        },
      });
    } else {
      if (event.target.checked === false) {
        setEquipmentAndTechnologies({
          ...equipmentAndTechnologies,
          [key]: {
            ...equipmentAndTechnologies[key],
            check: event.target.checked,
          },
        });
      }
    }
  };

  // Virtual Pipeline
  const [virtualPipeline, setVirtualPipeline] = useState({
    virtualPipelines: { check: false, value: "Virtual Pipelines" },
    alignedOverallPreventionStategy: {
      check: false,
      value: "Aligned with the overall prevention strategy",
    },
  });

  const checkedVirtualPipelinesValues = Object.values(virtualPipeline)
    .filter((obj) => obj.check)
    .map((obj) => obj.value);

  // Handles radio button on change for virtual pipeline
  const handleVirtualPipelineChange = (key) => (event) => {
    const maxNumberOfSelections = 1;
    if (checkedVirtualPipelinesValues.length < maxNumberOfSelections) {
      setVirtualPipeline({
        ...virtualPipeline,
        [key]: { ...virtualPipeline[key], check: event.target.checked },
      });
    } else {
      if (event.target.checked === false) {
        setVirtualPipeline({
          ...virtualPipeline,
          [key]: { ...virtualPipeline[key], check: event.target.checked },
        });
      }
    }
  };

  const tankEmissionSelections = {
    siteCharacteristics: checkedSiteCharacteristicsValues,
    wellSiteOperations: checkedWellSiteOperationsValues,
    supportingEquipmentAndAdditionalTechnology:
      checkedEquipmentAndTechnologiesValues,
    virtualPipeline: checkedVirtualPipelinesValues,
  };

  const [resultSolutions, setResultSolutions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleGettingTankEmissionSolutions = () => {
    setIsLoading(true); // set loading state to true
    getTankEmissionSolutions(tankEmissionSelections, function (response) {
      setResultSolutions(response);
    }).then(() => {
      setIsLoading(false); // set loading state back to false when download is complete;
    });
  };

  return (
    <div className="TESTING">
      <CaseStudy
        title="Tank Emission Solutions"
        paragraphs={tankParagraph}
        hasButton={false}
        className="caseStudy"
      />
      <div
        style={{ marginTop: "-5%" }}
        className="flex-align-horizontal-vertical white-font"
      >
        {/* <button onClick={() => console.log(checkedSiteCharacteristicsValues)}>show site</button>
                <button onClick={() => console.log(checkedWellSiteOperationsValues)}>show well</button>
                <button onClick={() => console.log(checkedEquipmentAndTechnologiesValues)}>show equipmeent</button>
                <button onClick={() => console.log(virtualPipeline)}>show virtual</button> */}

        <div className="emissionSolutionContainer ">
          <div className="checkboxTitle">
            <h3>
              Site Characteristics{" "}
              <span style={{ fontSize: "0.7em" }}>*Maximum 24 allowed</span>
            </h3>
          </div>
          {/* Site Characteristics */}
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="checkboxFlex">
                {Object.keys(siteCharacteristics)
                  .slice(0, Object.keys(siteCharacteristics).length / 2)
                  .map((key) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <BlueCheckbox
                          checked={siteCharacteristics[key].check}
                          onChange={handleSiteCharacteristicChange(key)}
                        />
                      }
                      label={siteCharacteristics[key].value}
                    />
                  ))}
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="checkboxFlex">
                {Object.keys(siteCharacteristics)
                  .slice(Object.keys(siteCharacteristics).length / 2)
                  .map((key) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <BlueCheckbox
                          checked={siteCharacteristics[key].check}
                          onChange={handleSiteCharacteristicChange(key)}
                        />
                      }
                      label={siteCharacteristics[key].value}
                    />
                  ))}
              </div>
            </Grid>
          </Grid>
          {/* Well Site Operations */}
          <div className="checkboxTitle">
            <h3>
              Well Site Operations{" "}
              <span style={{ fontSize: "0.7em" }}>*Maximum 11 allowed</span>
            </h3>
          </div>

          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="checkboxFlex">
                {Object.keys(wellSiteOperations)
                  .slice(0, Object.keys(wellSiteOperations).length / 2)
                  .map((key) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <BlueCheckbox
                          checked={wellSiteOperations[key].check}
                          onChange={handleWellSiteOperationsChange(key)}
                        />
                      }
                      label={wellSiteOperations[key].value}
                    />
                  ))}
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="checkboxFlex">
                {Object.keys(wellSiteOperations)
                  .slice(Object.keys(wellSiteOperations).length / 2)
                  .map((key) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <BlueCheckbox
                          checked={wellSiteOperations[key].check}
                          onChange={handleWellSiteOperationsChange(key)}
                        />
                      }
                      label={wellSiteOperations[key].value}
                    />
                  ))}
              </div>
            </Grid>
          </Grid>
          {/* Supporting Equipment and Additional Technologies */}
          <div className="checkboxTitle">
            <h3>
              Support Equipment and Additional Technology{" "}
              <span style={{ fontSize: "0.7em" }}>*Maximum 12 allowed</span>
            </h3>
          </div>

          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="checkboxFlex">
                {Object.keys(equipmentAndTechnologies)
                  .slice(0, Object.keys(equipmentAndTechnologies).length / 2)
                  .map((key) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <BlueCheckbox
                          checked={equipmentAndTechnologies[key].check}
                          onChange={handleEquipmentAndTechnologiesChange(key)}
                        />
                      }
                      label={equipmentAndTechnologies[key].value}
                    />
                  ))}
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="checkboxFlex">
                {Object.keys(equipmentAndTechnologies)
                  .slice(Object.keys(equipmentAndTechnologies).length / 2)
                  .map((key) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <BlueCheckbox
                          checked={equipmentAndTechnologies[key].check}
                          onChange={handleEquipmentAndTechnologiesChange(key)}
                        />
                      }
                      label={equipmentAndTechnologies[key].value}
                    />
                  ))}
              </div>
            </Grid>
          </Grid>

          {/*Virtual Pipeline */}
          <div className="checkboxVirtualTitle">
            <h3>
              Virtual Pipeline{" "}
              <span style={{ fontSize: "0.7em" }}>*Maximum 1 allowed</span>
            </h3>
          </div>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="checkboxFlex">
                {Object.keys(virtualPipeline)
                  .slice(0, Object.keys(virtualPipeline).length / 2)
                  .map((key) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <BlueCheckbox
                          checked={virtualPipeline[key].check}
                          onChange={handleVirtualPipelineChange(key)}
                        />
                      }
                      label={virtualPipeline[key].value}
                    />
                  ))}
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="checkboxFlex">
                {Object.keys(virtualPipeline)
                  .slice(Object.keys(virtualPipeline).length / 2)
                  .map((key) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <BlueCheckbox
                          checked={virtualPipeline[key].check}
                          onChange={handleVirtualPipelineChange(key)}
                        />
                      }
                      label={virtualPipeline[key].value}
                    />
                  ))}
              </div>
            </Grid>
          </Grid>
        </div>
        {/* Render Backdrop if isLoading is true */}
        <Backdrop open={isLoading} style={{ zIndex: 1 }}>
          <CircularProgress color="primary" />
        </Backdrop>

        <div style={{ margin: "5% 0 5% 0" }}>
          <PurpleButton
            text="Generate Report Results PDF Print"
            onClickHandler={handleGettingTankEmissionSolutions}
          />
        </div>
      </div>
    </div>
  );
}
