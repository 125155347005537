import React from "react";
import { Box, Grid } from "@mui/material";

// Styles
import "./resourcesStyles.css";

const flareDefinition =
  "Flaring is a controlled process for burning off natural gas that may contain contaminants. Standard flares may not be fully efficient in converting methane, particularly in adverse weather or at lower flow rates. Replacing some flares with incinerators, enclosed flares, or simpler combustors can improve methane conversion, though emergency relief systems may still require traditional flaring methods to meet safety and dispersion requirements.";
const compressorSealDefinition =
  'Compressor seals prevent process gas from escaping into the atmosphere. "Wet seals" lubricate moving parts with oil but may release absorbed methane when depressurized. "Dry gas seals" are preferred for lower methane emissions. Capturing these emissions can be achieved by using compressors, injecting seal gas into the fuel stream, or utilizing combustors and other on-site equipment requiring natural gas.';
const fugitiveEmissionDefinition =
  "Fugitive emissions stem from small leaks or major equipment failures due to vibration, damage, or corrosion. Addressing these leaks often requires installation shutdowns, increasing wear and tear. Mitigation relies on monitoring and detection devices, as well as site survey services to reduce risks and improve safety.";
const dehydratorDefinition =
  "Dehydrators remove moisture from natural gas streams, usually through glycol circulation. During glycol regeneration, heat drives off water vapor, potentially releasing absorbed methane and other hydrocarbons. Condensers can help contain or mitigate these emissions by reducing water vapor content, allowing the gas to be combusted or utilized for other purposes.";
const pneumaticDefinition =
  "Pneumatic devices in the industry, historically powered by methane, control various equipment and systems. Methane emissions occur after powering these devices. To reduce emissions, alternatives include using compressed air, electrical controllers, or other gases like nitrogen, or capturing and repurposing the emitted methane for on-site energy needs.";
const tankVentDefinition =
  "Tank vents, typically found at the top of storage tanks, regulate pressure and protect against overpressure or vacuum conditions. However, mitigating methane emissions from vents is challenging due to low and variable gas flows, potential reverse flow, and sensitivity to atmospheric temperature and pressure changes. Capturing emitted gases requires compression using a Vapor Recovery Unit (VCU) and subsequent transfer for sales, flaring, or on-site equipment fueling.";
const chemicalPumpDefinition =
  "Chemical pumps, powered by pressurized methane, inject chemicals into production systems to prevent freezing, corrosion, or emulsion issues. Methane emissions depend on the chemical volume pumped and pump design. Nowadays, these pumps are being replaced by electrically driven alternatives or instrument air systems to reduce methane emissions.";
const surfaceDefinition =
  "Surface casing in wells may experience vent flows from shallower formations, unrelated to well production and persisting even after production ceases. Cement repairs and plugging methods can permanently shut off gas flows, while temporary mitigation, such as small combustors or bio-beds, can be utilized until a permanent solution is implemented.";
const operationDefinition =
  'In certain operations like cold heavy oil production, uneconomical gas volumes are vented to prevent downhole pump "gas-locking" and improve pumping efficiency. Due to the absence of production flowlines and low gas flows, installing a separate compressed gas gathering system may be impractical. Gas venting occurs after meeting on-site heating and pumping energy needs. Mitigation strategies include compression and flowline collection, sweet gas combustion, and sour gas flaring to manage odors.';
export default function EmissionGraph() {
  return (
    <div>
      <div className="emissionGraphWrapper">
        {/* <div className="white-font emisssionGraphLeftSideDefinition">
          <div className="graphDefinition">{dehydratorDefinition}</div>
          <div className="graphDefinition">{flareDefinition}</div>
          <div className="graphDefinition">{surfaceDefinition}</div>
          <div className="graphDefinition">{fugitiveEmissionDefinition}</div>
        </div> */}

        <div className="graphImage">
          <Box
            component="img"
            sx={{
              p: "1em",
              width: 800,
              height: 600,
              maxWidth: { xs: 300, sm: 400, md: 800 },
              maxHeight: { xs: 250, sm: 400, md: 600 },
              borderRadius: "30px",
            }}
            alt="PTAC Logo."
            src="/images/EmissionGraph.png"
          />
          {/* <div className="emisssionGraphBottomDefinitionWrapper">
            <div className="emisssionGraphBottomDefinition white-font graphDefinition">
              {chemicalPumpDefinition}
            </div>
          </div> */}
        </div>

        {/* <div className="white-font emisssionGraphRightSideDefinition">
          <div className="graphDefinition">{compressorSealDefinition}</div>
          <div className="graphDefinition">{pneumaticDefinition}</div>
          <div className="graphDefinition">{tankVentDefinition}</div>
          <div className="graphDefinition">{operationDefinition}</div>
        </div> */}
      </div>
    </div>
  );
}
