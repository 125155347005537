import React, { useState, useContext, useEffect } from "react";

// Components
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import {
  TextField,
  InputAdornment,
  CircularProgress,
  Backdrop,
  Typography,
} from "@mui/material";
import PurpleButton from "../../Components/Buttons/PurpleButton";
// Sections
import SiteInformation from "./Sections/SiteInformation";
import CheckBoxSection from "./Sections/CheckBoxSection";
import TankEmissions from "./Sections/TankEmissions";
import FacilityEquipmentEmission from "./Sections/FacilityEquipmentEmission";
import PneumaticInstrument from "./Sections/PneumaticInstrument";
import GlycolDehydrators from "./Sections/GlycolDehydrators";
import WellEmission from "./Sections/WellEmission";
import FugitiveEmission from "./Sections/FugitiveEmission";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import VersionHistoryCard from "../../Components/Calculator/VersionHistoryCard";
//Styles
import "./calculationStyles.css";

// API
import { sendInputs, generatePdf } from "../../APIs/calculatorAPI";

export default function CalculationsPage() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  // Must pass methane mol through most of the sections for the summary sub totals.
  const [methaneMolPercentage, setMethaneMolPercentage] = useState("93");
  const { siteInformationInputs, location, siteInformationSection } =
    SiteInformation();
  const { emissionSummaryCheck, emissionSummarySection } = CheckBoxSection();
  const { tankEmissionInputs, tankEmissionsSection } = TankEmissions(
    methaneMolPercentage,
    setMethaneMolPercentage
  );
  const { facilityEquipmentEmissionInputs, facilityEquipmentEmissionSection } =
    FacilityEquipmentEmission(methaneMolPercentage);
  const { pneumaticInstrumentInputs, pneumaticInstrumentSection } =
    PneumaticInstrument(methaneMolPercentage);
  const { glycolDehydratorInputs, glycolDehydratorSection } =
    GlycolDehydrators(methaneMolPercentage);
  const { wellEmissionInputs, wellEmissionSection } =
    WellEmission(methaneMolPercentage);
  const { fugitiveEmissionInputs, fugitiveEmissionSection } =
    FugitiveEmission();

  // Big object containing all values
  const inputs = {
    siteInformationInputs,
    tankEmissionInputs,
    facilityEquipmentEmissionInputs,
    pneumaticInstrumentInputs,
    glycolDehydratorInputs,
    wellEmissionInputs,
    fugitiveEmissionInputs,
  };

  const [monthlyResult, setMonthlyResult] = useState(0);
  const [yearlyResult, setYearlyResult] = useState(0);
  const [remainingAssetResult, setRemainingAssetResult] = useState(0);

  // Sets the results to their appropriate values
  useEffect(() => {
    sendInputs(inputs, function (response) {
      setMonthlyResult(response[0]);
      setYearlyResult(response[1]);
      setRemainingAssetResult(response[2]);
    });
  }, [inputs]);

  const [isLoading, setIsLoading] = useState(false);
  const handlePdf = () => {
    setIsLoading(true); // set loading state to true
    generatePdf(inputs, location).then(() => {
      setIsLoading(false); // set loading state back to false when download is complete
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);

  return (
    <div className="calculationPage">
      <div className="calculationBackground">
        <div>
          <NavBar />
        </div>
        <div className="title">
          <Typography variant="h3" className="assetTitle white-font">
            CALCULATION
          </Typography>
        </div>

        <div className="calculatorSections">
          <div className="versionHistoryContainer">
            <VersionHistoryCard />
          </div>
          <div>
            <div className="section">{siteInformationSection}</div>
            <div className="section">{emissionSummarySection}</div>
            {/* Calculation Sections */}

            <div>
              {emissionSummaryCheck.tankCheck ? (
                <div className="section">{tankEmissionsSection}</div>
              ) : null}
            </div>

            <div>
              {emissionSummaryCheck.facilityCheck ? (
                <div className="section">
                  {facilityEquipmentEmissionSection}
                </div>
              ) : null}
            </div>
            <div>
              {emissionSummaryCheck.pneumaticCheck ? (
                <div className="section">{pneumaticInstrumentSection}</div>
              ) : null}
            </div>
            <div>
              {emissionSummaryCheck.glycolCheck ? (
                <div className="section">{glycolDehydratorSection}</div>
              ) : null}
            </div>
            <div>
              {emissionSummaryCheck.wellCheck ? (
                <div className="section">{wellEmissionSection}</div>
              ) : null}
            </div>
            <div>
              {emissionSummaryCheck.fugitiveCheck ? (
                <div className="section">{fugitiveEmissionSection}</div>
              ) : null}
            </div>
          </div>

          {/*Result Section */}
          <div className="sectionCard  resultSection flex-align-horizontal-vertical">
            <h2 className="resultMainTitle">TOTAL EMISSION SUMMARY</h2>
            <div className="entireInputWrapper">
              <div className="resultInputContainer">
                <label className="resultLabel">Monthly</label>
                <TextField
                  value={monthlyResult}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        tC02e/month
                      </InputAdornment>
                    ),
                  }}
                  className="resultInput"
                />
              </div>
              <div className="resultInputContainer">
                <label className="resultLabel">Yearly</label>
                <TextField
                  value={yearlyResult}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">tC02e/year</InputAdornment>
                    ),
                  }}
                  className="resultInput"
                />
              </div>
              <div className="resultInputContainer">
                <label className="resultLabel">Remaining Asset Life</label>
                <TextField
                  value={remainingAssetResult}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">tC02e</InputAdornment>
                    ),
                  }}
                  className="resultInput"
                />
              </div>
            </div>
          </div>
          {/* Render Backdrop if isLoading is true */}
          <Backdrop open={isLoading} style={{ zIndex: 1 }}>
            <CircularProgress color="primary" />
          </Backdrop>
          <div style={{ marginTop: "2%" }}>
            <PurpleButton
              text="Emission Summary PDF"
              variant="contained"
              onClickHandler={handlePdf}
            >
              Emission Summary PDf
            </PurpleButton>
          </div>

          {/*Footer */}
          <div className="footer">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
