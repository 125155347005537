import axios from "axios";

/**
 * @description - Checks if the email already exists in the database.
 * @param {String} email - Email string that will be checked if exists.
 * @param {Callback Function} callback - The callback function for the response. Returns an object that has a status code and message.
 */
const checkEmailIsAvailable = function (email, callback) {
  axios
    .post("https://api.carbonemissionscanada.com/user/findEmail", {
      email: email,
    })
    .then((res) => {
      callback({
        status: res.request.status,
        message: res.data.message,
      });
    })
    .catch((err) => {
      callback({
        status: err.response.status,
        message: err.response.data.message,
      });
    });
};

/**
 * @description - Creates a user. Validation should be done before using this function.
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} email
 * @param {String} companyName
 * @param {String} position
 * @param {String} phone
 * @param {String} password
 * @param {Callback Function} callback - The callback function for the response. Returns an object that has a status code and message.
 */
const registerUser = function (
  firstName,
  lastName,
  email,
  companyName,
  position,
  phone,
  password,
  callback
) {
  axios
    .post("https://api.carbonemissionscanada.com/auth/register", {
      firstName: firstName,
      lastName: lastName,
      email: email,
      companyName: companyName,
      position: position,
      phone: phone,
      password: password,
    })
    .then((res) => {
      callback({
        status: res.request.status,
        message: res.data.message,
      });
    })
    .catch((err) => {
      callback({
        status: err.response.status,
        message: err.response.data.message,
      });
    });
};

/**
 * @description - Logs in a user and creates a user session.
 * @param {String} email
 * @param {String} password
 * @param {Callback Function} callback - The callback function for the response. Returns an object that has a status code and message.
 */
const loginUser = function (email, password, callback) {
  axios
    .post("https://api.carbonemissionscanada.com/auth/login", {
      email: email,
      password: password,
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => callback(err.response.data));
};

/**
 * @description - Gets the current user session and its data.
 * @param {Function} callback  - The callback function for the response.
 */
const getCurrentUser = function (callback) {
  axios
    .post("https://api.carbonemissionscanada.com/user/")
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => console.log(err.response.data));
};

const forgotPassword = function (email, callback) {
  axios
    .post("https://api.carbonemissionscanada.com/user/forgot-password", {
      email,
    })
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => console.log(err.response.data));
};

/**
 * @description - Deletes a user account.
 * @param {Function} callback - The callback function for the response.
 */
const deleteUser = function (callback) {
  axios
    .post("https://api.carbonemissionscanada.com/user/account ")
    .then((response) => {
      callback(response);
    })
    .catch((err) => console.log(err.response.data));
};

/**
 *
 * @param {String} value - token generated when user clicks captcha.
 * @param {Function} callback - A callback function that handles the response.
 */
const captchaCheck = function (value, callback) {
  axios
    .post("https://api.carbonemissionscanada.com/user/captchaCheck", {
      token: value,
    })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => console.log("Error: " + err));
};

export {
  registerUser,
  loginUser,
  checkEmailIsAvailable,
  getCurrentUser,
  forgotPassword,
  deleteUser,
  captchaCheck,
};
