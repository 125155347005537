import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
// Components
import { Box } from "@mui/material";
import CaseStudy from "../../Components/CaseStudy";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
// Styles
import "../../Pages/Resources/CaseStudyPages/caseStudyStyles.css";
import PurpleButton from "../../Components/Buttons/PurpleButton";

const paragraphs = [
  "Historically, addressing upstream oil and gas methane emissions has been difficult due to the perceived low economic value and lack of hazardous classification for high methane content streams. However, the rising concern over global GHG emissions and methane's increased potency as a GHG compared to carbon dioxide has sparked interest in methane measurement. This interest has been further driven by international agreements, federal commitments, and corporate governance objectives that emphasize improved measurement and control of methane streams. Despite the challenges and costs of continuous measurement, it is often more economical to mitigate emissions rather than measure them.",
  'Implementing effective methane solutions necessitates estimates of the volumes and compositions of the streams being contained or converted. The Alberta Energy Regulator\'s "Manual 015 – Estimating Methane Emissions" serves as a valuable tool for those developing product solutions or planning mitigation installations. The yearly "ST60B Upstream Petroleum Industry Emissions Report" offers insights into current emission levels and trends, helping suppliers identify potential markets and customers. Emission factors differ depending on the production type due to variations in gas composition, as demonstrated in the table below:',
];

export default function HandbookPage() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);

  const handlePDFClick = () => {
    window.open(
      "https://indd.adobe.com/view/45bf0943-9b6e-496d-a5c2-91aab43ed0c6",
      "_blank"
    );
  };
  const handleTankButtonClick = () => {
    window.open("https://online.flippingbook.com/link/819981/", "_blank");
  };
  return (
    <div className="homePage">
      <div className="homeBackground">
        <div>
          <NavBar />
        </div>
        <div className="white-font indent">
          <h1 className="caseTitle ">
            Tank Venting Eco-Efficiency & Operations Handbook
          </h1>
          {paragraphs.map(function (paragraph, index) {
            return <p className="caseBody indent">{paragraph}</p>;
          })}
        </div>

        <div className="flex-align-horizontal-vertical">
          <Box
            component="img"
            sx={{
              p: "1em",

              maxWidth: { xs: 300, sm: 400, md: 400 },
              maxHeight: { xs: 400, sm: 400, md: 250 },
            }}
            alt="PTAC Logo."
            src="/images/Table.png"
          />
        </div>
        <div className="caseBody white-font indent">
          <p className="indent justifyParagraph">
            These differences should be taken into account when tackling methane
            emissions from various production types.
          </p>
          <p className="indent" style={{ fontSize: "1.5em" }}>
            HANDBOOK
          </p>

          <p className="indent justifyParagraph">
            The Tank Venting Eco-Efficiency & Operations Handbook serves as a
            guide that combines environmental stewardship with operational
            effectiveness in the management of tank venting processes. This
            resource provides detailed information on best practices, innovative
            technologies, and proven methodologies for minimizing emissions
            while maintaining high operational efficiency. By integrating the
            principles and strategies outlined in the handbook, industry
            professionals can achieve a balance between environmental targets
            and optimizing their overall performance, contributing to a more
            sustainable future for the sector.
          </p>
        </div>
        <div
          className="flex-align-horizontal-vertical"
          style={{ marginBottom: "150px" }}
        >
          <div style={{ marginTop: "2%" }}>
            <PurpleButton
              onClickHandler={handleTankButtonClick}
              text="Tank Emission Overview"
            />
          </div>

          <div style={{ margin: "25px 0 5% 0" }}>
            <PurpleButton onClickHandler={handlePDFClick} text="PDF Download" />
          </div>
        </div>

        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
