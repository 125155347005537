import "./App.css";
import "./generalStyles.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import axios from "axios";

//AuthContext for logged in status.
import { AuthContextProvider } from "./context/AuthContext";

// Pages
import LoginPage from "./Pages/Login/LoginPage";
import RegisterPage from "./Pages/Register/RegisterPage";
import HomePage from "./Pages/Home/HomePage";
import TestingPage from "./Pages/Testing/TestingPage";
import ResourcesPage from "./Pages/Resources/ResourcesPage";
import GlossaryPage from "./Pages/Glossary/GlossaryPage";
import HandbookPage from "./Pages/Handbook/HandbookPage";
import CalculationsPage from "./Pages/Calculations/CalculationsPage";
import SolutionPage from "./Pages/Solution/SolutionPage";
import FlippingBookPage from "./Pages/FlippingBook/FlippingBookPage";
import SettingsPage from "./Pages/Settings/SettingsPage";
import EquationFrameworkPage from "./Pages/EquationFramework/EquationFrameworkPage";
import VersionHistoryPage from "./Pages/VersionHistory/VersionHistoryPage";
// Case Study Pages
import Vru from "./Pages/Resources/CaseStudyPages/Vru";
import CombustionAndPower from "./Pages/Resources/CaseStudyPages/CombustionAndPower";
import ChemicalPumps from "./Pages/Resources/CaseStudyPages/ChemicalPumps";
import ElectricalDevices from "./Pages/Resources/CaseStudyPages/ElectricalDevices";
import InstrumentAir from "./Pages/Resources/CaseStudyPages/InstrumentAir";
import Retrofit from "./Pages/Resources/CaseStudyPages/Retrofit";
import Detection from "./Pages/Resources/CaseStudyPages/Detection";
import ResearchTechnology from "./Pages/Resources/CaseStudyPages/ResearchTechnologies";
import ManagingEmissions from "./Pages/Resources/CaseStudyPages/ManagingEmissions";
import PrioritizingInitiatives from "./Pages/Resources/CaseStudyPages/PrioritizingInitiatives";

import ForgotPassword from "./Pages/ResetPassword/ForgotPassword";

axios.defaults.withCredentials = true;

function App() {
  return (
    <AuthContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/testing" element={<TestingPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/resources" element={<ResourcesPage />} />
          <Route path="/glossary" element={<GlossaryPage />} />
          <Route path="/handbook" element={<HandbookPage />} />
          <Route path="/calculations" element={<CalculationsPage />} />
          <Route path="/solutions" element={<SolutionPage />} />
          <Route
            path="/company-flip-book/:pageNumber"
            element={<FlippingBookPage />}
          />
          <Route path="/settings" element={<SettingsPage />} />
          <Route
            path="/equation-frameworks"
            element={<EquationFrameworkPage />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/version-history" element={<VersionHistoryPage />} />

          {/*--------Case Study Routes-------- */}
          <Route path="/resources/case-study/vru" element={<Vru />} />
          <Route
            path="/resources/case-study/combustion"
            element={<CombustionAndPower />}
          />
          <Route
            path="/resources/case-study/chemicalPumps"
            element={<ChemicalPumps />}
          />
          <Route
            path="/resources/case-study/electrical-devices"
            element={<ElectricalDevices />}
          />
          <Route
            path="/resources/case-study/instrument-air"
            element={<InstrumentAir />}
          />
          <Route
            path="/resources/case-study/power-generation-retrofit"
            element={<Retrofit />}
          />
          <Route
            path="/resources/case-study/detection-measurement-quantification-monitoring"
            element={<Detection />}
          />
          <Route
            path="/resources/case-study/research-new-technologies"
            element={<ResearchTechnology />}
          />
          <Route
            path="/resources/case-study/managing-emissions"
            element={<ManagingEmissions />}
          />
          <Route
            path="/resources/case-study/prioritizing-iniatives"
            element={<PrioritizingInitiatives />}
          />
        </Routes>
      </Router>
    </AuthContextProvider>
  );
}

export default App;
