import React, { useEffect, useState } from "react";

import CalculatorSection from "../../../Components/Calculator/CalculatorSection";

// API
import { getCoordinates } from "../../../APIs/mapAPI";
export default function SiteInformation() {
  //Inputs
  const [uwi, setUwi] = useState("");
  const [formattedUwi, setFormatedUwi] = useState("");
  const [siteDescription, setSiteDescription] = useState("");
  const [facilityId, setFacilityId] = useState("");
  const [siteActivity, setSiteActivity] = useState("");
  const [remainingLife, setRemainingLife] = useState("");

  const labels = [
    "UWI Surface",
    "Site Description",
    "Facility ID",
    "Site Activity",
    "Remaining Life of Asset",
  ];
  const siteInfoTexts = [
    "Input a Unique Well Identifier (UWI) and provide additional details. Example: 100 / 06-20-038-04W5 / 00",
    "Supply essential descriptions of your UWI/facility's location as needed.",
    "Provide specifics relating to Facility ID.",
    "Submit any required information concerning your oil and gas facility or wellsite.",
    "Asset's remaining lifespan including considerations of reserves, production capacity, and economic constraints. (years)",
  ];
  const adornments = [null, null, null, null, "Years", null];

  const [comments, setComments] = useState("");

  const [location, setLocation] = useState({ lat: null, lng: null });

  const siteInformationInputs = {
    uwi,
    siteDescription,
    facilityId,
    siteActivity,
    remainingLife,
    comments,
  };

  return {
    siteInformationInputs,
    location,
    siteInformationSection: (
      <div>
        <CalculatorSection
          title="SITE INFORMATION"
          inputs={[
            uwi,
            siteDescription,
            facilityId,
            siteActivity,
            remainingLife,
          ]}
          inputValueSetters={[
            setUwi,
            setSiteDescription,
            setFacilityId,
            setSiteActivity,
            setRemainingLife,
          ]}
          labels={labels}
          infoTexts={siteInfoTexts}
          commentTitle="Comments"
          commentValue={comments}
          commentSetter={setComments}
          adornments={adornments}
          needsMap={false}
          type={"text"}
          currentUWI={uwi}
          locationSetter={setLocation}
        />
      </div>
    ),
  };
}
